

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";


// Material Dashboard 2 React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";

// Data
import { NavLink } from "react-router-dom";
import AgentDataTable from "./data/agentDataTable";

function AgentsTable() {



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDBox display="flex" alignItems="center" sx={{ justifyContent: 'space-between' }}>
                  <MDTypography variant="h6" color="white">
                    Agents
                  </MDTypography>
                  <MDBox ml={0.5} width="9rem">
                    <NavLink to="/agents/add">
                      <MDButton variant="gradient" color="secondary" >
                        Add Agents
                      </MDButton>
                    </NavLink>
                  </MDBox>
                </MDBox>
              </MDBox>
              <MDBox pt={1}>
                <AgentDataTable />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AgentsTable;

import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
const API_URL = `${BACKEND_URL}/api/subscriber/`

// Create New subscriber
const createSubscriber = async (subscriberData) => {
    const response = await axios.post(API_URL + "add", subscriberData)
    return response.data.message
}


// get all subscriber filtered details
const getSubscribers = async () => {
    const response = await axios.get(API_URL + "getsubscribers")
    return response.data
}

// delete a subscriber
const deleteSubscriber = async (id) => {
    const response = await axios.delete(`${API_URL}delete/${id}`)
    return response.data.message
}

//get all details of a single subscriber
const getSubscriber = async (id) => {
    const response = await axios.get(`${API_URL}getsubscriber/${id}`)
    return response.data
}

//update a subscriber
const updateSubscriber = async (id, subscriberData) => {
    const response = await axios.patch(`${API_URL}updatesubscriber/${id}`, subscriberData)
    return response.data
}

//update a subscriber picture
const updateSubscriberPicture = async (id, subscriberData) => {
    const response = await axios.patch(`${API_URL}updatesubscriberimage/${id}`, subscriberData)
    return response.data
}



const subscriberService = {
    createSubscriber,
    getSubscribers,
    deleteSubscriber,
    getSubscriber,
    updateSubscriber,
    updateSubscriberPicture
}

export default subscriberService
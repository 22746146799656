// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "components/Tables/DataTable";
import { Link, useParams } from "react-router-dom";
import PaymentDate from "layouts/utils/paymentDate";
import { useState } from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import PopupDialog from "layouts/utils/popupDialog";
import { deleteDocument } from "redux/features/document/documentSlice";
import { getPlots } from "redux/features/plot/plotSlice";

export default function DocumentsDataTable() {

  const { id } = useParams();
  const dispatch = useDispatch();
  const { plots } = useSelector((state) => state.plot);
  const { documents, isLoading } = useSelector((state) => state.document);

  const [openPreview, setOpenPreview] = useState(false);
  const [previewImage, setPreviewImage] = useState(null)

  const handlePreviewClick = (previewImage) => {
    setOpenPreview(true);
    setPreviewImage(previewImage)
  };

  // delete document
  const deleteDocumentFunction = async ({ id, documentData }) => {

    await dispatch(deleteDocument({ id, documentData }))
    await dispatch(getPlots());
  };


  const rows = documents.map((document, index) => {

    const matchingPlot = plots.find(plot => plot._id === document.plotID);

    //documentData for deletion
    const documentData = {
      plotID: matchingPlot?._id,
      documentID: document?._id,
    }

    return {
      plotName: `${matchingPlot?.blockNumber} ${matchingPlot?.plotNumber}`,
      phase: matchingPlot.phase,
      docTitle: document?.docTitle,
      date: (<PaymentDate payment={document?.createdAt} />),
      preview: (
        <MDBox display="flex" alignItems="center" lineHeight={1} ml={3} sx={{ cursor: "pointer" }}>
          <Icon component={Link} to="#" color="secondary" fontSize="small" onClick={() => handlePreviewClick(document?.image.filePath)}>visibility</Icon>
        </MDBox>
      ),
      action: (
        <MDTypography sx={{ display: "flex", alignItems: "center" }}>
          <Icon component={Link} color="secondary" to="#" sx={{ mt: -0.25, ml: 3, mr: 3 }}>
            edit
          </Icon>
          <PopupDialog
            popupTitle="Delete Document"
            popupText="Are you sure you want to delete this Document?"
            buttonYes="Yes"
            buttonNo="Cancel"
            icon="delete"
            color="error"
            handleYes={() => deleteDocumentFunction({ id, documentData })}
          />
        </MDTypography>
      ),
    };
  });



  const columns = [
    { Header: "Upload Date", accessor: "date" },
    { Header: "Plot Name", accessor: "plotName" },
    { Header: "Phase", accessor: "phase" },
    { Header: "Document Title", accessor: "docTitle" },
    { Header: "Preview", accessor: "preview" },
    { Header: "Actions", accessor: "action" },
  ];




  return (
    <MDBox >
      {!isLoading && documents.length === 0 ? (
        <MDTypography variant="h6" pt={4} px={4} pb={3} color="error">
          Subscriber has no uploaded documents,
        </MDTypography>
      ) : (
        <MDBox>
          <Dialog open={openPreview} onClose={() => setOpenPreview(false)} PaperProps={{ style: { width: '70vw', height: '60vh', maxHeight: 'none' } }}>
            <DialogTitle>Document Preview</DialogTitle>
            <DialogContent>
              <img src={previewImage} alt="Document" style={{ width: '100%' }} />
            </DialogContent>
          </Dialog>
          <DataTable table={{ columns, rows }}
            isSorted={true}
            entriesPerPage={true}
            showTotalEntries={true}
            canSearch={true}
            noEndBorder
          />

        </MDBox>

      )}

    </MDBox>

  )
}

import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
const API_URL = `${BACKEND_URL}/api/staff/`


// Register Staff
const register = async (staffData) => {
    const response = await axios.post(API_URL + "register", staffData)

    return response.data
}

// login Staff
const login = async (staffData) => {
    const response = await axios.post(API_URL + "login", staffData)

    return response.data
}

// logout Staff
const logout = async () => {
    const response = await axios.get(API_URL + "logout")
    return response.data.message
}

// get login Status
const loginStatus = async () => {
    const response = await axios.get(API_URL + "loggedin")    
    return response.data
}

// admin to get staff data
const getStaff = async (id) => {
    const response = await axios.get(`${API_URL}getstaff/${id}`)    
    return response.data
}

// get logged in staff details
const getLoggedInStaff = async (id) => {
    const response = await axios.get(API_URL + "getstaff")    
    return response.data
}

// admin to create staff Profile
const createStaff = async (staffData) => {
    const response = await axios.post(API_URL + "createStaff", staffData)    
    return response.data.message
}

// staff forgot Password 
const forgotPassword = async (staffData) => {
    const response = await axios.post(API_URL + "forgotpassword", staffData)    
    return response.data.message
}

// staff reset Password 
const resetPassword = async (staffData, resetToken) => {
    const response = await axios.patch(`${API_URL}resetpassword/${resetToken}`, staffData)    
    return response.data.message
}

// get staffs
const getStaffs = async () => {
    const response = await axios.get(API_URL + "getstaffs")    
    return response.data
}

// delete staffs
const deleteStaff = async (id) => {
    const response = await axios.delete(API_URL + id)    
    return response.data.message
}

// update staff by admin
const updateStaff = async (id, staffData) => {
    const response = await axios.patch(`${API_URL}updatestaff/${id}`, staffData)    
    return response.data.message
}


const authService = {
    register,
    login,
    logout,
    loginStatus,
    getStaff,
    getLoggedInStaff,
    createStaff,
    forgotPassword,
    resetPassword,
    getStaffs,
    deleteStaff,
    updateStaff
}

export default authService
import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";


// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";


// Images
import subscriberImage from "assets/images/subscriberImage.png";
import backgroundImage from "assets/images/bg-profile.jpg";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getSubscriber } from "redux/features/subscriber/subscriberSlice";
import Loader from "components/loader";
import { getSubPayments } from "redux/features/payment/paymentSlice";
import { getPlots } from "redux/features/plot/plotSlice";
import { getAgents } from "redux/features/agent/agentSlice";
import { getSubDocuments } from "redux/features/document/documentSlice";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon } from "@mui/material";
import { toast } from "react-toastify";
import { updateSubscriberPicture } from "redux/features/subscriber/subscriberSlice";

function Header({ children }) {

  const { id } = useParams()
  const dispatch = useDispatch();

  const { subscriber, isLoading } = useSelector((state) => state.subscriber);
  const { plots } = useSelector((state) => state.plot);

  //handles view of profile picture
  const [isProfilePictureOpen, setisProfilePictureOpen] = useState(false);


  const handleProfilePicturePreviewOpenDialog = () => {
    setisProfilePictureOpen(true);
  };

  const handleProfilePicturePreviewCloseDialog = () => {
    setIsEditing(false)
    setisProfilePictureOpen(false);
  };

  const profilePictureDialog = (
    <>
      <DialogTitle>Subscriber Image</DialogTitle>
      <DialogContent>
        <img src={subscriber?.image?.filePath} alt="Subscriber Image" style={{ width: '100%' }} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleProfilePicturePreviewCloseDialog}>Close</Button>
      </DialogActions>
    </>
  );




  //handles profile picture upload
  const [isEditing, setIsEditing] = useState(false);
  const [selectedProfilePicture, setSelectedProfilePicture] = useState(null);
  const [isUpdateProfilePictureOpen, setisUpdateProfilePictureOpen] = useState(false);

  const handleUpdateProfilePicturePreviewOpenDialog = () => {
    setisUpdateProfilePictureOpen(true);
  };

  const handleUpdateProfilePicturePreviewCloseDialog = () => {
    setIsEditing(false)
    setSelectedProfilePicture(null);
    setisUpdateProfilePictureOpen(false);
  };

  const profilePictureChange = (event) => {
    // Logic to open the system tray and select a new picture
    // Once the user selects a picture, update the profilePicture state
    const file = event.target.files[0];
    setSelectedProfilePicture(file);
    setisUpdateProfilePictureOpen(true);
  };

  const submitProfilePictureChange = async () => {

    if (!selectedProfilePicture) {
      return toast.error('Kindly select a picture')
    }

    const subscriberData = new FormData()

    subscriberData.append("image", selectedProfilePicture)



    //clean up and execution
    setIsEditing(false)
    setisUpdateProfilePictureOpen(false);
    await dispatch(updateSubscriberPicture({ id, subscriberData }));
    dispatch(getSubscriber(id));

  };

  //all use effect
  useEffect(() => {
    dispatch(getSubscriber(id));
    dispatch(getSubPayments(id))
    dispatch(getSubDocuments(id))
    dispatch(getAgents())
    dispatch(getPlots());
  }, [dispatch]);




  useEffect(() => {

    async function getData() {
      if (!subscriber?.email) {
        await dispatch(getSubscriber(id));
      }

    }

    if (!subscriber?.email) {
      getData();
    }
  }, [dispatch, id, subscriber]);


  return (

    <MDBox position="relative" mb={5}>
      {isLoading && <Loader />}
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />

      {!subscriber && !isLoading ? (
        <MDTypography variant="h6" px={4} pb={3} color="error">
          Something went wrong, please reload...
        </MDTypography>
      ) : (
        <Card
          sx={{
            position: "relative",
            mt: -8,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <MDBox display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
            <MDBox display="flex" alignItems="center" >

              <MDBox
                display="flex" alignItems="center"
                onMouseEnter={() => setIsEditing(true)}
                onMouseLeave={() => setIsEditing(false)}
              >
                <MDAvatar src={subscriber?.image?.filePath} onMouseEnter={() => setIsEditing(true)} onClick={handleProfilePicturePreviewOpenDialog} alt="profile-image" size="xl" shadow="sm" />


                {/* Preview Picture */}
                <Dialog open={isProfilePictureOpen} onClose={handleProfilePicturePreviewCloseDialog}>
                  {profilePictureDialog}
                </Dialog>

                {/* Upload Picture */}
                <label htmlFor="profilePictureInput">
                  {isEditing && (
                    <Icon color="secondary" sx={{ ml: 1 }}>
                      edit
                    </Icon>
                  )}
                  <input
                    id="profilePictureInput"
                    type="file"
                    accept="image/*"
                    onChange={profilePictureChange}
                    style={{ display: 'none' }}
                  />
                </label>

                {selectedProfilePicture && (
                  <Dialog open={isUpdateProfilePictureOpen} onClose={handleUpdateProfilePicturePreviewCloseDialog}>
                    <DialogTitle>New Profile Picture Preview</DialogTitle>
                    <DialogContent>
                      <img src={URL.createObjectURL(selectedProfilePicture)} alt="New Profile Picture Preview" style={{ width: '100%' }} />
                    </DialogContent>
                    <DialogActions>

                      <Button onClick={handleUpdateProfilePicturePreviewCloseDialog}>Close</Button>
                      <MDButton variant="gradient" color="info" onClick={submitProfilePictureChange} >
                        Update
                      </MDButton>

                    </DialogActions>
                  </Dialog>
                )}



              </MDBox>

              <MDBox display="flex" flexDirection="column" marginLeft="1rem">
                <MDTypography variant="h5" fontWeight="medium">
                  {subscriber?.name || subscriber?.nameOfEntry}
                </MDTypography>
                <MDTypography variant="button" color="text" fontWeight="regular">
                  File Number: {subscriber?.fileNumber}
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox display="flex" flexDirection="column" justifyContent="center" marginLeft="auto" marginTop="0.5rem">
              <MDTypography variant="button" color="text" fontWeight="regular">
                Email: {subscriber?.email}
              </MDTypography>
            </MDBox>
            <MDBox display="flex" flexDirection="column" justifyContent="center" marginLeft="auto" marginTop="0.5rem">
              <MDTypography variant="button" color="text" fontWeight="regular">
                Phone Number: {subscriber?.phoneNumber}
              </MDTypography>
            </MDBox>
            <MDBox display="flex" flexDirection="column" justifyContent="center" marginLeft="auto" marginTop="0.5rem">
              <MDTypography variant="button" color="text" fontWeight="regular">
                Address: {subscriber?.residentialAddress || subscriber?.officeAddress}
              </MDTypography>
            </MDBox>
          </MDBox>

          {children}
        </Card>
      )

      }

    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
};

export default Header;




// Material Dashboard 2 React components
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DocumentModal from "layouts/utils/documentModal";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DocumentsDataTable from "./data";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { addDocument } from "redux/features/document/documentSlice";
import { getPlots } from "redux/features/plot/plotSlice";


function Documents() {

  const initialState = {
    docTitle: '',
    plotID: '',
  }

  //declarations
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams()
  const [documentData, setDocumentData] = useState(initialState)
  const { subscriber } = useSelector((state) => state.subscriber);
  const { plots = [], isLoading } = useSelector((state) => state.plot);
  const offerPlots = plots.filter((plot) => plot?.subscriberID === subscriber?.id);

  const {
    docTitle,
    plotID,
  } = documentData

  //document upload handling
  const [document, setDocument] = useState("");
  const [documentPreview, setDocumentPreview] = useState(null);

  //toggle Modal
  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleAction = async (e) => {
    e.preventDefault();


    //validations
    if (!plotID) {
      toast.error('Select a plot')
      return
    }

    if (!docTitle) {
      toast.error('Enter the title of the document')
      return
    }

    if (!document) {
      toast.error('Upload a document')
      return
    }



    const documentData = new FormData()

    documentData.append("plotID", plotID)
    documentData.append("docTitle", docTitle)
    documentData.append("image", document)

    await dispatch(addDocument({ id, documentData }));
    await dispatch(getPlots());
  }

  // functions to handle record payment modal.
  const handleInputChange = (e) => {
    const { name, value } = e.target
    setDocumentData({ ...documentData, [name]: value })
  };

  //functions to handle document upload and preview
  const handleImageChange = (e) => {
    setDocument(e.target.files[0])
    setDocumentPreview(URL.createObjectURL(e.target.files[0]))
  }




  return (
    <MDBox>
      {/* {isLoading && <Loader />} */}
      <MDBox p={3}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <MDTypography variant="h6" mt={1} >
              Documents
            </MDTypography>
          </Grid>
          <Grid item>
            <MDButton onClick={handleOpenModal} variant="gradient" color="secondary" to="#">
              Upload Document
            </MDButton>
            <DocumentModal
              open={open}
              onClose={handleCloseModal}
              onAction={handleAction}
              formData={documentData}
              plots={offerPlots}
              handleImageChange={handleImageChange}
              handleInputChange={handleInputChange}
              imagePreview={documentPreview}
              title="Upload Document"
              description="Upload a document for this subscriber?"
              actionButtonLabel="Upload Document"
            />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox>
        <DocumentsDataTable />
      </MDBox>
    </MDBox>
  );
}

export default Documents;

import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import MDButton from "components/MDButton";
import { NumericFormat } from "react-number-format";
import MDInput from "components/MDInput";
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import './datepicker.css';
import 'react-datepicker/dist/react-datepicker.css';
import MDBox from "components/MDBox";
import { Link } from "react-router-dom";
import MDTypography from "components/MDTypography";

// Data


function DocumentModal(props) {
  const { open, onClose, onAction, title, description, actionButtonLabel, handleInputChange, handleImageChange, imagePreview, formData, plots } = props;

  const [openImage, setOpenImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpenImage(true);
  };

  const handleDialogClose = () => {
    setSelectedImage(null);
    setOpenImage(false);
  };


  const {
    docTitle,
    plotID,
  } = formData




  return (
    <MDBox >
      <Dialog open={open} onClose={onClose} maxWidth="lg" PaperProps={{ style: { width: '40vw', height: '40vh', maxHeight: 'none' } }}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent style={{ pt: '10px' }} >
          <DialogContentText> </DialogContentText>
          <Grid container spacing={4} style={{ maxWidth: '50vw' }}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Plot</InputLabel>
                <Select
                  name="plotID"
                  value={plotID}
                  onChange={handleInputChange}
                  variant="standard"
                // disabled={button}
                >
                  {plots.map((plot) => (
                    <MenuItem key={plot._id} value={plot._id}>
                      {plot.blockNumber} {plot.plotNumber} | {plot.phase}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDInput
                type="text"
                label="Document Title"
                onChange={handleInputChange}
                name="docTitle"
                value={docTitle}
                variant="standard"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <InputLabel htmlFor="payment-proof">Select Document to upload</InputLabel>
              <MDInput
                type="file"
                id="payment-proof"
                onChange={(e) => handleImageChange(e)}
                name="image"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {imagePreview != null ? (
                <div>
                  <MDButton
                    component={Link}
                    to="#"
                    onClick={() => handleImageClick(imagePreview)}
                    sx={{ mr: -3 }}
                    variant="text"
                    color="secondary"
                  >
                    Click here to preview
                  </MDButton>
                </div>
              ) : (
                <p>No image selected yet.</p>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Dialog open={openImage} onClose={handleDialogClose}>
                <DialogTitle>Document Preview</DialogTitle>
                <DialogContent>
                  <img src={selectedImage} alt="product" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                </DialogContent>
                <DialogActions>
                  <MDButton onClick={handleDialogClose} variant="gradient" color="error">
                    Close
                  </MDButton>
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>

        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <MDButton variant="gradient" color="info" onClick={onAction} >
            {actionButtonLabel}
          </MDButton>
        </DialogActions>
      </Dialog>
    </MDBox>
  );
}



export default DocumentModal;

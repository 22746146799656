

import { useEffect, useState } from "react";

// react-router-dom components
import { Link, useNavigate, useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";


// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

//import custom component
import PasswordInput from "components/PasswordInput";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sigin-page.png";
import brand from "assets/images/mansonia-bay-logo.png";

//my imports
import { toast } from "react-toastify";
import validator from 'validator';
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/loader";
import { resetPassword } from "redux/features/auth/authSlice";
import { RESET } from "redux/features/auth/authSlice";


const initialState = {
  password: "",
  password2: "",
}

function Basic() {

  const [formData, setFormData] = useState(initialState);
  const { password, password2 } = formData
  const { resetToken } = useParams()


  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { isLoading, isSuccess, message } = useSelector((state) => state.auth)



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!password || !password2) {
      return toast.error('Please fill all fields')
    }

    if (password2 !== password) {
      return toast.error('Passwords do not match')
    }

    if (!validator.isStrongPassword(password)) {
      return toast.error('New Password not strong enough');
    }


    const staffData = {
      password
    }


    await dispatch(resetPassword({ staffData, resetToken }))


  }

  useEffect(() => {
    if (isSuccess && message?.includes("Password Reset Successful")) {
      navigate("/")
    }

    dispatch(RESET())
  }, [dispatch, navigate, message, isSuccess])


  return (
    <BasicLayout image={bgImage}>
      {isLoading && <Loader />}
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >

          <MDBox component="img" src={brand} alt="Brand" width="10rem" mt={2} />

          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1} mb={2}>
            Reset Password
          </MDTypography>

        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <PasswordInput
              type="password"
              label="New Password"
              onChange={handleInputChange}
              name="password"
              value={password}
              fullWidth
            />
            <PasswordInput
              type="password"
              label="Confirm New Password"
              onChange={handleInputChange}
              name="password2"
              value={password2}
              fullWidth
            />
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" onClick={handleSubmit} fullWidth>
                Reset Password
              </MDButton>
            </MDBox>
            <MDBox mb={1} mt={3} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account? {" "}
                <MDTypography
                  component={Link}
                  to="/"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign in
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;

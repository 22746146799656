import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import paymentService from './paymentService'

const initialState = {
    payment: null,
    payments: [],
    isError: false,
    isSuccess: false,
    isCreate: false,
    isLoading: false,
    message: "",
    totalPlotPayments: 0,
    totalAgentPayments: 0,
    totalOidcPayments: 0,
    totalLegalPayments: 0,
    totalOtherPaymentsComm: 0,
    totalOtherPayments: 0

}

//Create new payment
export const addPayment = createAsyncThunk(
    "payments/add",
    async ({ id, paymentData }, thunkAPI) => {
        try {
            return await paymentService.addPayment(id, paymentData)
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// get all payments  of a subscriber
export const getSubPayments = createAsyncThunk(
    "payments/getSubPayments",
    async (id, thunkAPI) => {
        try {
            return await paymentService.getSubPayments(id)
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// get all payments
export const getPayments = createAsyncThunk(
    "payments/getpayments",
    async (_, thunkAPI) => {
        try {
            return await paymentService.getPayments()
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

//delete a payment
export const deletePayment = createAsyncThunk(
    "payments/delete",
    async ({ id, paymentData }, thunkAPI) => {
        try {
            await paymentService.deletePayment(id, paymentData)
            return paymentData
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

//update a payment
export const updatePayment = createAsyncThunk(
    "payments/updatePayment",
    async ({ id, paymentData }, thunkAPI) => {
        try {

            return await paymentService.updatePayment(id, paymentData)
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

//get all details of a single payment
export const getPayment = createAsyncThunk(
    "plots/getPayment",
    async (id, thunkAPI) => {
        try {
            return await paymentService.getPayment(id)
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)


const paymentSlice = createSlice({
    name: "payment",
    initialState,
    reducers: {
        CALC_PAYMENTS_FOR_SUBSCRIBER(state, action) {
            const payments = action.payload;

            const offerPayments = payments.filter((payment) => payment?.paymentType === 'Offer Payment');

            const totalPlotPayments = offerPayments.reduce((accumulator, payment) => accumulator + parseFloat(payment?.offerCommission?.$numberDecimal || 0), 0);
            const totalAgentPayments = offerPayments.reduce((accumulator, payment) => accumulator + parseFloat(payment?.agentCommission?.$numberDecimal || 0), 0);
            const totalOidcPayments = offerPayments.reduce((accumulator, payment) => accumulator + parseFloat(payment?.oidcCommission?.$numberDecimal || 0), 0);
            const totalLegalPayments = offerPayments.reduce((accumulator, payment) => accumulator + parseFloat(payment?.legalCommission?.$numberDecimal || 0), 0);
            const totalOtherPaymentsComm = offerPayments.reduce((accumulator, payment) => accumulator + parseFloat(payment?.otherCommission?.$numberDecimal || 0), 0);

            const otherPayments = payments.filter((payment) => payment?.paymentType !== 'Offer Payment' && payment?.paymentType !== 'Offer Payment Progress');

            const totalOtherPayments = otherPayments.reduce((accumulator, payment) => accumulator + parseFloat(payment?.amountPaid?.$numberDecimal || 0), 0);



            state.totalPlotPayments = totalPlotPayments.toFixed(2)
            state.totalAgentPayments = totalAgentPayments.toFixed(2)
            state.totalOidcPayments = totalOidcPayments.toFixed(2)
            state.totalLegalPayments = totalLegalPayments.toFixed(2)
            state.totalOtherPaymentsComm = totalOtherPaymentsComm.toFixed(2)
            state.totalOtherPayments = totalOtherPayments.toFixed(2)



        }
    },
    extraReducers: (builder) => {
        // Define any additional reducers here
        builder

            //Create New payment
            .addCase(addPayment.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(addPayment.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isCreate = true
                state.isError = false
                toast.success(action.payload)
                state.payments.push(action.payload)

            })
            .addCase(addPayment.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.isCreate = false
                state.message = action.payload
                toast.error(action.payload)
            })

            // get all payments of a subscriber
            .addCase(getSubPayments.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getSubPayments.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.payments = (action.payload)
                state.message = action.payload

            })
            .addCase(getSubPayments.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                toast.error(action.payload)
            })

            // get all payments
            .addCase(getPayments.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getPayments.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.payments = (action.payload)
                state.message = action.payload

            })
            .addCase(getPayments.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                toast.error(action.payload)
            })

            // delete a payment
            .addCase(deletePayment.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(deletePayment.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.payments = state.payments.filter(payment => payment._id !== action.payload.paymentID)
                toast.success("Payment deleted successfully")
            })
            .addCase(deletePayment.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                toast.error(action.payload)
            })

            // update a payment
            .addCase(updatePayment.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(updatePayment.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                toast.success("Payment updated successfully")
            })
            .addCase(updatePayment.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                toast.error(action.payload)
            })

            //get all details of a single payment
            .addCase(getPayment.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getPayment.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.payment = action.payload
            })
            .addCase(getPayment.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                toast.error(action.payload)
            })
    }
})

export const { CALC_PAYMENTS_FOR_SUBSCRIBER } = paymentSlice.actions

export const selectIsSuccess = (state) => state.payment.isSuccess

export const selectPayment = (state) => state.payment.payment

export default paymentSlice.reducer

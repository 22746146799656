import React from "react";

const ThousandSeparator = ({ value }) => {
  if (value === null || value === undefined) {
    return null;
  }
  
  const formattedValue = parseFloat(value).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  
  const [integerPart, decimalPart] = formattedValue.split(".");
  
  const integerPartWithSeparator = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  
  return (
    <span>
      ₦{integerPartWithSeparator}.{decimalPart}
    </span>
  );
};

export default ThousandSeparator;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import documentService from './documentService'

const initialState = {
    document: null,
    documents: [],
    isError: false,
    isSuccess: false,
    isCreate: false,
    isLoading: false,
    message: "",
}

//Create new document
export const addDocument = createAsyncThunk(
    "documents/add",
    async ({ id, documentData }, thunkAPI) => {
        try {
            return await documentService.addDocument(id, documentData)
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// get all documents  of a subscriber
export const getSubDocuments = createAsyncThunk(
    "documents/getSubDocuments",
    async (id, thunkAPI) => {
        try {
            return await documentService.getSubDocuments(id)
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// get all documents
export const getDocuments = createAsyncThunk(
    "documents/getdocuments",
    async (_, thunkAPI) => {
        try {
            return await documentService.getDocuments()
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

//delete a document
export const deleteDocument = createAsyncThunk(
    "documents/delete",
    async ({ id, documentData }, thunkAPI) => {
        try {
            await documentService.deleteDocument(id, documentData)
            return documentData
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

//update a document
export const updateDocument = createAsyncThunk(
    "documents/updateDocument",
    async ({ id, documentData }, thunkAPI) => {
        try {

            return await documentService.updateDocument(id, documentData)
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

//get all details of a single document
export const getDocument = createAsyncThunk(
    "plots/getDocument",
    async (id, thunkAPI) => {
        try {
            return await documentService.getDocument(id)
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)


const documentSlice = createSlice({
    name: "document",
    initialState,
    reducers: {
        CALC_OVERDUE_DOCUMENT(state, action) {

        }
    },
    extraReducers: (builder) => {
        // Define any additional reducers here
        builder

            //Create New document
            .addCase(addDocument.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(addDocument.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isCreate = true
                state.isError = false
                toast.success(action.payload)
                state.documents.push(action.payload)

            })
            .addCase(addDocument.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.isCreate = false
                state.message = action.payload
                toast.error(action.payload)
            })

            // get all documents of a subscriber
            .addCase(getSubDocuments.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getSubDocuments.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.documents = (action.payload)
                state.message = action.payload

            })
            .addCase(getSubDocuments.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                toast.error(action.payload)
            })

            // get all documents
            .addCase(getDocuments.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getDocuments.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.documents = (action.payload)
                state.message = action.payload

            })
            .addCase(getDocuments.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                toast.error(action.payload)
            })

            // delete a document
            .addCase(deleteDocument.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(deleteDocument.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.documents = state.documents.filter(document => document._id !== action.payload.documentID)
                toast.success("Document deleted successfully")
            })
            .addCase(deleteDocument.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                toast.error(action.payload)
            })

            // update a document
            .addCase(updateDocument.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(updateDocument.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                toast.success("Document updated successfully")
            })
            .addCase(updateDocument.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                toast.error(action.payload)
            })

            //get all details of a single document
            .addCase(getDocument.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getDocument.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.document = action.payload
            })
            .addCase(getDocument.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                toast.error(action.payload)
            })
    }
})

export const { CALC_OVERDUE_DOCUMENT } = documentSlice.actions

export const selectIsSuccess = (state) => state.document.isSuccess

export const selectDocument = (state) => state.document.document

export default documentSlice.reducer

import React, { useState } from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// @mui material components
import Switch from "@mui/material/Switch";



const PasswordInput = ({ value, label,  onChange, name, onPaste, disabled, variant}) => {

    const [showPassword, setShowPassword] = useState(false);

    const togglePassword = () => {
        setShowPassword(!showPassword)
    }

  return (
    <div>
      <MDBox>
              <MDInput
                type={showPassword  ? "text" : "password"}
                label={label}
                onChange={onChange} 
                value={value}
                name={name}
                onPaste={onPaste}
                disabled={disabled}
                variant = {variant}
                required
                fullWidth
              /> 
        </MDBox>
        <MDBox display="flex" alignItems="center" mb={1} >
        <MDTypography
            variant="button"
            fontWeight="regular"
            color="text"
            onClick={togglePassword}
            icon = "eye"
            sx={{ cursor: "pointer", userSelect: "none",  }}
        >
        &nbsp;&nbsp;Show Password
        </MDTypography>        
        <Switch checked={showPassword} onChange={togglePassword} />
        </MDBox >
        
    </div>
  )
}

export default PasswordInput

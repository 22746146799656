import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import agentService from './agentService'

const initialState = {
    agent: null,
    agents: [],
    isError: false,
    isSuccess: false,
    isCreate: false,
    isLoading: false,
    message: "",
}

//Create new agent
export const createAgent = createAsyncThunk(
    "agents/create",
    async (agentData, thunkAPI) => {
        try {
            return await agentService.createAgent(agentData)
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)


// get all agent filtered details
export const getAgents = createAsyncThunk(
    "agents/getAgents",
    async (_, thunkAPI) => {
        try {
            return await agentService.getAgents()
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)


// delete a agent
export const deleteAgent = createAsyncThunk(
    "agents/deleteAgent",
    async (id, thunkAPI) => {
        try {
            return await agentService.deleteAgent(id)
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

//get all details of a single agent
export const getAgent = createAsyncThunk(
    "agents/getAgent",
    async (id, thunkAPI) => {
        try {
            return await agentService.getAgent(id)
            console.log("hi")
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)


// update a agent
export const updateAgent = createAsyncThunk(
    "agents/updateAgent",
    async ({ id, agentData }, thunkAPI) => {
        try {

            return await agentService.updateAgent(id, agentData)
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)


const agentSlice = createSlice({
    name: "agent",
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        // Define any additional reducers here
        builder

            //Create New agent
            .addCase(createAgent.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(createAgent.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isCreate = true
                state.isError = false
                state.agents.push(action.payload)
                toast.success("Agent added successfully")

            })
            .addCase(createAgent.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.isCreate = false
                state.message = action.payload
                toast.error(action.payload)
            })

            // get all agent filtered details
            .addCase(getAgents.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getAgents.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.isCreate = false
                state.agents = (action.payload)
                state.agent = null

            })
            .addCase(getAgents.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.isSuccess = false
                state.message = action.payload
                toast.error(action.payload)
            })

            // Delete a agent
            .addCase(deleteAgent.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(deleteAgent.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.agents = state.agents.filter(agent => agent._id !== action.payload)
                state.message = (action.payload)
                toast.success(action.payload)

            })
            .addCase(deleteAgent.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.isSuccess = false
                state.message = action.payload
                toast.error(action.payload)
            })

            //get all details of a single agent
            .addCase(getAgent.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getAgent.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.agent = action.payload
            })
            .addCase(getAgent.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                toast.error(action.payload)
            })

            //Update a agent
            .addCase(updateAgent.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(updateAgent.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isCreate = true
                state.isError = false
                toast.success(action.payload)

            })
            .addCase(updateAgent.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.isCreate = false
                state.message = action.payload
                toast.error(action.payload)
            })
    }
})



export const selectIsSuccess = (state) => state.agent.isSuccess

export const selectAgent = (state) => state.agent.agent

export default agentSlice.reducer

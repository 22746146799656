
import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";


// Material Dashboard 2 React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "components/loader";
import { toast } from "react-toastify";
import validator from 'validator';
import { createAgent } from "redux/features/agent/agentSlice";



// Data
const initialState = {
  agentName: "",
  companyName: "",
  email: "",
  phoneNumber: "",
}



function AddNewAgent() {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { isLoading, isCreate } = useSelector((state) => state.agent)

  const [formData, setFormData] = useState(initialState);


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((formData) => ({
      ...formData,
      [name]: value,
    }));
  };

  let {
    agentName,
    companyName,
    email,
    phoneNumber,
  } = formData;


  const handleSubmit = async (e) => {
    e.preventDefault()

    //Validations
    if (!agentName || !companyName || !email || !phoneNumber) {

      toast.error("Fill all required fields");
      return;
    }

    if (!validator.isEmail(email)) {
      return toast.error('Check email again')
    }


    const agentData = {
      ...formData,
      name: agentName,
    };

    await dispatch(createAgent(agentData))
  }


  useEffect(() => {
    if (isCreate) {
      navigate("/agents")
    }
  }, [isCreate, navigate])


  return (
    <DashboardLayout>
      {isLoading && <Loader />}
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <MDTypography variant="h4" color="text" mb={3} >
          Add New Agent
        </MDTypography>
        <Grid container spacing={4} style={{ maxWidth: '50vw' }} >
          <Grid item xs={6} sm={6} >
            <MDInput
              fullWidth
              type="text"
              label="Name"
              name="agentName"
              value={agentName}
              onChange={handleInputChange}
              variant="standard"
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <MDInput
              fullWidth
              type="text"
              label="Company Name"
              name="companyName"
              value={companyName}
              onChange={handleInputChange}
              variant="standard"
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <MDInput
              type="email"
              label="Email"
              onChange={handleInputChange}
              name="email"
              value={email}
              fullWidth
              variant="standard"
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <MDInput
              fullWidth
              type="text"
              label="Phone Number"
              name="phoneNumber"
              value={phoneNumber}
              onChange={handleInputChange}
              variant="standard"
            />
          </Grid>
          <Grid item xs={6}>
            <MDBox mb={1}>
              <MDButton variant="gradient" color="info" onClick={handleSubmit} >
                Add Agent
              </MDButton>
            </MDBox>
          </Grid>

        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddNewAgent;

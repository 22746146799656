import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
const API_URL = `${BACKEND_URL}/api/payment/`

// create New payment
const addPayment = async (id, paymentData) => {
    const response = await axios.post(`${API_URL}add/${id}`, paymentData)
    return response.data.message
}

// get all payment of a subscriber
const getSubPayments = async (id) => {
    const response = await axios.get(`${API_URL}getsubpayments/${id}`)
    return response.data
}

// get all payments 
const getPayments = async () => {
    const response = await axios.get(`${API_URL}getpayments`)
    return response.data
}

// delete payment
const deletePayment = async (id, paymentData) => {
    const response = await axios.delete(`${API_URL}delete/${id}`, { data: paymentData })
    return response.data.message
}

// update payment
const updatePayment = async (id, paymentData) => {

    const response = await axios.patch(`${API_URL}updatepayment/${id}`, paymentData)
    return response.data.message
}

//get a single payment
const getPayment = async (id) => {
    const response = await axios.get(`${API_URL}getpayment/${id}`)
    return response.data
}



const paymentService = {
    addPayment,
    getSubPayments,
    getPayments,
    deletePayment,
    getPayment,
    updatePayment
}

export default paymentService
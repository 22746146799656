import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
const API_URL = `${BACKEND_URL}/api/plot/`

// Create New plot
const createPlot = async (plotData) => {
    const response = await axios.post(API_URL + "add", plotData)

    return response.data
}


// get all plot filtered details
const getPlots = async () => {
    const response = await axios.get(API_URL + "getplots")
    return response.data
}

// delete a plot
const deletePlot = async (id) => {
    const response = await axios.delete(`${API_URL}delete/${id}`)
    return response.data.message
}

//get all details of a single plot
const getPlot = async (id) => {
    const response = await axios.get(`${API_URL}getplot/${id}`)
    return response.data
}

//update a plot
const updatePlot = async (id, plotData) => {
    const response = await axios.patch(`${API_URL}updateplot/${id}`, plotData)
    return response.data.message
}

// delete offer
const deleteOffer = async (id, plotData) => {
    const response = await axios.delete(`${API_URL}deleteoffer/${id}`, { data: plotData })
    return response.data.message
}

//convert an offer
const convertOffer = async (convertOfferData) => {
    const response = await axios.patch(API_URL + "convertoffer", convertOfferData)
    return response.data.message
}



const plotService = {
    createPlot,
    getPlots,
    deletePlot,
    getPlot,
    updatePlot,
    deleteOffer,
    convertOffer
}

export default plotService
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PaymentProgressDataTable from "./data";



function PaymentProgress() {


  return (
    <MDBox>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox>
          <MDTypography variant="h6" mb={-5} >
            Installments
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox>
        <PaymentProgressDataTable />
      </MDBox>
    </MDBox>
  );
}

export default PaymentProgress;



import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";


// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sigin-page.png";
import brand from "assets/images/mansonia-bay-logo.png"

//my imports
import { toast } from "react-toastify";
import validator from 'validator';
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "redux/features/auth/authSlice";
import Loader from "components/loader";
import { RESET } from "redux/features/auth/authSlice";

function Basic() {
  const [email, setEmail] = useState("");

  const dispatch = useDispatch()
  const { isLoading } = useSelector((state) => state.auth)

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      return toast.error('Please enter an email');
    }
    if (!validator.isEmail(email)) {
      return toast.error('Invalid email address')
    }

    const staffData = {
      email
    }

    await dispatch(forgotPassword(staffData))
    await dispatch(RESET(staffData))


  }


  return (

    <BasicLayout image={bgImage}>
      {isLoading && <Loader />}
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >

          <MDBox component="img" src={brand} alt="Brand" width="10rem" mt={2} />

          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1} mb={2}>
            Forgot Password
          </MDTypography>

        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                onChange={(e) => setEmail(e.target.value)}
                name="email"
                value={email}
                fullWidth
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" onClick={handleSubmit} fullWidth>
                Get Reset Email
              </MDButton>
            </MDBox>
            <MDBox mb={1} mt={3} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account? {" "}
                <MDTypography
                  component={Link}
                  to="/"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign in
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;

/* eslint-disable react/prop-types */
/* eslint-disable react/position-component-definition */


// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import Icon from "@mui/material/Icon";
import DataTable from "components/Tables/DataTable";

// Images
import user from "assets/images/user.png";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStaffs } from "redux/features/auth/authSlice";
import Loader from "components/loader";
import PopupDialog from "layouts/utils/popupDialog";
import { deleteStaff } from "redux/features/auth/authSlice";
import { Link } from "react-router-dom";




export default function StaffDataTable() {
  const dispatch = useDispatch();
  const { staffs, isLoading } = useSelector((state) => state.auth)

  useEffect(() => {
    dispatch(getStaffs())
  }, [])


  const deleteStaffFunction = async (staffId) => {
    // make API call to delete subscriber
    await dispatch(deleteStaff(staffId));
    dispatch(getStaffs())
  };

  function extractDateFromCreatedAt(createdAt) {
    const date = new Date(createdAt);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${day}-${month}-${year}`;
  }

  const Staff = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, department }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{department}</MDTypography>
    </MDBox>
  );

  const rows = staffs.map((staff, index) => ({
    key: staff._id,
    serialNumber: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {index + 1}
      </MDTypography>
    ),
    staffDetails: <Staff image={user} name={`${staff.firstName} ${staff.lastName}`} email={staff.email} />,
    position: <Job title={staff.role} department={staff.department} />,
    status: (
      <MDBox ml={-1}>
        <MDBadge badgeContent="online" color="success" variant="gradient" size="sm" />
      </MDBox>
    ),
    joined: (
      <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        {extractDateFromCreatedAt(staff.createdAt)}
      </MDTypography>
    ),
    action: (
      <MDTypography sx={{ display: "flex", alignItems: "center" }}>

        <Icon component={Link} color="secondary" to={`/staff/editstaff/${staff._id}`} sx={{ mt: -0.25, mr: 3 }}>
          edit
        </Icon>

        <PopupDialog
          popupTitle="Delete Staff"
          popupText="Are you sure you want to delete this Staff?"
          buttonYes="Yes"
          buttonNo="Cancel"
          icon="delete"
          color="error"
          handleYes={() => deleteStaffFunction(staff._id)}
        />
      </MDTypography>

    ),

  })
  );

  const columns = [
    { Header: "sn", accessor: "serialNumber", width: "1%", align: "left" },
    { Header: "staff details", accessor: "staffDetails", width: "30%", align: "left" },
    { Header: "Access & department", accessor: "position", align: "left" },
    { Header: "status", accessor: "status", align: "center" },
    { Header: "joined", accessor: "joined", align: "center" },
    { Header: "actions", accessor: "action", align: "center" },
  ];


  return (
    <MDBox pt={3}>
      {isLoading && <Loader />}
      {!isLoading && staffs.length === 0 ? (
        <MDTypography variant="h6" px={4} pb={3} color="error">
          No Staff Found
        </MDTypography>
      ) : (
        <DataTable
          table={{ columns, rows }}
          isSorted={false}
          entriesPerPage={false}
          showTotalEntries={false}
          noEndBorder
        />
      )}

    </MDBox>

  )
}

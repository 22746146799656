

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";


// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";

// Overview page components
import Header from "layouts/subscribers/components/Header";


// Material Dashboard 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";



//import tab components
import PlotsOwned from "./components/SubscribersTab/PlotsOwned";
import PaymentProgress from "./components/SubscribersTab/PaymentProgress";

//import Tab handler
import TabPanel from "./components/TabPanel";
import Payments from "./components/SubscribersTab/Payments";
import UpcomingPayments from "./components/SubscribersTab/UpcomingPayments";
import OverduePayments from "./components/SubscribersTab/OverduePayments";
import Documents from "./components/SubscribersTab/Documents";

function SubscribersDetails() {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={12} sx={{ ml: "auto" }}>
              <AppBar position="static">
                <Tabs
                  orientation={tabsOrientation}
                  value={tabValue}
                  onChange={handleSetTabValue}
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab
                    label="Plots"
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        landscape
                      </Icon>
                    }
                  />
                  <Tab
                    label="Installments"
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        sync
                      </Icon>
                    }
                  />
                  <Tab
                    label="Payments"
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        payments
                      </Icon>
                    }
                  />
                  <Tab
                    label="Upcoming Payments"
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        receipt
                      </Icon>
                    }
                  />
                  <Tab
                    label="Overdue Payments"
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        receipt_long
                      </Icon>
                    }
                  />
                  <Tab
                    label="Documents"
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        article
                      </Icon>
                    }
                  />
                </Tabs>
                <TabPanel value={tabValue} index={0}>
                  <PlotsOwned />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  <PaymentProgress />
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                  <Payments />
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                  <UpcomingPayments />
                </TabPanel>
                <TabPanel value={tabValue} index={4}>
                  <OverduePayments />
                </TabPanel>
                <TabPanel value={tabValue} index={5}>
                  <Documents />
                </TabPanel>
              </AppBar>
            </Grid>
          </Grid>

        </MDBox>

      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default SubscribersDetails;

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDProgress from "components/MDProgress";
import { useDispatch, useSelector } from "react-redux";
import { getPlots, deletePlot } from "redux/features/plot/plotSlice";
import { useEffect, useState } from "react";
import DataTable from "components/Tables/DataTable";
import PopupDialog from "layouts/utils/popupDialog";
import ThousandSeparator from "layouts/utils/thousandSeperator";
import MDButton from "components/MDButton";
import PaymentModal from "layouts/utils/paymentModal";
import { toast } from "react-toastify";
import { addPayment } from "redux/features/payment/paymentSlice";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getSubPayments } from "redux/features/payment/paymentSlice";
import Loader from "components/loader";
import { deleteOffer } from "redux/features/plot/plotSlice";
import ConvertOfferModal from "layouts/utils/convertOfferModal";
import { getSubscribers } from "redux/features/subscriber/subscriberSlice";
import { convertOffer } from "redux/features/plot/plotSlice";
import EditOfferModal from "layouts/utils/editOfferModal";

export default function OfferDataTable() {



  const paymentTypeOptions = [
    { value: "Offer Payment", label: "Installment Payment" },
    { value: "Survey Fee", label: "Survey Fee" },
    { value: "Design Guidelines", label: "Design Guidelines" },
    { value: "Municipal Fee", label: "Municipal Fee" },
    { value: "Transfer Fee", label: "Transfer Fee" },
    { value: "Others", label: "Others" }
  ];

  const [payment, setPayment] = useState({
    paymentType: '',
    amountPaid: 0,
    paymentDate: new Date(),
  });

  const {
    paymentType,
    amountPaid,
    paymentDate,
  } = payment

  const [secondarySubscriberData, setSecondarySubscriberData] = useState({
    secondarySubscriberID: '',
    plotTransferFee: 0,
    paymentDate: new Date()
  })

  //all about editing offer
  const initialState = {
    selectedPlot: '',
    selectedAgent: 'none',
    plotType: "",
    plotSize: 0,
    pricePerSQM: 0,
    listPrice: 0,
    agentFee: 0,
    agentCommission: 0,
    oidcFee: 0,
    oidcCommission: 0,
    legalFee: 0,
    legalCommission: 0,
    otherFee: 0,
    otherCommission: 0,
    salesPrice: 0
  }


  // initalizations
  const { id } = useParams()
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(initialState);
  const [openPayment, setOpenPayment] = useState(false);
  const [openConvertOffer, setOpenConvertOffer] = useState(false);
  const [openEditOffer, setOpenEditOffer] = useState(false);
  const { plots } = useSelector((state) => state.plot);
  const { agents } = useSelector((state) => state.agent);
  const { isLoading } = useSelector((state) => state.payment);
  const { subscriber, subscribers } = useSelector((state) => state.subscriber);
  const [plotID, setPlotID] = useState("")
  const [amountPaidFormatted, setAmountPaidFormatted] = useState(payment.amountPaid)
  const [plotTransferFeeFormatted, setPlotTransferFeeFormatted] = useState(secondarySubscriberData.plotTransferFee);




  //Proof of payment handling
  const [paymentProof, setPaymentProof] = useState("");
  const [paymentProofPreview, setPaymentProofPreview] = useState(null);


  // filters
  const offerPlots = plots.filter((plot) => plot?.subscriberID === subscriber?.id);
  const otherSubscribers = subscribers.filter((subscriber) => subscriber?._id !== id);
  const availablePlots = plots.filter((plot) => !plot.subscriberID)


  const [installmentsData, setInstallmentsData] = useState([
    { percentage: 0, amountPaid: 0, expectedPaymentDate: new Date() }
  ]);

  //fetch subscribers upon refresh
  useEffect(() => {
    if (subscribers.length === 0) {
      dispatch(getSubscribers());
    }
  }, [dispatch, subscribers]);


  // functions to handle record payment modal.
  const handlePaymentInputChange = (eventOrDate) => {
    if (typeof eventOrDate === 'string') { // DatePicker change
      setPayment(prevState => ({
        ...prevState,
        paymentDate: new Date(eventOrDate),
      }));
    } else if (eventOrDate.target) { // input field change
      const { name, value } = eventOrDate.target;
      if (name === 'amountPaid') {
        const unformattedValue = value.replace(/,/g, '').replace('₦', '');

        setPayment(prevState => ({
          ...prevState,
          [name]: unformattedValue,
        }));

        setAmountPaidFormatted(value);
      } else {
        setPayment(prevState => ({
          ...prevState,
          [name]: value,
        }));
      }
    } else { // DatePicker change
      setPayment(prevState => ({
        ...prevState,
        paymentDate: eventOrDate,
      }));
    }
  };

  const handlePaymentOpenModal = (plotID) => {
    setPlotID(plotID)
    setOpenPayment(true);
  };

  const handlePaymentCloseModal = () => {
    setOpenPayment(false);
  };

  const handlePaymentAction = async (e) => {
    e.preventDefault();

    // deconstruction


    //Validations
    if (!amountPaid || amountPaid == 0 || !paymentType) {
      toast.error('Please fill all fields')
      return
    }

    if (!plotID) {
      toast.error('Plot seems to have been deleted')
      return
    }

    if (!paymentDate) {
      toast.error('Enter Payment Date')
      return
    }

    const today = new Date()
    const isPaymentDateGreaterThanToday = paymentDate > today

    if (isPaymentDateGreaterThanToday) {
      toast.error('Payment Date cannot be in the future')
      return
    }

    if (!paymentProof) {
      toast.error('Kindly upload proof of payment')
      return
    }

    handlePaymentCloseModal();



    const paymentData = new FormData()

    paymentData.append("plotID", plotID)
    paymentData.append("paymentType", paymentType)
    paymentData.append("paymentDate", paymentDate)
    paymentData.append("amountPaid", parseFloat(amountPaid))
    paymentData.append("image", paymentProof)



    await dispatch(addPayment({ id, paymentData }));
    await dispatch(getPlots());
    await dispatch(getSubPayments(id))

  };

  //functions to handle proof of payment upload and preview
  const handleImageChange = (e) => {
    setPaymentProof(e.target.files[0])
    setPaymentProofPreview(URL.createObjectURL(e.target.files[0]))

  }

  // functions to handle convert offer modal.
  const handleConvertOfferInputChange = (eventOrDate) => {
    if (typeof eventOrDate === 'string') { // DatePicker change
      setSecondarySubscriberData(prevState => ({
        ...prevState,
        paymentDate: new Date(eventOrDate),
      }));
    } else if (eventOrDate.target) { // input field change
      const { name, value } = eventOrDate.target;

      if (name === 'plotTransferFee') {
        const unformattedValue = value.replace(/,/g, '').replace('₦', '');

        setSecondarySubscriberData(prevState => ({
          ...prevState,
          [name]: unformattedValue,
        }));

        setPlotTransferFeeFormatted(value);
      } else {
        setSecondarySubscriberData(prevState => ({
          ...prevState,
          [name]: value,
        }));
      }
    } else { // DatePicker change
      setSecondarySubscriberData(prevState => ({
        ...prevState,
        paymentDate: eventOrDate,
      }));
    }
  };

  const handleConvertOfferOpenModal = (plotID) => {
    setPlotID(plotID)
    setOpenConvertOffer(true);
  };

  const handleConvertOfferCloseModal = () => {
    setOpenConvertOffer(false);
  };

  const handleConvertOfferAction = async (e) => {
    e.preventDefault();

    // deconstruction
    const {
      secondarySubscriberID,
      plotTransferFee,
      paymentDate
    } = secondarySubscriberData


    //Validations
    if (!secondarySubscriberID || plotTransferFee == 0) {
      toast.error('Please fill all fields')
      return
    }

    if (!plotID) {
      toast.error('Plot seems to have been deleted')
      return
    }

    const today = new Date()
    const isPaymentDateGreaterThanToday = paymentDate > today

    if (isPaymentDateGreaterThanToday) {
      toast.error('Payment Date cannot be in the future')
      return
    }

    if (!paymentProof) {
      toast.error('Kindly upload proof of payment')
      return
    }

    handleConvertOfferCloseModal();

    const convertOfferData = new FormData()

    convertOfferData.append("plotID", plotID)
    convertOfferData.append("secondarySubscriberID", secondarySubscriberID)
    convertOfferData.append("paymentDate", paymentDate)
    convertOfferData.append("plotTransferFee", parseFloat(plotTransferFee))
    convertOfferData.append("image", paymentProof)

    try {
      await dispatch(convertOffer(convertOfferData));
      await dispatch(getPlots());
      await dispatch(getSubPayments(id))
    } catch (error) {
      toast.error(error)
      return
    }

  };


  // functions to handle edit offer modal.
  const handleEditOfferOpenModal = (plotID) => {
    setPlotID(plotID)

    const selectedPlot = plots.find((plot) => plot._id === plotID);
    const selectedAgent = agents.find((agent) => agent._id === selectedPlot.agentID);



    // update the formData state to set the selectedPlot field to the selected plot object
    setFormData((formData) => ({
      ...formData,
      selectedPlot: `${selectedPlot.blockNumber} ${selectedPlot.plotNumber}`,
      plotType: selectedPlot?.plotType || '',
      plotSize: selectedPlot?.plotSize.$numberDecimal.toString() || 0,
      pricePerSQM: selectedPlot?.pricePerSQM.$numberDecimal.toString() || 0,
      listPrice: selectedPlot?.listPrice.$numberDecimal.toString() || 0,
      salesPrice: selectedPlot?.listPrice.$numberDecimal.toString() || 0,
      selectedAgent: selectedAgent?._id || 'none',
      agentFee: selectedPlot.agentFee,
      agentCommission: 0,
      oidcFee: selectedPlot.oidcFee,
      oidcCommission: 0,
      legalFee: selectedPlot.legalFee,
      legalCommission: 0,
      otherFee: selectedPlot.otherFee,
      otherCommission: 0,
    }))
    setOpenEditOffer(true);

  };

  const handleEditOfferCloseModal = () => {
    setOpenEditOffer(false);
  };

  //functions to process the editing of the offer
  //handle change  
  const handleInstallmentChange = (index, percentage, value, date) => {

    let data = [...installmentsData];
    data[index].percentage = percentage;
    data[index].amountPaid = value;

    if (formData.salesPrice && !isNaN(formData.salesPrice) && percentage) {
      data[index].amountPaid = (formData.salesPrice * percentage) / 100;
    }

    data[index].expectedPaymentDate = date;
    setInstallmentsData(data);
  }


  const addFields = () => {
    let newfield = { percentage: 0, amountPaid: 0, expectedPaymentDate: "" }
    setInstallmentsData([...installmentsData, newfield])
  }

  const removeFields = (index) => {

    let data = [...installmentsData];
    data.splice(index, 1)
    setInstallmentsData(data)
  }

  const handlePlotChange = (event) => {
    const { name, value } = event.target;
    const selectedPlot = availablePlots.find((plot) => plot._id === value);

    // update the formData state to set the selectedPlot field to the selected plot object
    setFormData((formData) => ({
      ...formData,
      [name]: value,
      plotType: selectedPlot?.plotType || '',
      plotSize: selectedPlot?.plotSize.$numberDecimal.toString() || 0,
      pricePerSQM: selectedPlot?.pricePerSQM.$numberDecimal.toString() || 0,
      listPrice: selectedPlot?.listPrice.$numberDecimal.toString() || 0,
      salesPrice: selectedPlot?.listPrice.$numberDecimal.toString() || 0,
      selectedAgent: 'none',
      agentFee: 0,
      agentCommission: 0,
      oidcFee: 0,
      oidcCommission: 0,
      legalFee: 0,
      legalCommission: 0,
      otherFee: 0,
      otherCommission: 0,
    }))



  };

  const handleAgentChange = (event) => {
    const { name, value } = event.target;

    const selectedPlot = availablePlots.find((plot) => plot._id === formData.selectedPlot);

    if (value === 'none') {
      setFormData((formData) => ({
        ...formData,
        selectedAgent: value,
        agentFee: 0,
        agentCommission: 0,
        oidcFee: 0,
        oidcCommission: 0,
        legalFee: 0,
        legalCommission: 0,
        otherFee: 0,
        otherCommission: 0,
        salesPrice: selectedPlot?.listPrice.$numberDecimal.toString() || 0,
      }))
    }

    setFormData((formData) => ({
      ...formData,
      selectedAgent: value,
    }))

  };


  const handleInputChange = (event) => {


    const { name, value } = event.target;

    // selction error checks
    if (!formData.selectedPlot) {
      toast.error("Select a plot");
      return;
    }

    let agentFee

    if (name === 'agentFee' && formData.selectedAgent === 'none') {
      toast.error("Select an Agent");

      return;
    }



    // formatting inputed valued
    let formattedValue = value;

    if (name === 'agentFee' || name === 'legalFee' || name === 'oidcFee' || name === 'otherFee') {
      formattedValue = value.toString()
      if (isNaN(formattedValue)) {
        formattedValue = 0;
      }
    }

    const listPrice = parseFloat(formData.listPrice);
    const agentCommission = parseFloat(formData.agentCommission);
    const legalCommission = parseFloat(formData.legalCommission);
    const oidcCommission = parseFloat(formData.oidcCommission);
    const otherCommission = parseFloat(formData.otherCommission);

    let newAgentCommission = agentCommission;
    let newLegalCommission = legalCommission;
    let newOidcCommission = oidcCommission;
    let newOtherCommission = otherCommission;





    switch (name) {
      case "agentFee":
        newAgentCommission = (parseFloat(formattedValue) / 100) * listPrice;
        break;
      case "legalFee":
        newLegalCommission = (parseFloat(formattedValue) / 100) * listPrice;
        break;

      case "oidcFee":
        newOidcCommission = (parseFloat(formattedValue) / 100) * listPrice;
        break;

      case "otherFee":

        newOtherCommission = (parseFloat(formattedValue) / 100) * listPrice;
        break;

      default:
        break;
    }


    let salesPrice = listPrice + newAgentCommission + newLegalCommission + newOidcCommission + newOtherCommission

    setFormData((formData) => ({
      ...formData,
      [name]: formattedValue,
      salesPrice,
      agentCommission: newAgentCommission.toFixed(2),
      legalCommission: newLegalCommission.toFixed(2),
      oidcCommission: newOidcCommission.toFixed(2),
      otherCommission: newOtherCommission.toFixed(2),
    }));

  };


  // function to delete offer 
  const deleteOfferFunction = async (plotID) => {
    //make API call to delete offer
    const plotData = {
      plotID: plotID
    }
    await dispatch(deleteOffer({ id, plotData }));
    await dispatch(getPlots())
    await dispatch(getSubPayments(id))

  };

  const Progress = ({ color, value }) => (
    <MDBox display="flex" alignItems="center">
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {value}%
      </MDTypography>
      <MDBox ml={0.5} width="9rem">
        <MDProgress variant="gradient" color={color} value={value} />
      </MDBox>
    </MDBox>
  );


  const rows = offerPlots.map((plot, index) => {

    let amountPaid = plot?.amountPaid?.$numberDecimal?.toString() || '';
    let listPrice = plot?.listPrice?.$numberDecimal?.toString() || '';
    const salesPrice = plot?.salesPrice?.$numberDecimal?.toString() || '';
    const plotSize = plot?.plotSize?.$numberDecimal?.toString() || '';
    const pricePerSQM = plot?.pricePerSQM?.$numberDecimal?.toString() || '';

    const plotCompletion = Math.round((parseFloat(amountPaid) / parseFloat(salesPrice)) * 100);


    return {

      serialNumber: index + 1,
      plotName: `${plot?.blockNumber} ${plot?.plotNumber}`,
      phase: plot?.phase,
      amountPaid: (<ThousandSeparator value={amountPaid} />),
      listPrice: (<ThousandSeparator value={listPrice} />),
      salesPrice: (<ThousandSeparator value={salesPrice} />),
      offerType: plot?.offerType,
      completion: <Progress color="info" value={plotCompletion} />,
      plotSize: plotSize,
      pricePerSQM: (<ThousandSeparator value={pricePerSQM} />),
      payment: (
        <MDBox>
          <MDButton size="small" onClick={() => handlePaymentOpenModal(plot?._id)} variant="gradient" color="secondary" to="#">
            Record Payment
          </MDButton>

        </MDBox>
      ),
      action: (
        <MDTypography sx={{ display: "flex", alignItems: "center" }}>
          <Icon component={Link} color="secondary" onClick={() => handleEditOfferOpenModal(plot?._id)} to="#" sx={{ mt: -0.25, mr: 3 }} disabled>
            edit
          </Icon>
          <Icon component={Link} color="secondary" onClick={() => handleConvertOfferOpenModal(plot?._id)} to="#" sx={{ mt: -0.25, mr: 3 }} disabled>
            published_with_changes_icon
          </Icon>
          <PopupDialog
            popupTitle="Delete Offer"
            popupText="Are you sure you want to delete this Offer? This action is not reversible"
            buttonYes="Yes"
            buttonNo="Cancel"
            icon="delete"
            color="error"
            handleYes={() => deleteOfferFunction(plot?._id)}
          />
        </MDTypography>
      ),
    };
  });



  const columns = [
    { Header: "Plot Name", accessor: "plotName" },
    { Header: "Phase", accessor: "phase" },
    { Header: "Plot Size", accessor: "plotSize" },
    { Header: "Price per sqm", accessor: "pricePerSQM" },
    { Header: "Sales Price", accessor: "salesPrice" },
    { Header: "Amount Paid", accessor: "amountPaid" },
    { Header: "Completion", accessor: "completion" },
    { Header: "Offer type", accessor: "offerType" },
    { Header: "Payment", accessor: "payment" },
    { Header: "Actions", accessor: "action" },
  ];



  return (
    <MDBox >
      {isLoading && <Loader />}
      {!isLoading && offerPlots.length === 0 ? (
        <MDTypography variant="h6" px={4} pb={3} color="error">
          Subscriber has no plot offer yet.
        </MDTypography>
      ) : (
        <MDBox>
          <ConvertOfferModal
            open={openConvertOffer}
            onClose={handleConvertOfferCloseModal}
            onAction={handleConvertOfferAction}
            title="Convert Offer"
            imagePreview={paymentProofPreview}
            handleImageChange={handleImageChange}
            secondarySubscriberData={secondarySubscriberData}
            plotTransferFeeFormatted={plotTransferFeeFormatted}
            handleInputChange={handleConvertOfferInputChange}
            subscribers={otherSubscribers}
            description="Convert Offer"
            actionButtonLabel="Convert Offer"
          />

          <EditOfferModal
            open={openEditOffer}
            onClose={handleEditOfferCloseModal}
            onAction={handleConvertOfferAction}
            formData={formData}
            installmentsData={installmentsData}
            addFields={addFields}
            removeFields={removeFields}
            plots={availablePlots}
            agents={agents}
            handlePlotChange={handlePlotChange}
            handleAgentChange={handleAgentChange}
            handleInstallmentChange={handleInstallmentChange}
            handleInputChange={handleInputChange}
            title="Edit Offer"
            description="Assign the plot to the subscriber?"
            actionButtonLabel="Edit Offer"
          />

          <PaymentModal
            open={openPayment}
            onClose={handlePaymentCloseModal}
            onAction={handlePaymentAction}
            title="Record Payment"
            payment={payment}
            imagePreview={paymentProofPreview}
            handleImageChange={handleImageChange}
            amountPaidFormatted={amountPaidFormatted}
            handleInputChange={handlePaymentInputChange}
            paymentTypeOptions={paymentTypeOptions}
            description="Record Payment for this plot"
            actionButtonLabel="Record Payment"
          />
          <DataTable table={{ columns, rows }}
            isSorted={true}
            entriesPerPage={true}
            showTotalEntries={true}
            canSearch={true}
            noEndBorder
          />
        </MDBox>
      )}

    </MDBox>

  )
}


import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from '@mui/material/Box';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";




// Material Dashboard 2 React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createSubscriber } from "redux/features/subscriber/subscriberSlice";
import Loader from "components/loader";
import { toast } from "react-toastify";

import validator from 'validator';

// Data
const initialState = {
  fileNumber: '',
  firstName: '',
  middleName: '',
  lastName: '',
  email: '',
  residentialAddress: '',
  phoneNumber: '',
  altPhoneNumber: '',
  nokFirstName: '',
  nokMiddleName: '',
  nokLastName: '',
  nokEmail: '',
  nokResidentialAddress: '',
  nokPhoneNumber: '',
  nokRelationship: '',
  coFirstName: '',
  coLastName: '',
  coEmail: '',
  coPhoneNumber: '',
  coResidentialAddress: '',
  nameOfEntry: '',
  rcNumber: '',
  typeOfBusiness: '',
  officeAddress: '',
  aoFirstName: '',
  aoMiddleName: '',
  aoLastName: '',
  aoEmail: '',
  aoResidentialAddress: '',
  aoPhoneNumber: '',
  isIndividual: true,
  isCorporate: false,
  isCoOwner: false,

}

function AddNewSubscriber() {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { isLoading, isCreate } = useSelector((state) => state.subscriber)


  const toggleForm = () => {
    setFormData({
      ...formData,
      isIndividual: !formData.isIndividual,
      isCorporate: !formData.isCorporate
    });

  }

  const toggleCoOwner = () => {
    setFormData({
      ...formData,
      isCoOwner: !formData.isCoOwner
    });
  }

  const submitIndividual = async (e) => {
    e.preventDefault()

    //validation
    if (isIndividual) {
      if (!firstName || !lastName || !nokLastName || !nokFirstName) {
        return toast.error('Please fill all fields')
      }
    }

    if (!validator.isEmail(email)) {
      return toast.error('Enter a valid email address')
    }


    if (isIndividual && isCoOwner) {
      if (!firstName || !lastName || !nokLastName || !nokFirstName || !coFirstName || !coLastName) {
        return toast.error('Please fill all fields')
      }
    }

    if (isCorporate) {
      if (!aoLastName || !aoFirstName) {
        return toast.error('Please fill all fields')
      }
    }


    const name = `${lastName} ${firstName} ${middleName}`;
    const nokName = `${nokLastName} ${nokFirstName} ${nokMiddleName}`
    const aoName = `${aoLastName} ${aoFirstName} ${aoMiddleName}`
    const coName = `${coLastName} ${coFirstName}`

    const subscriberData = { ...formData, name, nokName, aoName, coName };



    await dispatch(createSubscriber(subscriberData))


  }



  const [formData, setFormData] = useState(initialState);

  const {
    fileNumber,
    firstName,
    middleName,
    lastName,
    email,
    residentialAddress,
    phoneNumber,
    altPhoneNumber,
    nokFirstName,
    nokMiddleName,
    nokLastName,
    nokEmail,
    nokResidentialAddress,
    nokPhoneNumber,
    nokRelationship,
    coFirstName,
    coLastName,
    coEmail,
    coPhoneNumber,
    coResidentialAddress,
    nameOfEntry,
    rcNumber,
    typeOfBusiness,
    officeAddress,
    aoFirstName,
    aoMiddleName,
    aoLastName,
    aoEmail,
    aoResidentialAddress,
    aoPhoneNumber,
    isCoOwner,
    isCorporate,
    isIndividual

  } = formData

  const handleInputChange = (e) => {
    const { name, value } = e.target

    setFormData({ ...formData, [name]: value })
  }

  useEffect(() => {
    if (isCreate) {
      navigate("/subscribers")

    }
  }, [isCreate, navigate])


  return (
    <DashboardLayout>
      {isLoading && <Loader />}
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={9}>
            <MDBox px={3}>
              <MDTypography variant="h4" color="text" mb={3} >
                Add New Subscriber
              </MDTypography>
              <FormGroup>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    py: 3,
                    px: 5,
                    width: '50%',
                    bgcolor: 'background.paper'
                  }}
                >
                  <MDBox mr={2}>
                    <FormControlLabel control={<Checkbox name="isIndividual" checked={isIndividual} onChange={toggleForm} />} label="Individual" />
                  </MDBox>
                  <MDBox>
                    <FormControlLabel control={<Checkbox name="isCorporate" checked={isCorporate} onChange={toggleForm} />} label="Corporate" />
                  </MDBox>
                </Box>
              </FormGroup>

              {isIndividual ? (

                <MDBox component="form" role="form">
                  <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize" mt={2} >
                    Subscriber's Details
                  </MDTypography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      width: "100%",
                      py: 2,
                    }}
                  >
                    <MDBox mr={2}>
                      <MDInput
                        type="text"
                        label="File Number"
                        onChange={handleInputChange}
                        name="fileNumber"
                        value={fileNumber}
                        fullWidth
                      />
                    </MDBox>
                    <MDBox mr={2} sx={{ flexGrow: 1 }}>
                      <MDInput
                        type="text"
                        label="First Name"
                        onChange={handleInputChange}
                        name="firstName"
                        value={firstName}
                        fullWidth
                      />
                    </MDBox>
                    <MDBox mr={2} sx={{ flexGrow: 1 }}>
                      <MDInput
                        type="text"
                        label="Middle Name"
                        onChange={handleInputChange}
                        name="middleName"
                        value={middleName}
                        fullWidth
                      />
                    </MDBox>
                    <MDBox sx={{ flexGrow: 1 }}>
                      <MDInput
                        type="text"
                        label="Last name"
                        onChange={handleInputChange}
                        name="lastName"
                        value={lastName}
                        fullWidth
                      />
                    </MDBox>
                  </Box>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="Email Address"
                      onChange={handleInputChange}
                      name="email"
                      value={email}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="Residential Address"
                      onChange={handleInputChange}
                      name="residentialAddress"
                      value={residentialAddress}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="tel"
                      label="Phone Number"
                      onChange={handleInputChange}
                      name="phoneNumber"
                      value={phoneNumber}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="tel"
                      label="Alternative Phone Number"
                      onChange={handleInputChange}
                      name="altPhoneNumber"
                      value={altPhoneNumber}
                      fullWidth
                    />
                  </MDBox>
                  <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize" >
                    Next of Kin
                  </MDTypography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      width: "100%",
                      py: 2,
                    }}
                  >
                    <MDBox mr={2} sx={{ flexGrow: 1 }}>
                      <MDInput
                        type="text"
                        label="First Name"
                        onChange={handleInputChange}
                        name="nokFirstName"
                        value={nokFirstName}
                        fullWidth
                      />
                    </MDBox>
                    <MDBox mr={2} sx={{ flexGrow: 1 }}>
                      <MDInput
                        type="text"
                        label="Middle Name"
                        onChange={handleInputChange}
                        name="nokMiddleName"
                        value={nokMiddleName}
                        fullWidth
                      />
                    </MDBox>
                    <MDBox sx={{ flexGrow: 1 }}>
                      <MDInput
                        type="text"
                        label="Last name"
                        onChange={handleInputChange}
                        name="nokLastName"
                        value={nokLastName}
                        fullWidth
                      />
                    </MDBox>
                  </Box>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="Email Address"
                      onChange={handleInputChange}
                      name="nokEmail"
                      value={nokEmail}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="Residential Address"
                      onChange={handleInputChange}
                      name="nokResidentialAddress"
                      value={nokResidentialAddress}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="tel"
                      label="Phone Number"
                      onChange={handleInputChange}
                      name="nokPhoneNumber"
                      value={nokPhoneNumber}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="Relationship"
                      onChange={handleInputChange}
                      name="nokRelationship"
                      value={nokRelationship}
                      fullWidth
                    />
                  </MDBox>

                  <FormGroup>
                    <MDBox mb={2}>
                      <FormControlLabel control={<Checkbox name="isCoOwner" checked={isCoOwner} onChange={toggleCoOwner} />} label="Co Owner" />
                    </MDBox>
                  </FormGroup>
                  {isCoOwner === true && (
                    <MDBox>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          width: "100%",
                        }}
                      >
                        <MDBox mr={2} sx={{ flexGrow: 1 }}>
                          <MDInput
                            type="text"
                            label="First Name"
                            onChange={handleInputChange}
                            name="coFirstName"
                            value={coFirstName}
                            fullWidth
                          />
                        </MDBox>
                        <MDBox sx={{ flexGrow: 1 }}>
                          <MDInput
                            type="text"
                            label="Last name"
                            onChange={handleInputChange}
                            name="coLastName"
                            value={coLastName}
                            fullWidth
                          />
                        </MDBox>
                      </Box>

                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          width: "100%",
                          py: 2,
                        }}
                      >
                        <MDBox mr={2} sx={{ flexGrow: 1 }}>
                          <MDInput
                            type="email"
                            label="Email Name"
                            onChange={handleInputChange}
                            name="coEmail"
                            value={coEmail}
                            fullWidth
                          />
                        </MDBox>
                        <MDBox sx={{ flexGrow: 1 }}>
                          <MDInput
                            type="tel"
                            label="Phone Number"
                            onChange={handleInputChange}
                            name="coPhoneNumber"
                            value={coPhoneNumber}
                            fullWidth
                          />
                        </MDBox>
                      </Box>
                      <MDBox mb={2}>
                        <MDInput
                          type="text"
                          label="Residential Address"
                          onChange={handleInputChange}
                          name="coResidentialAddress"
                          value={coResidentialAddress}
                          fullWidth
                        />
                      </MDBox>
                    </MDBox>
                  )}
                </MDBox>
              ) : (
                <MDBox component="form" role="form">
                  <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize" mt={2} >
                    Subscriber's Details
                  </MDTypography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      width: "100%",
                      py: 2,
                    }}
                  >
                    <MDBox mr={2}>
                      <MDInput
                        type="text"
                        label="File Number"
                        onChange={handleInputChange}
                        name="fileNumber"
                        value={fileNumber}
                        fullWidth
                      />
                    </MDBox>
                    <MDBox mr={2}>
                      <MDInput
                        type="text"
                        label="RC Number"
                        onChange={handleInputChange}
                        name="rcNumber"
                        value={rcNumber}
                        fullWidth
                      />
                    </MDBox>
                    <MDBox sx={{ flexGrow: 1 }}>
                      <MDInput
                        type="text"
                        label="Name of Entry"
                        onChange={handleInputChange}
                        name="nameOfEntry"
                        value={nameOfEntry}
                        fullWidth
                      />
                    </MDBox>

                  </Box>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="Email Address"
                      onChange={handleInputChange}
                      name="email"
                      value={email}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="Office Address"
                      onChange={handleInputChange}
                      name="officeAddress"
                      multiline rows={3}
                      value={officeAddress}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="tel"
                      label="Phone Number"
                      onChange={handleInputChange}
                      name="phoneNumber"
                      value={phoneNumber}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="tel"
                      label="Alternative Phone Number"
                      onChange={handleInputChange}
                      name="altPhoneNumber"
                      value={altPhoneNumber}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="Type of Business"
                      onChange={handleInputChange}
                      name="typeOfBusiness"
                      value={typeOfBusiness}
                      fullWidth
                    />
                  </MDBox>
                  <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize" >
                    Authorizing officer
                  </MDTypography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      width: "100%",
                      py: 2,
                    }}
                  >
                    <MDBox mr={2} sx={{ flexGrow: 1 }}>
                      <MDInput
                        type="text"
                        label="First Name"
                        onChange={handleInputChange}
                        name="aoFirstName"
                        value={aoFirstName}
                        fullWidth
                      />
                    </MDBox>
                    <MDBox mr={2} sx={{ flexGrow: 1 }}>
                      <MDInput
                        type="text"
                        label="Middle Name"
                        onChange={handleInputChange}
                        name="aoMiddleName"
                        value={aoMiddleName}
                        fullWidth
                      />
                    </MDBox>
                    <MDBox sx={{ flexGrow: 1 }}>
                      <MDInput
                        type="text"
                        label="Last name"
                        onChange={handleInputChange}
                        name="aoLastName"
                        value={aoLastName}
                        fullWidth
                      />
                    </MDBox>
                  </Box>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="Email Address"
                      onChange={handleInputChange}
                      name="aoEmail"
                      value={aoEmail}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="Residential Address"
                      onChange={handleInputChange}
                      name="aoResidentialAddress"
                      multiline rows={3}
                      value={aoResidentialAddress}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="tel"
                      label="Phone Number"
                      onChange={handleInputChange}
                      name="aoPhoneNumber"
                      value={aoPhoneNumber}
                      fullWidth
                    />
                  </MDBox>

                </MDBox>
              )}
              <MDBox mt={4} mb={1}>
                <MDButton variant="gradient" color="info" onClick={submitIndividual}  >
                  Submit
                </MDButton>

              </MDBox>

            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddNewSubscriber;

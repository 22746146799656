import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from '@mui/material/Box';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { NumericFormat } from 'react-number-format';




// Material Dashboard 2 React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { updatePlot, getPlot } from "redux/features/plot/plotSlice";
import Loader from "components/loader";
import { toast } from "react-toastify";
import { selectPlot } from "redux/features/plot/plotSlice";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";



function EditPlot() {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(false)


  const { plot, isCreate } = useSelector((state) => state.plot)


  const [formData, setFormData] = useState({
    blockNumber: plot?.blockNumber || '',
    plotNumber: plot?.plotNumber || '',
    plotType: plot?.plotType || "",
    phase: plot?.phase || "",
    pricePerSQM: plot?.pricePerSQM.$numberDecimal || 0,
    plotSize: plot?.plotSize.$numberDecimal || 0,
    listPrice: plot?.listPrice.$numberDecimal || 0,
  });


  const {
    blockNumber,
    plotNumber,
    plotType,
    phase,
    pricePerSQM,
    plotSize,
    listPrice
  } = formData


  const handleInputChange = (event) => {
    const { name, value } = event.target;

    const newPricePerSQM = name === 'pricePerSQM' ? value.replace(/₦|,/g, '') : formData.pricePerSQM;
    const formattedPricePerSQM = name === 'pricePerSQM' ? value : formData.pricePerSQM;
    const newPlotSize = name === 'plotSize' ? value.replace(/₦|,/g, '') : formData.plotSize;

    let newListPrice = 0;
    if (name === 'pricePerSQM' || name === 'plotSize') {
      const pricePerSQMValue = name === 'pricePerSQM' ? newPricePerSQM : formData.pricePerSQM;
      const plotSizeValue = name === 'plotSize' ? newPlotSize : formData.plotSize;

      newListPrice = parseFloat(pricePerSQMValue.replace(/[^\d.-]/g, '')) * parseFloat(plotSizeValue);

      if (isNaN(newListPrice)) {
        newListPrice = 0;
      }
    }

    setFormData({
      ...formData,
      listPrice: newListPrice.toFixed(2),
      pricePerSQM: formattedPricePerSQM,
      plotSize: newPlotSize,
      [name]: value,
    });
  };




  const handleSubmit = async (e) => {
    e.preventDefault();

    const pricePerSQM = parseFloat(formData.pricePerSQM.replace(/[^\d.-]/g, ''));

    if (formData.listPrice == 0) {
      toast.error("Enter the Price Per SQM & Plot Size");
      return;
    }

    // Create plotData object and log it to console
    const plotData = {
      ...formData,
      pricePerSQM: pricePerSQM
    };




    await dispatch(updatePlot({ id, plotData }))
  }


  useEffect(() => {
    setIsLoading(true);
    async function getPlotData() {
      await dispatch(getPlot(id));
    }
    if (!plot?.blockNumber) {
      getPlotData();
    } else {
      setIsLoading(false);
      const block = plot.blockNumber.split(' ');
      const plotNum = plot.plotNumber.split(' ');
      setFormData({
        blockNumber: block[1],
        plotNumber: plotNum[1],
        plotType: plot.plotType,
        phase: plot.phase,
        plotSize: plot.plotSize.$numberDecimal,
        pricePerSQM: plot.pricePerSQM.$numberDecimal,
        listPrice: plot.listPrice.$numberDecimal,
      });
    }
  }, [dispatch, id, plot?.blockNumber]);


  useEffect(() => {
    if (isCreate) {
      navigate("/plots")
    }
  }, [isCreate, navigate])

  useEffect(() => {
    dispatch(getPlot(id));
  }, []);


  return (
    <DashboardLayout>
      {isLoading && <Loader />}
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        {!plot && !isLoading ? (
          <MDTypography variant="h6" px={4} pb={3} color="error">
            Something went wrong, please reload...
          </MDTypography>
        ) : (
          <MDBox>
            <MDTypography variant="h4" color="text" mb={3} >
              Edit Plot
            </MDTypography>
            <Grid container spacing={4} style={{ maxWidth: '50vw' }} >
              <Grid item xs={6} sm={6} >
                <MDInput
                  fullWidth
                  label="Block Number"
                  name="blockNumber"
                  value={blockNumber}
                  onChange={handleInputChange}
                  variant="standard"
                  disabled
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <MDInput
                  fullWidth
                  label="Plot Number"
                  name="plotNumber"
                  value={plotNumber}
                  onChange={handleInputChange}
                  variant="standard"
                  disabled
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <NumericFormat
                  fullWidth
                  label="Price per SQM"
                  customInput={MDInput}
                  name="pricePerSQM"
                  value={pricePerSQM}
                  thousandSeparator={true}
                  decimalScale={2}
                  prefix={'₦'}
                  allowNegative={false}
                  onValueChange={(values) => {
                    const { formattedValue } = values;
                    handleInputChange({
                      target: {
                        name: 'pricePerSQM',
                        value: formattedValue
                      }
                    });
                  }}
                  variant="standard"
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <NumericFormat
                  fullWidth
                  customInput={MDInput}
                  label="Plot Size (in meters)"
                  name="plotSize"
                  value={plotSize}
                  thousandSeparator={false}
                  decimalScale={2}
                  onChange={handleInputChange}
                  variant="standard"
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="plot-type-label">Plot Type</InputLabel>
                  <Select
                    labelId="plot-type-label"
                    name="plotType"
                    value={plotType}
                    onChange={handleInputChange}
                    label="Plot Type"
                    variant="standard"
                  >
                    <MenuItem value={"Residential"}>Residential</MenuItem>
                    <MenuItem value={"Commercial"}>Commercial</MenuItem>
                    <MenuItem value={"Others"}>Others</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="phase-label">Phase</InputLabel>
                  <Select
                    labelId="phase-label"
                    name="phase"
                    value={phase}
                    onChange={handleInputChange}
                    label="Phase"
                    variant="standard"
                  >
                    <MenuItem value={"Phase 1"}>Phase 1</MenuItem>
                    <MenuItem value={"Phase 2"}>Phase 2</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <NumericFormat
                  fullWidth
                  customInput={MDInput}
                  label="Listing Price"
                  name="listPrice"
                  value={listPrice}
                  thousandSeparator={true}
                  decimalScale={2}
                  prefix={'₦'}
                  disabled={true}
                  variant="standard"
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={6}>
                <MDBox mb={1}>
                  <MDButton variant="gradient" color="info" onClick={handleSubmit} >
                    Update Plot
                  </MDButton>
                </MDBox>
              </Grid>

            </Grid>

          </MDBox>

        )}

      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditPlot;

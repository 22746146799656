import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import MDButton from "components/MDButton";
import { NumericFormat } from "react-number-format";
import MDInput from "components/MDInput";
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import './datepicker.css';
import 'react-datepicker/dist/react-datepicker.css';
import MDBox from "components/MDBox";
import { Link } from "react-router-dom";

// Data


function ConvertOfferModal(props) {
  const { open, onClose, title, secondarySubscriberData, handleImageChange, imagePreview, plotTransferFeeFormatted, subscribers, handleInputChange, actionButtonLabel } = props;

  const {
    secondarySubscriberID,
    paymentDate
  } = secondarySubscriberData

  const [openImage, setOpenImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpenImage(true);
  };

  const handleDialogClose = () => {
    setSelectedImage(null);

    setOpenImage(false);
  };



  return (
    <MDBox >
      <Dialog open={open} onClose={onClose} maxWidth="lg" PaperProps={{ style: { width: '40vw', height: '40vh', maxHeight: 'none' } }}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent style={{ pt: '10px' }} >
          <DialogContentText> </DialogContentText>
          <Grid container spacing={4} style={{ maxWidth: '50vw' }} >
            <Grid item xs={12} sm={6} >
              <FormControl fullWidth margin="normal">
                <InputLabel>Select Secondary Subscriber</InputLabel>
                <Select
                  name="secondarySubscriberID"
                  value={secondarySubscriberID}
                  onChange={handleInputChange}
                  variant="standard"
                >
                  {subscribers.map((subscriber) => (
                    <MenuItem key={subscriber._id} value={subscriber._id}>
                      {subscriber.name ? `${subscriber.name}` : `${subscriber.nameOfEntry}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <NumericFormat
                fullWidth
                label="Plot Transfer Fee"
                customInput={MDInput}
                name="plotTransferFee"
                allowNegative={false}
                value={plotTransferFeeFormatted}
                onChange={(event, value) => handleInputChange(event, value)}
                thousandSeparator={true}
                decimalScale={2}
                prefix={'₦'}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="payment-date">Transfer Fee Payment Date</InputLabel>
              <DatePicker
                id="payment-date"
                placeholderText="Payment Date"
                label="Transfer Fee Payment Date"
                name='paymentDate'
                format="dd/MM/yyyy"
                selected={paymentDate ? new Date(paymentDate) : new Date()}
                onChange={handleInputChange}
                value={paymentDate ? format(paymentDate, 'do MMM, yyyy') : ''}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="payment-proof">Proof of Payment</InputLabel>
              <MDInput
                type="file"
                id="payment-proof"
                onChange={(e) => handleImageChange(e)}
                name="image"
                fullWidth
              />
              {imagePreview != null ? (
                <div>

                  <MDButton component={Link} to="#" onClick={() => handleImageClick(imagePreview)} sx={{ mr: -3, }} variant="text" color="secondary">
                    Click here to preview
                  </MDButton>

                </div>
              ) : (
                <p>No image selected yet.</p>
              )}
              <Dialog open={openImage} onClose={handleDialogClose}>
                <DialogTitle>Proof of Payment Preview</DialogTitle>
                <DialogContent>
                  <img src={selectedImage} alt="product" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                </DialogContent>
                <DialogActions>
                  <MDButton onClick={handleDialogClose} variant="gradient" color="error">
                    Close
                  </MDButton>
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <MDButton variant="gradient" color="info" onClick={props.onAction} >
            {actionButtonLabel}
          </MDButton>
        </DialogActions>
      </Dialog>
    </MDBox>
  );
}



export default ConvertOfferModal;

import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
const API_URL = `${BACKEND_URL}/api/dashboard/`



// get all payments for dashboard
const getPayments = async () => {
    const response = await axios.get(API_URL + "getpayments")
    return response.data
}

// get all payments for dashboard
const getPlotsLength = async () => {
    const response = await axios.get(API_URL + "getplots")
    return response.data
}


const dashboardService = {
    getPayments,
    getPlotsLength
}

export default dashboardService
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PaymentDataTable from "./data";
import { Grid } from "@mui/material";
import ComplexStatisticsCard from "components/Cards/StatisticsCards/ComplexStatisticsCard";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CALC_PAYMENTS_FOR_SUBSCRIBER } from "redux/features/payment/paymentSlice";
import { useEffect } from "react";
import { AdminOnlyLink } from "components/protect/hiddenLink";

function Payments() {

  const { id } = useParams()
  const dispatch = useDispatch();

  const { payments, totalPlotPayments, totalAgentPayments, totalOidcPayments, totalLegalPayments, totalOtherPaymentsComm, totalOtherPayments } = useSelector((state) => state.payment);

  const totalSubPayments = parseFloat(totalPlotPayments) + parseFloat(totalAgentPayments) + parseFloat(totalLegalPayments) + parseFloat(totalOtherPaymentsComm) + parseFloat(totalOidcPayments) + parseFloat(totalOtherPayments)



  useEffect(() => {
    dispatch(CALC_PAYMENTS_FOR_SUBSCRIBER(payments));
  }, [dispatch, payments]);



  return (
    <MDBox>
      <MDBox p={3}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <MDTypography variant="h6" mt={1} >
              Payments
            </MDTypography>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox>
        <PaymentDataTable />
      </MDBox>
      <MDBox pt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="money"
                title="Total Payments"
                amount={totalSubPayments}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="landscape"
                title="Plot Payments"
                amount={totalPlotPayments}
              />
            </MDBox>
          </Grid>

          <Grid item xs={12} md={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="foundation"
                title="Other Payments (Survey e.t.c)"
                amount={totalOtherPayments}
              />
            </MDBox>
          </Grid>

        </Grid>

        <AdminOnlyLink>
          <Grid container pt={5} spacing={3}>

            <Grid item xs={12} md={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="percentage"
                  title="OIDC Commission"
                  amount={totalOidcPayments}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="percentage"
                  title="Legal Commission"
                  amount={totalLegalPayments}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="percentage"
                  title="Agent Commission"
                  amount={totalAgentPayments}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="percentage"
                  title="Other Commission"
                  amount={totalOtherPaymentsComm}
                />
              </MDBox>
            </Grid>


          </Grid>

        </AdminOnlyLink>
      </MDBox>
    </MDBox>
  );
}

export default Payments;

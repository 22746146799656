

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useDispatch, useSelector } from "react-redux";
import { getPlots, deletePlot } from "redux/features/plot/plotSlice";
import Loader from "components/loader";
import { useEffect } from "react";
import DataTable from "components/Tables/DataTable";
import { getSubscribers } from "redux/features/subscriber/subscriberSlice";
import ThousandSeparator from "layouts/utils/thousandSeperator";
import { getPayments } from "redux/features/payment/paymentSlice";
import PaymentDate from "layouts/utils/paymentDate";
import { CSVLink } from "react-csv";

export default function UpcomingDataTable() {
  const dispatch = useDispatch();
  const { payments, isLoading } = useSelector((state) => state.payment);
  const { subscribers } = useSelector((state) => state.subscriber);
  const { plots } = useSelector((state) => state.plot);


  useEffect(() => {
    dispatch(getSubscribers());
  }, []);


  useEffect(() => {
    dispatch(getPayments());
  }, []);

  useEffect(() => {
    dispatch(getPlots());
  }, []);

  const upcomingPayments = payments.filter((payment) => payment?.paymentType === "Offer Payment Progress" && payment?.isUpcoming)
    .sort((a, b) => new Date(a.expectedPaymentDate) - new Date(b.expectedPaymentDate));



  const rows = upcomingPayments.map((payment, index) => {
    const matchingSubscriber = subscribers.find(subscriber => subscriber?._id === payment?.subscriberID);
    const matchingPlot = plots.find(plot => plot?._id === payment?.plotID);

    const plotCorOwnerName = matchingSubscriber ? matchingSubscriber.nameOfEntry : "Vacant";
    const plotInvOwnerName = matchingSubscriber ? matchingSubscriber.name : "Vacant";
    const plotOwnerFileNumber = matchingSubscriber ? matchingSubscriber.fileNumber : "Vacant";

    const expectedAmount = payment?.expectedAmount.$numberDecimal.toString()

    return {
      key: payment?._id,
      date: <PaymentDate payment={payment?.expectedPaymentDate} />,
      plotName: `${matchingPlot?.blockNumber} ${matchingPlot?.plotNumber}`,
      phase: matchingPlot?.phase,
      plotOwner: plotInvOwnerName || plotCorOwnerName,
      fileNumber: plotOwnerFileNumber,
      expectedAmount: <ThousandSeparator value={expectedAmount} />,
    };
  });



  const columns = [
    { Header: "Expected Payment Date", accessor: "date", align: "left" },
    { Header: "Plot Owner", accessor: "plotOwner", width: "10%", align: "left" },
    { Header: "File Number", accessor: "fileNumber", align: "left" },
    { Header: "Plot Name", accessor: "plotName", align: "left" },
    { Header: "Phase", accessor: "phase", align: "left" },
    { Header: "Amount Expected", accessor: "expectedAmount", align: "left" },
  ];

  return (
    <MDBox >
      {isLoading && <Loader />}
      {!isLoading && upcomingPayments.length === 0 ? (
        <MDTypography variant="h6" px={4} pt={3} pb={3} color="error">
          No Upcoming Payments
        </MDTypography>
      ) : (
        <MDBox>
          <CSVLink data={rows} filename={"upcoming-payments.csv"}>
            Download CSV
          </CSVLink>
          <DataTable table={{ columns, rows }}
            isSorted={true}
            entriesPerPage={true}
            showTotalEntries={true}
            canSearch={true}
            noEndBorder
          />
        </MDBox>
      )}

    </MDBox>

  )
}

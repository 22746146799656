/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */


// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDProgress from "components/MDProgress";
import { useDispatch, useSelector } from "react-redux";
import { getSubscribers, deleteSubscriber } from "redux/features/subscriber/subscriberSlice";
import Loader from "components/loader";
import { useEffect } from "react";
import DataTable from "components/Tables/DataTable";
import PopupDialog from "layouts/utils/popupDialog";
import { Link, NavLink } from "react-router-dom";
import { getPlots } from "redux/features/plot/plotSlice";

export default function SubscriberTableData() {
  const dispatch = useDispatch();
  const { subscribers, isLoading } = useSelector((state) => state.subscriber);
  const { plots } = useSelector((state) => state.plot);


  useEffect(() => {
    dispatch(getSubscribers());
  }, []);

  useEffect(() => {
    dispatch(getPlots());
  }, []);



  const deleteSubscriberFunction = async (subscriberId) => {

    // make API call to delete subscriber
    await dispatch(deleteSubscriber(subscriberId));
    dispatch(getSubscribers())
  };


  const Progress = ({ color, value }) => (
    <MDBox display="flex" alignItems="center">
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {value}%
      </MDTypography>
      <MDBox ml={0.5} width="9rem">
        <MDProgress variant="gradient" color={color} value={value} />
      </MDBox>
    </MDBox>
  );


  const rows = subscribers.map((subscriber, index) => {
    const subscriberPlots = plots.filter(plot => plot?.subscriberID === subscriber?._id);
    const numberOfPlots = subscriberPlots.length || 0;
    const totalAmountPaid = subscriberPlots.reduce((total, plot) => total + parseFloat(plot?.amountPaid?.$numberDecimal), 0);
    const totalSalesPrice = subscriberPlots.reduce((total, plot) => total + parseFloat(plot?.salesPrice?.$numberDecimal), 0);


    let plotCompletion = parseFloat((totalAmountPaid / totalSalesPrice) * 100).toFixed(2);


    if (isNaN(plotCompletion)) {
      plotCompletion = 0;
    }

    return {
      key: subscriber?._id,
      serialNumber: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {index + 1}
        </MDTypography>
      ),
      fileNumber: subscriber?.fileNumber,
      subscriber: subscriber?.name ? subscriber?.name : subscriber?.nameOfEntry,
      numberofplots: (
        <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
          {numberOfPlots}
        </MDTypography>
      ),
      completion: <Progress color="info" value={plotCompletion || 0} />,
      action: (
        <MDTypography sx={{ display: "flex", alignItems: "center" }}>
          <Icon component={Link} color="secondary" to={`/subscribers/${subscriber?._id}`} sx={{ mt: -0.25 }}>
            remove_red_eye_icon
          </Icon>
          <Icon component={Link} color="secondary" to={`/subscribers/editsubscriber/${subscriber?._id}`} sx={{ mt: -0.25, ml: 3, mr: 3 }}>
            edit
          </Icon>
          <PopupDialog
            popupTitle="Delete Subscriber"
            popupText="Are you sure you want to delete this Subscriber?"
            buttonYes="Yes"
            buttonNo="Cancel"
            icon="delete"
            color="error"
            handleYes={() => deleteSubscriberFunction(subscriber?._id)}
          />
        </MDTypography>
      ),
    };
  });



  const columns = [
    { Header: "SN", accessor: "serialNumber", width: "1%", align: "left" },
    { Header: "Subscriber", accessor: "subscriber", width: "30%", align: "left" },
    { Header: "File Number", accessor: "fileNumber", width: "10%", align: "left" },
    { Header: "Number of plots", accessor: "numberofplots", align: "center" },
    { Header: "payment completion", accessor: "completion", align: "center" },
    { Header: "action", accessor: "action", align: "center" },
  ];



  return (
    <MDBox >
      {isLoading && <Loader />}
      {!isLoading && subscribers.length === 0 ? (
        <MDTypography variant="h6" px={4} pb={3} color="error">
          No Subscriber Found
        </MDTypography>
      ) : (
        <DataTable table={{ columns, rows }}
          isSorted={true}
          entriesPerPage={true}
          showTotalEntries={true}
          canSearch={true}
          noEndBorder
        />
      )}

    </MDBox>

  )
}

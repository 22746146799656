import { useState, useEffect } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

//import custom component
import PasswordInput from "components/PasswordInput";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sigin-page.png";
import brand from "assets/images/mansonia-bay-logo.png"

//My imports
import { toast } from "react-toastify";
import validator from 'validator';
import { useDispatch, useSelector } from "react-redux";
import { RESET } from "redux/features/auth/authSlice";
import Loader from "components/loader";
import { login } from "redux/features/auth/authSlice";

const initialState = {
  email: "",
  password: "",
}

function Basic() {

  const [formData, setFormData] = useState(initialState);
  const { email, password } = formData


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { isLoading, isLoggedIn, isSuccess, message } = useSelector((state) => state.auth)


  const handleSubmit = async (e) => {
    e.preventDefault();

    //Validations
    if (!email || !password) {
      return toast.error('Please fill all fields');
    }
    if (!validator.isEmail(email)) {
      return toast.error('Invalid email')
    }

    const staffData = {
      email, password
    }

    await dispatch(login(staffData))
  }

  useEffect(() => {
    if (isSuccess && isLoggedIn) {
      navigate("/dashboard")
    }
    dispatch(RESET())
  }, [isLoggedIn, isSuccess, dispatch, navigate])



  return (
    <BasicLayout image={bgImage}>
      {isLoading && <Loader />}

      <Card>
        <MDBox>
          <MDTypography variant="h2" fontWeight="medium" color="error" mt={4} mb={4} mr={3} ml={3}>
            THIS IS FOR FEATURES TESTING ONLY
          </MDTypography>
        </MDBox>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >

          <MDBox component="img" src={brand} alt="Brand" width="10rem" mt={2} />

          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1} mb={2}>
            Sign in
          </MDTypography>

        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                onChange={handleInputChange}
                name="email"
                value={email}
                required
                fullWidth
              />
            </MDBox>
            <PasswordInput
              type="password"
              label="Password"
              onChange={handleInputChange}
              name="password"
              value={password}
              fullWidth
            />
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" onClick={handleSubmit} fullWidth>
                sign in
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography
                component={Link}
                to="/forgotpassword"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                Forgot Password?
              </MDTypography>
            </MDBox>
            {/* <MDBox mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                {" "}Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/signup"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox> */}
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;

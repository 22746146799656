
import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";


// Material Dashboard 2 React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "components/loader";
import { toast } from "react-toastify";
import validator from 'validator';
import { getAgent, selectAgent, updateAgent } from "redux/features/agent/agentSlice";



function EditAgent() {

  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  const agent = useSelector(selectAgent)


  const [formData, setFormData] = useState({
    agentName: agent?.name || "",
    companyName: agent?.companyName || "",
    email: agent?.email || "",
    phoneNumber: agent?.phoneNumber || "",
  });


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((formData) => ({
      ...formData,
      [name]: value,
    }));
  };

  let {
    agentName,
    companyName,
    email,
    phoneNumber,
  } = formData;


  const handleSubmit = async (e) => {
    e.preventDefault()

    //Validations
    if (!agentName || !companyName || !email || !phoneNumber) {

      toast.error("Fill all required fields");
      return;
    }

    if (!validator.isEmail(email)) {
      return toast.error('Check email again')
    }


    const agentData = {
      ...formData,
      name: agentName,
    };

    await dispatch(updateAgent({ id, agentData }))
    navigate("/agents")
  }




  useEffect(() => {
    setIsLoading(true);
    async function getAgentData() {
      await dispatch(getAgent(id));
    }

    if (!agent?.email) {
      getAgentData();
    } else {
      setIsLoading(false);
      setFormData({
        agentName: agent.name,
        companyName: agent.companyName,
        email: agent.email,
        phoneNumber: agent.phoneNumber,
      });
    }
  }, [dispatch, id, agent?.email]);



  return (
    <DashboardLayout>
      {isLoading && <Loader />}
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <MDTypography variant="h4" color="text" mb={3} >
          Add New Agent
        </MDTypography>
        <Grid container spacing={4} style={{ maxWidth: '50vw' }} >
          <Grid item xs={6} sm={6} >
            <MDInput
              fullWidth
              type="text"
              label="Name"
              name="agentName"
              value={agentName}
              onChange={handleInputChange}
              variant="standard"
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <MDInput
              fullWidth
              type="text"
              label="Company Name"
              name="companyName"
              value={companyName}
              onChange={handleInputChange}
              variant="standard"
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <MDInput
              type="email"
              label="Email"
              onChange={handleInputChange}
              name="email"
              value={email}
              fullWidth
              variant="standard"
              disabled
              autoComplete="new-password"
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <MDInput
              fullWidth
              type="text"
              label="Phone Number"
              name="phoneNumber"
              value={phoneNumber}
              onChange={handleInputChange}
              variant="standard"
            />
          </Grid>
          <Grid item xs={6}>
            <MDBox mb={1}>
              <MDButton variant="gradient" color="info" onClick={handleSubmit} >
                Update Agent
              </MDButton>
            </MDBox>
          </Grid>

        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditAgent;

import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import authService from 'redux/features/auth/authService';

const useRedirectLoggedOutUser = (path) => {
  const navigate = useNavigate()

  useEffect(() =>{
    let isLoggedIn
    const redirectLoggedOutStaff = async () => {
        try {
            isLoggedIn = await authService.loginStatus()
        } catch (error){
            console.log(error.message)
        }
        if (!isLoggedIn) {
            toast.info("You need to Login Again")
            navigate(path)
            return
        }
    }
    redirectLoggedOutStaff()


  }, [path, navigate])
}

export default useRedirectLoggedOutUser

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useDispatch, useSelector } from "react-redux";
import { getPlots } from "redux/features/plot/plotSlice";
import Loader from "components/loader";
import { useEffect } from "react";
import DataTable from "components/Tables/DataTable";
import PopupDialog from "layouts/utils/popupDialog";
import { Link } from "react-router-dom";
import ThousandSeparator from "layouts/utils/thousandSeperator";
import { getAgents } from "redux/features/agent/agentSlice";
import PaymentDate from "layouts/utils/paymentDate";
import { deleteAgent } from "redux/features/agent/agentSlice";
import { getPayments } from "redux/features/dashboard/dashboardSlice";

export default function AgentDataTable() {
  const dispatch = useDispatch();
  const { agents, isLoading } = useSelector((state) => state.agent);
  const { plots } = useSelector((state) => state.plot);
  const { payments } = useSelector((state) => state.dashboard);


  useEffect(() => {
    dispatch(getAgents());
    dispatch(getPlots());
    dispatch(getPayments());
  }, []);




  const deleteAgentFunction = async (Id) => {

    // make API call to delete agent
    await dispatch(deleteAgent(Id));
    dispatch(getAgents())
  };


  const rows = agents.map((agent, index) => {
    const agentPlots = plots.filter(plot => plot.agentID === agent?._id);
    const agentPaymentCommission = payments.filter(payment => payment.agentID === agent?._id);

    const numberOfPlots = agentPlots.length || 0;

    const totalAgentCommission = agentPlots.reduce((total, plot) => total + parseFloat(plot?.agentCommission?.$numberDecimal), 0);
    const agentPaidCommission = agentPaymentCommission.reduce((total, payment) => total + parseFloat(payment?.agentCommission?.$numberDecimal), 0);


    return {
      key: agent?._id,
      serialNumber: index + 1,
      name: agent?.name,
      companyName: agent?.companyName,
      email: agent?.email,
      phoneNumber: agent?.phoneNumber,
      commission: <ThousandSeparator value={totalAgentCommission} />,
      paidCommission: <ThousandSeparator value={agentPaidCommission} />,
      numberOfPlots: numberOfPlots,
      joined: <PaymentDate payment={agent?.createdAt} />,
      action: (
        <MDTypography sx={{ display: "flex", alignItems: "center" }}>
          <Icon component={Link} color="secondary" to={`/agent/${agent?._id}`} sx={{ mt: -0.25 }}>
            remove_red_eye_icon
          </Icon>
          <Icon component={Link} color="secondary" to={`/agents/editagent/${agent?._id}`} sx={{ mt: -0.25, ml: 3, mr: 3 }}>
            edit
          </Icon>
          <PopupDialog
            popupTitle="Delete Agent"
            popupText="Are you sure you want to delete this Agent?"
            buttonYes="Yes"
            buttonNo="Cancel"
            icon="delete"
            color="error"
            handleYes={() => deleteAgentFunction(agent?._id)}
          />
        </MDTypography>
      ),
    };
  });



  const columns = [
    { Header: "SN", accessor: "serialNumber", width: "1%", align: "left" },
    { Header: "Name", accessor: "name", align: "left" },
    { Header: "Company Name", accessor: "companyName", align: "left" },
    { Header: "Email", accessor: "email", align: "left" },
    { Header: "Phone Number", accessor: "phoneNumber", align: "left" },
    { Header: "Joined", accessor: "joined", align: "left" },
    { Header: "Expected Commission", accessor: "commission", width: "10%", align: "left" },
    { Header: "Paid Commission", accessor: "paidCommission", width: "10%", align: "left" },
    { Header: "Number of Plots", accessor: "numberOfPlots", align: "center" },
    { Header: "Actions", accessor: "action", align: "center" },
  ];



  return (
    <MDBox >
      {isLoading && <Loader />}
      {!isLoading && agents.length === 0 ? (
        <MDTypography variant="h6" px={4} pt={3} pb={3} color="error">
          No Agents Found
        </MDTypography>
      ) : (
        <DataTable table={{ columns, rows }}
          isSorted={true}
          entriesPerPage={true}
          showTotalEntries={true}
          canSearch={true}
          noEndBorder
        />
      )}

    </MDBox>

  )
}

import { configureStore } from "@reduxjs/toolkit"
import authReducer from "../redux/features/auth/authSlice"
import subscriberReducer from "../redux/features/subscriber/subscriberSlice"
import plotReducer from "../redux/features/plot/plotSlice"
import paymentReducer from "../redux/features/payment/paymentSlice"
import agentReducer from "../redux/features/agent/agentSlice"
import dashboardReducer from "../redux/features/dashboard/dashboardSlice"
import documentReducer from "../redux/features/document/documentSlice"



export const store = configureStore({
    reducer: {
        auth: authReducer,
        subscriber: subscriberReducer,
        plot: plotReducer,
        payment: paymentReducer,
        agent: agentReducer,
        dashboard: dashboardReducer,
        document: documentReducer,

    }
})
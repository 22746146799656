// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import { CSVLink } from "react-csv";
import MDTypography from "components/MDTypography";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import DataTable from "components/Tables/DataTable";
import ThousandSeparator from "layouts/utils/thousandSeperator";
import { useParams } from "react-router-dom";
import { getSubPayments } from "redux/features/payment/paymentSlice";
import PaymentDate from "layouts/utils/paymentDate";

export default function UpcomingPaymentsDataTable() {

  const dispatch = useDispatch();
  const { id } = useParams();
  const { plots } = useSelector((state) => state.plot);
  const { payments, isLoading } = useSelector((state) => state.payment);
  const UpcomingPayments = payments.filter((payment) => payment?.paymentType === "Offer Payment Progress" && payment?.isUpcoming)

    .sort((a, b) => new Date(a.expectedPaymentDate) - new Date(b.expectedPaymentDate));

  const rows = UpcomingPayments.map((payment, index) => {

    const matchingPlot = plots.find(plot => plot._id === payment.plotID);

    const toBalance = payment?.toBalance.$numberDecimal.toString()

    return {
      plotName: `${matchingPlot?.blockNumber} ${matchingPlot?.plotNumber}`,
      phase: matchingPlot?.phase,
      date: <PaymentDate payment={payment?.expectedPaymentDate} />,
      toBalance: (<ThousandSeparator value={toBalance} />),
    };
  });



  const columns = [
    { Header: "Due Date", accessor: "date" },
    { Header: "Plot Name", accessor: "plotName" },
    { Header: "Phase", accessor: "phase" },
    { Header: "Amount", accessor: "toBalance" },
  ];

  useEffect(() => {
    dispatch(getSubPayments(id));
  }, []);

  return (
    <MDBox >
      {!isLoading && UpcomingPayments.length === 0 ? (
        <MDTypography variant="h6" pt={4} px={4} pb={3} color="error">
          Subscriber has no Upcoming Payment,
        </MDTypography>
      ) : (
        <DataTable table={{ columns, rows }}
          isSorted={true}
          entriesPerPage={true}
          showTotalEntries={true}
          canSearch={true}
          noEndBorder
        />
      )}

    </MDBox>

  )
}

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Data
import UpcomingPaymentsDataTable from "./data";

function UpcomingPayments() {

  return (
    <MDBox>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox>
          <MDTypography variant="h6" mb={-5} >
            Upcoming Payments
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox>
        <UpcomingPaymentsDataTable />
      </MDBox>
    </MDBox>
  );
}

export default UpcomingPayments;

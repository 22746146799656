import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import plotService from './plotService'

const initialState = {
    plot: null,
    plots: [],
    isError: false,
    isSuccess: false,
    isCreate: false,
    isLoading: false,
    message: "",
}

//Create new plot
export const createPlot = createAsyncThunk(
    "plots/create",
    async (plotData, thunkAPI) => {
        try {
            return await plotService.createPlot(plotData)
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)


// get all plot filtered details
export const getPlots = createAsyncThunk(
    "plots/getPlots",
    async (_, thunkAPI) => {
        try {
            return await plotService.getPlots()
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)


// delete a plot
export const deletePlot = createAsyncThunk(
    "plots/deletePlot",
    async (id, thunkAPI) => {
        try {
            return await plotService.deletePlot(id)
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

//get all details of a single plot
export const getPlot = createAsyncThunk(
    "plots/getPlot",
    async (id, thunkAPI) => {
        try {
            return await plotService.getPlot(id)
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)


// update a plot
export const updatePlot = createAsyncThunk(
    "plots/updatePlot",
    async ({ id, plotData }, thunkAPI) => {
        try {

            return await plotService.updatePlot(id, plotData)
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

//delete an offer
export const deleteOffer = createAsyncThunk(
    "plots/deleteOffer",
    async ({ id, plotData }, thunkAPI) => {
        try {
            await plotService.deleteOffer(id, plotData)
            return plotData
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// convert an offer
export const convertOffer = createAsyncThunk(
    "plots/convertOffer",
    async (convertOfferData, thunkAPI) => {
        try {

            return await plotService.convertOffer(convertOfferData)
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)


const plotSlice = createSlice({
    name: "plot",
    initialState,
    reducers: {
        CALC_LISTING_PRICE(state, action) {


        }
    },
    extraReducers: (builder) => {
        // Define any additional reducers here
        builder

            //Create New plot
            .addCase(createPlot.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(createPlot.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isCreate = true
                state.isError = false
                state.plots.push(action.payload)
                toast.success("Plot added successfully")

            })
            .addCase(createPlot.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.isCreate = false
                state.message = action.payload
                toast.error(action.payload)
            })

            // get all plot filtered details
            .addCase(getPlots.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getPlots.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.isCreate = false
                state.plots = (action.payload)
                state.plot = null

            })
            .addCase(getPlots.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.isSuccess = false
                state.message = action.payload
                toast.error(action.payload)
            })

            // Delete a plot
            .addCase(deletePlot.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(deletePlot.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.plots = state.plots.filter(plot => plot._id !== action.payload)
                state.message = (action.payload)
                toast.success(action.payload)

            })
            .addCase(deletePlot.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.isSuccess = false
                state.message = action.payload
                toast.error(action.payload)
            })

            //get all details of a single plot
            .addCase(getPlot.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getPlot.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.plot = action.payload
            })
            .addCase(getPlot.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                toast.error(action.payload)
            })

            //Update a plot
            .addCase(updatePlot.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(updatePlot.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isCreate = true
                state.isError = false
                toast.success(action.payload)

            })
            .addCase(updatePlot.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.isCreate = false
                state.message = action.payload
                toast.error(action.payload)
            })

            // delete a offer
            .addCase(deleteOffer.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(deleteOffer.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                toast.success("Offer deleted successfully")
            })
            .addCase(deleteOffer.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                toast.error(action.payload)
            })

            //convert an offer
            .addCase(convertOffer.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(convertOffer.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isCreate = true
                state.isError = false
                toast.success(action.payload)

            })
            .addCase(convertOffer.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.isCreate = false
                state.message = action.payload
                toast.error(action.payload)
            })
    }
})

export const { CALC_LISTING_PRICE } = plotSlice.actions

export const selectIsSuccess = (state) => state.plot.isSuccess

export const selectPlot = (state) => state.plot.plot

export default plotSlice.reducer

const { useSelector } = require("react-redux")
const { selectIsLoggedIn, selectLoggedInStaff } = require("../../redux/features/auth/authSlice")



export const ShowOnLogin = ({ children }) => {

    const isLoggedIn = useSelector(selectIsLoggedIn)

    if (isLoggedIn){
        return <> {children} </>
    }
    return null
}

export const AdminOnlyLink = ({ children }) => {

    const isLoggedIn = useSelector(selectIsLoggedIn)
    const staff = useSelector(selectLoggedInStaff)

    if (isLoggedIn && staff?.role === "Admin"){
        
        return <> {children} </>        
    }    
    return null
}





import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import Box from '@mui/material/Box';

//import custom component
import PasswordInput from "components/PasswordInput";

// Material Dashboard 2 React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";

//My imports
import { toast } from "react-toastify";
import validator from 'validator';
import { useDispatch, useSelector } from "react-redux";
import authSlice from "redux/features/auth/authSlice";
import { createStaff } from "redux/features/auth/authSlice";
import { RESET } from "redux/features/auth/authSlice";
import Loader from "components/loader";


// Data
const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  password: "1234567890",
  role: "User",
  department: "Operations",
}


function AddNewStaff() {


  const [staffData, setStaffData] = useState(initialState);
  const { firstName, lastName, email, password, role, department } = staffData


  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isLoading, isError } = useSelector((state) => state.auth)


  const handleInputChange = (e) => {
    const { name, value } = e.target

    setStaffData({ ...staffData, [name]: value })

  }

  const handleSubmit = async (e) => {
    e.preventDefault()


    //Validations
    if (!email || !password || !firstName || !lastName || !role || !department) {
      return toast.error('Please fill all fields')
    }
    if (!validator.isEmail(email)) {
      return toast.error('Check email again')
    }

    const staffData = {
      firstName, lastName, email, password, role, department
    }

    await dispatch(createStaff(staffData))

    if (!isError) {
      navigate("/staffs");
    }



  }

  // useEffect(() => {
  //   if (isCreate) {
  //     navigate("/staffs")
  //   }
  // }, [isCreate, navigate])

  return (
    <DashboardLayout>
      {isLoading && <Loader />}
      <DashboardNavbar />

      <MDBox pt={2} pb={3}>
        <MDTypography variant="h4" color="text" mb={3} >
          Add New Staff
        </MDTypography>
        <Grid container spacing={2} component="form" role="form">
          <Grid item xs={12} lg={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <MDInput
                  type="text"
                  label="First Name"
                  onChange={handleInputChange}
                  name="firstName"
                  value={firstName}
                  fullWidth
                  variant="standard"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MDInput
                  type="text"
                  label="Last Name"
                  onChange={handleInputChange}
                  name="lastName"
                  value={lastName}
                  fullWidth
                  variant="standard"
                />
              </Grid>
              <Grid item xs={12}>
                <MDInput
                  type="email"
                  label="Email"
                  onChange={handleInputChange}
                  name="email"
                  value={email}
                  fullWidth
                  variant="standard"
                />
              </Grid>
              <Grid item xs={12}>
                <PasswordInput
                  type="password"
                  label="Password"
                  onChange={handleInputChange}
                  name="password"
                  value="1234567890"
                  disabled={true}
                  variant="standard"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="role-label">Role</InputLabel>
                  <Select
                    labelId="role-label"
                    name="role"
                    value={role}
                    label="role"
                    defaultValue="User"
                    onChange={handleInputChange}
                    variant="standard"
                  >
                    <MenuItem value={"User"}>User</MenuItem>
                    <MenuItem value={"Admin"}>Admin</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="department-label">Department</InputLabel>
                  <Select
                    labelId="department-label"
                    name="department"
                    value={department}
                    label="department"
                    defaultValue="Operations"
                    onChange={handleInputChange}
                    variant="standard"
                  >
                    <MenuItem value={"Operations"}>Operations</MenuItem>
                    <MenuItem value={"Finance"}>Finance</MenuItem>
                    <MenuItem value={"IT"}>IT</MenuItem>
                    <MenuItem value={"Procurement & Logistics"}>Procurement & Logistics</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <MDBox mt={2} mb={1}>
                  <MDButton variant="gradient" color="info" onClick={handleSubmit} >
                    Add Staff
                  </MDButton>
                </MDBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddNewStaff;


import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


// react-router-dom components
import { Link, useNavigate, useParams } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import Box from '@mui/material/Box';

//import custom component
import PasswordInput from "components/PasswordInput";

// Material Dashboard 2 React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";

//My imports
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/loader";
import { getStaff } from "redux/features/auth/authSlice";
import { updateStaff } from "redux/features/auth/authSlice";
import { selectStaff } from "redux/features/auth/authSlice";




function EditStaff() {

  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const staff = useSelector(selectStaff)


  const [staffData, setStaffData] = useState({
    firstName: staff?.firstName || "",
    lastName: staff?.lastName || "",
    email: staff?.email || "",
    department: staff?.department || "",
    role: staff?.role || "",
    password: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setStaffData({ ...staffData, [name]: value })
  }

  const { firstName, lastName, email, password, role, department } = staffData


  useEffect(() => {
    setIsLoading(true);

    async function getStaffData() {
      await dispatch(getStaff(id));

    }

    if (!staff?.email) {
      getStaffData();
    } else {
      setIsLoading(false);
      setStaffData({
        firstName: staff.firstName,
        lastName: staff.lastName,
        email: staff.email,
        department: staff.department,
        role: staff.role,
        password: "",
      });
    }
  }, [dispatch, id, staff?.email]);





  const handleSubmit = async (e) => {
    e.preventDefault()


    //Validations
    if (!email || !firstName || !lastName || !role || !department) {
      return toast.error('Please fill all fields')
    }


    const staffData = {
      firstName, lastName, email, password, role, department
    }

    await dispatch(updateStaff({ id, staffData }))
    navigate("/staffs")

  }


  return (
    <DashboardLayout>
      {isLoading && <Loader />}
      <DashboardNavbar />

      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <MDBox px={3}>
              <MDTypography variant="h4" color="text" mb={3} >
                Edit Staff
              </MDTypography>
              {!staff && !isLoading ? (
                <MDTypography variant="h6" px={4} pb={3} color="error">
                  Something went wrong, please reload...
                </MDTypography>
              ) : (
                <MDBox component="form" role="form">
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      py: 2,
                      justifyContent: 'space-between',
                      width: "100%"
                    }}
                  >
                    <MDBox mr={2} sx={{ flexGrow: 1 }}>
                      <MDInput
                        type="text"
                        label="First Name"
                        onChange={handleInputChange}
                        name="firstName"
                        variant="standard"
                        value={firstName}
                        fullWidth
                      />
                    </MDBox>
                    <MDBox sx={{ flexGrow: 1 }}>
                      <MDInput
                        type="text"
                        label="Last Name"
                        onChange={handleInputChange}
                        name="lastName"
                        value={lastName}
                        variant="standard"
                        fullWidth
                      />
                    </MDBox>

                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      width: "100%"
                    }}
                  >
                    <MDBox mr={2} sx={{ flexGrow: 1 }}>

                      <MDInput
                        id="field1"
                        label="Email"
                        onChange={handleInputChange}
                        name="field"
                        value={email}
                        fullWidth
                        variant="standard"
                        disabled
                        autoComplete="new-password"
                      />
                    </MDBox>
                    <MDBox sx={{ flexGrow: 1 }}>
                      <PasswordInput
                        type="password"
                        label="Password"
                        onChange={handleInputChange}
                        name="password"
                        value={password}
                        disabled={false}
                        variant="standard"
                      />
                    </MDBox>

                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      width: "100%"
                    }}
                  >
                    <MDBox mr={2} sx={{ flexGrow: 1 }} >
                      <FormControl fullWidth>
                        <InputLabel id="role-label">Role</InputLabel>
                        <Select
                          labelId="role-label"
                          name="role"
                          value={role}
                          label="role"
                          defaultValue="User"
                          onChange={handleInputChange}
                          variant="standard"
                        >
                          <MenuItem value={"User"}>User</MenuItem>
                          <MenuItem value={"Admin"}>Admin</MenuItem>
                        </Select>
                      </FormControl>

                    </MDBox>
                    <MDBox sx={{ flexGrow: 1 }}>
                      <FormControl fullWidth>
                        <InputLabel id="department-label">Department</InputLabel>
                        <Select
                          labelId="department-label"
                          name="department"
                          value={department}
                          label="department"
                          defaultValue="Operations"
                          onChange={handleInputChange}
                          variant="standard"
                        >
                          <MenuItem value={"Operations"}>Operations</MenuItem>
                          <MenuItem value={"Finance"}>Finance</MenuItem>
                          <MenuItem value={"IT"}>IT</MenuItem>
                          <MenuItem value={"Procurement & Logistics"}>Procurement & Logistics</MenuItem>
                        </Select>
                      </FormControl>
                    </MDBox>
                  </Box>
                  <MDBox mt={2} mb={1}>
                    <MDButton variant="gradient" color="info" onClick={handleSubmit} >
                      Update Staff
                    </MDButton>

                  </MDBox>
                </MDBox>
              )}

            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditStaff;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import dashboardService from './dashboardService'

const initialState = {
    payments: [],
    plotsQuantity: [],
    monthlyTotalsArray: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
    totalOfferPayments: 0,
    totalAgentPayments: 0,
    totalOidcPayments: 0,
    totalLegalPayments: 0,
    totalOtherPaymentsComm: 0,
    totalPlotPayments: 0,
    totalOtherPayments: 0,
    totalOverduePayments: 0,
    totalUpcomingPayments: 0,
    totalAgentCommission: 0,
    totalAgentsPlot: 0,
    phase1Payments: 0,
    phase2Payments: 0,
    phase1OtherPayments: 0,
    phase2OtherPayments: 0,

}


// get all dashboard filtered details
export const getPayments = createAsyncThunk(
    "dashboards/getPayments",
    async (_, thunkAPI) => {
        try {
            return await dashboardService.getPayments()
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)



const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        CALC_PLOT_PAYMENTS(state, action) {
            const payments = action.payload;

            const otherPayments = payments.filter((payment) => payment?.paymentType === "Offer Payment")
            const totalPayments = otherPayments.reduce((accumulator, payment) => accumulator + parseFloat(payment?.amountPaid?.$numberDecimal || 0), 0);

            state.totalOfferPayments = totalPayments;
        },
        CALC_OTHER_PAYMENTS(state, action) {
            const payments = action.payload;

            const otherPayments = payments.filter((payment) => payment?.paymentType !== "Offer Payment" && payment?.paymentType !== "Offer Payment Progress")
            const totalPayments = otherPayments.reduce((accumulator, payment) => accumulator + parseFloat(payment?.amountPaid?.$numberDecimal || 0), 0);

            state.totalOtherPayments = totalPayments;
        },
        CALC_OVERDUE_PAYMENTS(state, action) {
            const payments = action.payload;

            const otherPayments = payments.filter((payment) => payment?.paymentType === "Offer Payment Progress" && payment?.isOverdue)
            const totalPayments = otherPayments.reduce((accumulator, payment) => accumulator + parseFloat(payment?.toBalance?.$numberDecimal), 0);

            state.totalOverduePayments = totalPayments;
        },
        CALC_UPCOMING_PAYMENTS(state, action) {
            const payments = action.payload;

            const otherPayments = payments.filter((payment) => payment?.paymentType === "Offer Payment Progress" && payment?.isUpcoming)
            const totalPayments = otherPayments.reduce((accumulator, payment) => accumulator + parseFloat(payment?.expectedAmount?.$numberDecimal), 0);

            state.totalUpcomingPayments = totalPayments;
        },
        CALC_PLOTS_QUANTITY(state, action) {

            const plots = action.payload;
            const totalPlots = plots.length;
            const phase1Plots = plots.filter(plot => plot?.phase === 'Phase 1').length;
            const phase2Plots = plots.filter(plot => plot?.phase === 'Phase 2').length;

            const unsubscribedPlots = plots.filter(plot => !plot?.subscriberID)
            const totalUnsubscribedPlots = unsubscribedPlots.length
            const unsubscribedPhase1Plots = unsubscribedPlots?.filter(plot => plot?.phase === 'Phase 1').length;
            const unsubscribedPhase2Plots = unsubscribedPlots?.filter(plot => plot?.phase === 'Phase 2').length;

            //getting owners in each phase
            const phase1PlotsAll = plots.filter(plot => plot?.phase === 'Phase 1');
            const uniquePlot1SubscriberIDs = [...new Set(phase1PlotsAll
                .filter(plot => plot.subscriberID) // Filter out plots without a subscriberID
                .map(plot => plot.subscriberID))];
            const phase1PlotsCount = uniquePlot1SubscriberIDs.length;



            const phase2PlotsAll = plots.filter(plot => plot?.phase === 'Phase 2');
            const uniquePlot2SubscriberIDs = [...new Set(phase2PlotsAll
                .filter(plot => plot.subscriberID) // Filter out plots without a subscriberID
                .map(plot => plot.subscriberID))];
            const phase2PlotsCount = uniquePlot2SubscriberIDs.length;


            const plotLengths = {
                totalPlots: totalPlots,
                phase1Plots: phase1Plots,
                phase2Plots: phase2Plots,
                phase1PlotsCount: phase1PlotsCount,
                phase2PlotsCount: phase2PlotsCount,
                totalUnsubscribedPlots: totalUnsubscribedPlots,
                unsubscribedPhase1Plots: unsubscribedPhase1Plots,
                unsubscribedPhase2Plots: unsubscribedPhase2Plots,
            };

            state.plotsQuantity = plotLengths;
        },
        CALC_PLOT_PAYMENTS_BY_PHASES(state, action) {
            const plots = action.payload;

            const phase1Plots = plots.filter(plot => plot?.phase === 'Phase 1');
            const phase2Plots = plots.filter(plot => plot?.phase === 'Phase 2');

            const phase1Payments = phase1Plots.reduce((accumulator, plot) => accumulator + parseFloat(plot?.amountPaid?.$numberDecimal || 0), 0);
            const phase2Payments = phase2Plots.reduce((accumulator, plot) => accumulator + parseFloat(plot?.amountPaid?.$numberDecimal || 0), 0);

            state.phase1Payments = phase1Payments || 0
            state.phase2Payments = phase2Payments || 0

        },
        CALC_OTHER_PAYMENTS_BY_PHASES(state, action) {
            const payments = action.payload;

            const phase1OtherPayments = payments.filter((payment) => payment?.phase === 'Phase 1' && payment?.paymentType !== 'Offer Payment Progress' && payment?.paymentType !== 'Offer Payment');
            const phase2OtherPayments = payments.filter((payment) => payment?.phase === 'Phase 2' && payment?.paymentType !== 'Offer Payment Progress' && payment?.paymentType !== 'Offer Payment');


            const phase1OtherPaymentsTotal = phase1OtherPayments.reduce((accumulator, payment) => accumulator + parseFloat(payment?.amountPaid?.$numberDecimal || 0), 0);
            const phase2OtherPaymentsTotal = phase2OtherPayments.reduce((accumulator, payment) => accumulator + parseFloat(payment?.amountPaid?.$numberDecimal || 0), 0);


            state.phase1OtherPayments = phase1OtherPaymentsTotal || 0
            state.phase2OtherPayments = phase2OtherPaymentsTotal || 0

        },
        CALC_AGENTS_COMMISSION(state, action) {
            const plots = action.payload;

            const totalAgentsPlot = plots.filter(plot => plot?.agentID).length
            state.totalAgentsPlot = totalAgentsPlot

            const agentCommission = plots.reduce((accumulator, plot) => accumulator + parseFloat(plot?.agentCommission?.$numberDecimal || 0), 0);
            state.totalAgentCommission = agentCommission;
        },
        CALC_PAYMENTS_BREAKDOWN(state, action) {
            const payments = action.payload;

            const offerPayments = payments.filter((payment) => payment?.paymentType === 'Offer Payment');

            const totalPlotPayments = offerPayments.reduce((accumulator, payment) => accumulator + parseFloat(payment?.offerCommission?.$numberDecimal || 0), 0);
            const totalAgentPayments = offerPayments.reduce((accumulator, payment) => accumulator + parseFloat(payment?.agentCommission?.$numberDecimal || 0), 0);
            const totalOidcPayments = offerPayments.reduce((accumulator, payment) => accumulator + parseFloat(payment?.oidcCommission?.$numberDecimal || 0), 0);
            const totalLegalPayments = offerPayments.reduce((accumulator, payment) => accumulator + parseFloat(payment?.legalCommission?.$numberDecimal || 0), 0);
            const totalOtherPaymentsComm = offerPayments.reduce((accumulator, payment) => accumulator + parseFloat(payment?.otherCommission?.$numberDecimal || 0), 0);


            state.totalPlotPayments = totalPlotPayments.toFixed(2)
            state.totalAgentPayments = totalAgentPayments.toFixed(2)
            state.totalOidcPayments = totalOidcPayments.toFixed(2)
            state.totalLegalPayments = totalLegalPayments.toFixed(2)
            state.totalOtherPaymentsComm = totalOtherPaymentsComm.toFixed(2)


        },
        CALC_REVENUE_PROGRESS_MONTLY(state, action) {
            const payments = action.payload;
            const filteredPayments = payments.filter(payment => payment?.paymentType !== "Offer Payment Progress");

            // Initialize an object to hold monthly totals
            const monthlyTotals = {
                Jan: 0,
                Feb: 0,
                Mar: 0,
                Apr: 0,
                May: 0,
                Jun: 0,
                Jul: 0,
                Aug: 0,
                Sep: 0,
                Oct: 0,
                Nov: 0,
                Dec: 0,
            };

            // Calculate monthly totals
            filteredPayments.forEach(payment => {
                const paymentDate = new Date(payment?.paymentDate);
                const paymentMonth = paymentDate.toLocaleString('default', { month: 'short' });
                const paymentAmount = Number(payment?.amountPaid?.$numberDecimal);
                monthlyTotals[paymentMonth] += paymentAmount;
            });

            // Convert monthly totals object to an array
            const monthlyTotalsArray = Object.keys(monthlyTotals).map(month => ({
                month,
                amountPaid: monthlyTotals[month],
            }));

            state.monthlyTotalsArray = monthlyTotalsArray;
        },
    },
    extraReducers: (builder) => {
        // Define any additional reducers here
        builder

            // get all dashboard filtered details
            .addCase(getPayments.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getPayments.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.payments = (action.payload)

            })
            .addCase(getPayments.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.isSuccess = false
                state.message = action.payload
                toast.error(action.payload)
            })


    }
})


export const { CALC_PLOT_PAYMENTS, CALC_OTHER_PAYMENTS, CALC_OVERDUE_PAYMENTS, CALC_UPCOMING_PAYMENTS, CALC_PLOTS_QUANTITY, CALC_AGENTS_COMMISSION, CALC_REVENUE_PROGRESS_MONTLY, CALC_PLOT_PAYMENTS_BY_PHASES, CALC_OTHER_PAYMENTS_BY_PHASES, CALC_PAYMENTS_BREAKDOWN } = dashboardSlice.actions;

export const selectIsSuccess = (state) => state.dashboard.isSuccess


export default dashboardSlice.reducer

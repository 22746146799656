
import { useEffect, useState } from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DataTable from "components/Tables/DataTable";

// Data
import data from "layouts/subscribers/components/SubscribersTab/PlotsOwned/data";
import { Link, useParams } from "react-router-dom";
import MDButton from "components/MDButton";
import { Grid, Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getPlots } from "redux/features/plot/plotSlice";
import { addPayment } from "redux/features/payment/paymentSlice";
import { toast } from "react-toastify";
import OfferDataTable from "./data";
import OfferModal from "layouts/utils/offerModal";
import { updatePlot } from "redux/features/plot/plotSlice";
import Loader from "components/loader";
import { getSubPayments } from "redux/features/payment/paymentSlice";

//Data


function PlotsOwned() {

  //declarations
  const [open, setOpen] = useState(false);

  //construction
  const initialState = {
    selectedPlot: '',
    selectedAgent: 'none',
    plotType: "",
    plotSize: 0,
    pricePerSQM: 0,
    listPrice: 0,
    agentFee: 0,
    agentCommission: 0,
    oidcFee: 0,
    oidcCommission: 0,
    legalFee: 0,
    legalCommission: 0,
    otherFee: 0,
    otherCommission: 0,
    salesPrice: 0
  }

  const { id } = useParams()
  const [formData, setFormData] = useState(initialState);
  const dispatch = useDispatch();
  const { agents = [] } = useSelector((state) => state.agent);
  const { plots = [], isLoading } = useSelector((state) => state.plot);
  const availablePlots = plots.filter((plot) => !plot.subscriberID)

  const [installmentsData, setInstallmentsData] = useState([
    { percentage: 0, amountPaid: 0, expectedPaymentDate: new Date() }
  ]);



  //handle change  
  const handleInstallmentChange = (index, percentage, value, date) => {



    let data = [...installmentsData];
    data[index].percentage = percentage;

    if (formData.salesPrice && !isNaN(formData.salesPrice) && percentage) {
      data[index].amountPaid = (formData.salesPrice * percentage) / 100;
    } else {
      data[index].amountPaid = 0;
    }

    data[index].expectedPaymentDate = date;
    setInstallmentsData(data);
  }


  const addFields = () => {
    let newfield = { percentage: 0, amountPaid: 0, expectedPaymentDate: "" }
    setInstallmentsData([...installmentsData, newfield])
  }

  const removeFields = (index) => {

    let data = [...installmentsData];
    data.splice(index, 1)
    setInstallmentsData(data)
  }

  const handlePlotChange = (event) => {
    const { name, value } = event.target;
    const selectedPlot = availablePlots.find((plot) => plot._id === value);

    // update the formData state to set the selectedPlot field to the selected plot object
    setFormData((formData) => ({
      ...formData,
      [name]: value,
      plotType: selectedPlot?.plotType || '',
      plotSize: selectedPlot?.plotSize.$numberDecimal.toString() || 0,
      pricePerSQM: selectedPlot?.pricePerSQM.$numberDecimal.toString() || 0,
      listPrice: selectedPlot?.listPrice.$numberDecimal.toString() || 0,
      salesPrice: selectedPlot?.listPrice.$numberDecimal.toString() || 0,
      selectedAgent: 'none',
      agentFee: 0,
      agentCommission: 0,
      oidcFee: 0,
      oidcCommission: 0,
      legalFee: 0,
      legalCommission: 0,
      otherFee: 0,
      otherCommission: 0,
    }))



  };

  const handleAgentChange = (event) => {
    const { name, value } = event.target;

    const selectedPlot = availablePlots.find((plot) => plot._id === formData.selectedPlot);

    if (value === 'none') {
      setFormData((formData) => ({
        ...formData,
        selectedAgent: value,
        agentFee: 0,
        agentCommission: 0,
        oidcFee: 0,
        oidcCommission: 0,
        legalFee: 0,
        legalCommission: 0,
        otherFee: 0,
        otherCommission: 0,
        salesPrice: selectedPlot?.listPrice.$numberDecimal.toString() || 0,
      }))
    }

    setFormData((formData) => ({
      ...formData,
      selectedAgent: value,
    }))

  };


  const handleInputChange = (event) => {


    const { name, value } = event.target;

    // selction error checks

    if (!formData.selectedPlot) {
      toast.error("Select a plot");
      return;
    }

    let agentFee

    if (name === 'agentFee' && formData.selectedAgent === 'none') {
      toast.error("Select an Agent");

      return;
    }



    // formatting inputed valued
    let formattedValue = value;

    if (name === 'agentFee' || name === 'legalFee' || name === 'oidcFee' || name === 'otherFee') {
      formattedValue = value.toString()
      if (isNaN(formattedValue)) {
        formattedValue = 0;
      }
    }

    const listPrice = parseFloat(formData.listPrice);
    const agentCommission = parseFloat(formData.agentCommission);
    const legalCommission = parseFloat(formData.legalCommission);
    const oidcCommission = parseFloat(formData.oidcCommission);
    const otherCommission = parseFloat(formData.otherCommission);

    let newAgentCommission = agentCommission;
    let newLegalCommission = legalCommission;
    let newOidcCommission = oidcCommission;
    let newOtherCommission = otherCommission;





    switch (name) {
      case "agentFee":
        newAgentCommission = (parseFloat(formattedValue) / 100) * listPrice;
        break;
      case "legalFee":
        newLegalCommission = (parseFloat(formattedValue) / 100) * listPrice;
        break;

      case "oidcFee":
        newOidcCommission = (parseFloat(formattedValue) / 100) * listPrice;
        break;

      case "otherFee":

        newOtherCommission = (parseFloat(formattedValue) / 100) * listPrice;
        break;

      default:
        break;
    }


    let salesPrice = listPrice + newAgentCommission + newLegalCommission + newOidcCommission + newOtherCommission

    setFormData((formData) => ({
      ...formData,
      [name]: formattedValue,
      salesPrice,
      agentCommission: newAgentCommission.toFixed(2),
      legalCommission: newLegalCommission.toFixed(2),
      oidcCommission: newOidcCommission.toFixed(2),
      otherCommission: newOtherCommission.toFixed(2),
    }));

  };

  //toggle Modal
  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleAction = async (e) => {
    e.preventDefault();



    if (!formData.selectedPlot) {
      toast.error("Select a plot")
      return;
    }

    if (formData.selectedAgent !== 'none' && formData.agentFee == 0) {
      toast.error("Enter Agent Fee");
      return;
    }

    if (!formData.oidcFee || formData.oidcFee == 0) {
      toast.error("Enter OIDC Fee")
      return;
    }

    if (!formData.legalFee || formData.legalFee == 0) {
      toast.error("Enter Legal Fee")
      return;
    }

    if (!formData.otherFee) {
      toast.error("Enter Other Fee")
      return;
    }

    console.log(formData)



    const totalAmount = installmentsData.reduce((total, plot) => total + plot.amountPaid, 0);

    if (!totalAmount) {
      toast.error("Enter installmental payments")
      return;
    }

    let previousDate = null;
    let datesAreValid = true;
    let datesAreInOrder = true
    installmentsData.forEach((installment) => {
      if (!installment.expectedPaymentDate || !installment.amountPaid) {
        datesAreValid = false;
      } else {
        const currentDate = new Date(installment.expectedPaymentDate);
        if (previousDate && currentDate <= previousDate) {
          datesAreInOrder = false;
        }
        previousDate = currentDate;
      }
    });

    if (!datesAreValid) {
      toast.error("Enter all installmental payments dates and amounts")
      return;
    }

    if (!datesAreInOrder) {
      toast.error("All installmental payments date should be in increasing order")
      return;
    }

    if (totalAmount !== parseFloat(formData.salesPrice)) {
      toast.error("Total Installmental Payments must be equal to the Plot's Sales Price")
      return;
    }

    handleCloseModal()

    const plotData = {
      agentID: formData.selectedAgent,
      salesPrice: formData.salesPrice,
      agentFee: formData.agentFee,
      agentCommission: formData.agentCommission,
      legalFee: formData.legalFee,
      legalCommission: formData.legalCommission,
      oidcFee: formData.oidcFee,
      oidcCommission: formData.oidcCommission,
      otherFee: formData.otherFee,
      otherCommission: formData.otherCommission,
    };

    const promises = installmentsData.map(async (item, index) => {
      const paymentData = {
        plotID: formData.selectedPlot,
        paymentType: 'Offer Payment Progress',
        expectedPaymentDate: item.expectedPaymentDate,
        expectedAmount: item.amountPaid,
      }

      const response = await dispatch(addPayment({ id, paymentData }));
      return response;
    });

    await dispatch(updatePlot({ id: formData.selectedPlot, plotData }));
    await dispatch(getPlots());
    await dispatch(getSubPayments(id))

    Promise.all(promises)
      .then((responses) => {
        // All promises resolved successfully
        toast.success('Offer created successfully');
        return

      })
      .catch((error) => {
        // At least one promise was rejected
        toast.error('Error adding payments, delete offer and try again');
        return

      });

  };



  return (
    <MDBox>
      {isLoading && <Loader />}
      <MDBox p={3}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <MDTypography variant="h6" mt={1} >
              Plots Owned
            </MDTypography>
          </Grid>
          <Grid item>
            <MDButton onClick={handleOpenModal} variant="gradient" color="secondary" to="#">
              Create Offer
            </MDButton>
            <OfferModal
              open={open}
              onClose={handleCloseModal}
              onAction={handleAction}
              formData={formData}
              installmentsData={installmentsData}
              addFields={addFields}
              removeFields={removeFields}
              plots={availablePlots}
              agents={agents}
              handlePlotChange={handlePlotChange}
              handleAgentChange={handleAgentChange}
              handleInstallmentChange={handleInstallmentChange}
              handleInputChange={handleInputChange}
              title="Create Offer"
              description="Assign the plot to the subscriber?"
              actionButtonLabel="Create Offer"
            />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox>
        <OfferDataTable />
      </MDBox>
    </MDBox>
  );
}

export default PlotsOwned;



/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import SubscribersTable from "layouts/subscribers"
import PlotsTable from "layouts/plots";


// @mui icons
import Icon from "@mui/material/Icon";
import AllPaymentsTable from "layouts/payments/all-payments";
import UpcomingPaymentTable from "layouts/payments/upcoming-payments";
import OverduePaymentsTable from "layouts/payments/overdue-payments";

const sideNavRoutes = [
  {
    type: "title",
    title: "Operations",
    key: "opertaions",
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Subscribers",
    key: "subscribers",
    icon: <Icon fontSize="small">account_circle</Icon>,
    route: "/subscribers",
    component: <SubscribersTable />,
  },
  {
    type: "collapse",
    name: "Plots",
    key: "plots",
    icon: <Icon fontSize="small">landscape</Icon>,
    route: "/plots",
    component: <PlotsTable />,
  },
  {
    type: "title",
    title: "Finance",
    key: "finance",
  },
  {
    type: "collapse",
    name: "All Payments",
    key: "payments",
    icon: <Icon fontSize="small">money</Icon>,
    route: "/payments",
    component: <AllPaymentsTable />,
  },
  {
    type: "collapse",
    name: "Upcoming Payments",
    key: "upcomingpayments",
    icon: <Icon fontSize="small">add_card</Icon>,
    route: "/upcomingpayments",
    component: <UpcomingPaymentTable />,
  },
  {
    type: "collapse",
    name: "Overdue Payments",
    key: "overduepayments",
    icon: <Icon fontSize="small">error</Icon>,
    route: "/overduepayments",
    component: <OverduePaymentsTable />,
  },

];

export default sideNavRoutes;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import subscriberService from './subscriberService'

const initialState = {
    subscriber: null,
    subscribers: [],
    isError: false,
    isSuccess: false,
    isCreate: false,
    isLoading: false,
    message: "",
}

//Create new subscriber
export const createSubscriber = createAsyncThunk(
    "subscribers/create",
    async (subscriberData, thunkAPI) => {
        try {
            return await subscriberService.createSubscriber(subscriberData)
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)


// get all subscriber filtered details
export const getSubscribers = createAsyncThunk(
    "subscribers/getSubscribers",
    async (_, thunkAPI) => {
        try {
            return await subscriberService.getSubscribers()
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)


// delete a subscriber
export const deleteSubscriber = createAsyncThunk(
    "subscribers/deleteSubscriber",
    async (id, thunkAPI) => {
        try {
            return await subscriberService.deleteSubscriber(id)
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

//get all details of a single subscriber
export const getSubscriber = createAsyncThunk(
    "subscribers/getSubscriber",
    async (id, thunkAPI) => {
        try {
            return await subscriberService.getSubscriber(id)
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)


// update a subscriber
export const updateSubscriber = createAsyncThunk(
    "subscribers/updateSubscriber",
    async ({ id, subscriberData }, thunkAPI) => {
        try {

            return await subscriberService.updateSubscriber(id, subscriberData)
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// update a subscriber image
export const updateSubscriberPicture = createAsyncThunk(
    "subscribers/updateSubscriberPicture",
    async ({ id, subscriberData }, thunkAPI) => {
        try {

            return await subscriberService.updateSubscriberPicture(id, subscriberData)
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)


const subscriberSlice = createSlice({
    name: "subscriber",
    initialState,
    reducers: {
        CALC_PAYMENT_PROGRESS(state, action) {

        }
    },
    extraReducers: (builder) => {
        // Define any additional reducers here
        builder

            //Create New subscriber
            .addCase(createSubscriber.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(createSubscriber.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isCreate = true
                state.isError = false
                state.subscribers.push(action.payload)
                toast.success("Subscriber added successfully")

            })
            .addCase(createSubscriber.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.isCreate = false
                state.message = action.payload
                toast.error(action.payload)
            })

            // get all subscriber filtered details
            .addCase(getSubscribers.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getSubscribers.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.isCreate = false
                state.subscribers = (action.payload)
                state.subscriber = null

            })
            .addCase(getSubscribers.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.isSuccess = false
                state.message = action.payload
                toast.error(action.payload)
            })

            // Delete a subscriber
            .addCase(deleteSubscriber.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(deleteSubscriber.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.subscribers = state.subscribers.filter(subscriber => subscriber._id !== action.payload)
                state.message = (action.payload)
                toast.success(action.payload)

            })
            .addCase(deleteSubscriber.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.isSuccess = false
                state.message = action.payload
                toast.error(action.payload)
            })

            //get all details of a single subscriber
            .addCase(getSubscriber.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getSubscriber.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.subscriber = action.payload
            })
            .addCase(getSubscriber.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                toast.error(action.payload)
            })

            //Update a subscriber
            .addCase(updateSubscriber.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(updateSubscriber.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isCreate = true
                state.isError = false
                toast.success("Subscriber updated Successfully")

            })
            .addCase(updateSubscriber.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.isCreate = false
                state.message = action.payload
                toast.error(action.payload)
            })

            //Update a subscriber Impage
            .addCase(updateSubscriberPicture.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(updateSubscriberPicture.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isCreate = true
                state.isError = false
                toast.success("Image updated Successfully")

            })
            .addCase(updateSubscriberPicture.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.isCreate = false
                state.message = action.payload
                toast.error(action.payload)
            })
    }
})

export const { CALC_PAYMENT_PROGRESS } = subscriberSlice.actions

export const selectIsSuccess = (state) => state.subscriber.isSuccess

export const selectSubscriber = (state) => state.subscriber.subscriber

export default subscriberSlice.reducer

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDProgress from "components/MDProgress";
import { useDispatch, useSelector } from "react-redux";
import { getPlots, deletePlot } from "redux/features/plot/plotSlice";
import Loader from "components/loader";
import { useEffect } from "react";
import DataTable from "components/Tables/DataTable";
import PopupDialog from "layouts/utils/popupDialog";
import { Link, NavLink } from "react-router-dom";
import { getSubscribers } from "redux/features/subscriber/subscriberSlice";
import ThousandSeparator from "layouts/utils/thousandSeperator";

export default function PlotDataTable() {
  const dispatch = useDispatch();
  const { plots, isLoading } = useSelector((state) => state.plot);
  const { subscribers } = useSelector((state) => state.subscriber);


  useEffect(() => {
    dispatch(getSubscribers());
  }, []);


  useEffect(() => {
    dispatch(getPlots());
  }, []);

  const deletePlotFunction = async (plotId) => {

    // make API call to delete plot
    await dispatch(deletePlot(plotId));
    dispatch(getPlots())
  };


  const rows = plots.map((plot, index) => {
    const matchingSubscriber = subscribers.find(subscriber => subscriber._id === plot.subscriberID);
    const plotCorOwnerName = matchingSubscriber ? matchingSubscriber.nameOfEntry : "Vacant";
    const plotInvOwnerName = matchingSubscriber ? matchingSubscriber.name : "Vacant";
    const plotOwnerFileNumber = matchingSubscriber ? matchingSubscriber.fileNumber : "Vacant";

    const listPrice = plot?.listPrice?.$numberDecimal ? Number(plot.listPrice.$numberDecimal).toFixed(2) : "N/A";
    const salesPrice = plot?.salesPrice?.$numberDecimal ? Number(plot.salesPrice.$numberDecimal).toFixed(2) : "N/A";
    const plotSize = plot?.plotSize?.$numberDecimal ? Number(plot.plotSize.$numberDecimal).toFixed(2) : "N/A";
    const pricePerSQM = plot?.pricePerSQM?.$numberDecimal ? Number(plot.pricePerSQM.$numberDecimal).toFixed(2) : "N/A";

    return {
      key: plot?.plotID,
      serialNumber: index + 1,
      plotName: `${plot?.blockNumber} ${plot?.plotNumber}`,
      phase: plot?.phase,
      plotOwner: plotInvOwnerName || plotCorOwnerName,
      fileNumber: plotOwnerFileNumber,
      listPrice: <ThousandSeparator value={listPrice} />,
      salesPrice: <ThousandSeparator value={salesPrice} />,
      offerType: plot?.offerType || "Nil",
      plotType: plot?.plotType,
      plotSize: plotSize,
      pricePerSQM: <ThousandSeparator value={pricePerSQM} />,
      action: (
        <MDTypography sx={{ display: "flex", alignItems: "center" }}>
          <Icon component={Link} color="secondary" to={`/plots/editplot/${plot?._id}`} sx={{ mt: -0.25, ml: 3, mr: 3 }}>
            edit
          </Icon>
          <PopupDialog
            popupTitle="Delete Plot"
            popupText="Are you sure you want to delete this Plot?"
            buttonYes="Yes"
            buttonNo="Cancel"
            icon="delete"
            color="error"
            handleYes={() => deletePlotFunction(plot?._id)}
          />
        </MDTypography>
      ),
    };
  });



  const columns = [
    { Header: "SN", accessor: "serialNumber", width: "1%", align: "left" },
    { Header: "Plot Name", accessor: "plotName", align: "left" },
    { Header: "Phase", accessor: "phase", align: "left" },
    { Header: "Plot Owner", accessor: "plotOwner", width: "10%", align: "left" },
    { Header: "File Number", accessor: "fileNumber", align: "left" },
    { Header: "Listing Price", accessor: "listPrice", align: "left" },
    { Header: "Sales Price", accessor: "salesPrice", align: "left" },
    { Header: "Offer Type", accessor: "offerType", align: "center" },
    { Header: "Plot Type", accessor: "plotType", align: "center" },
    { Header: "Plot Size (SQM)", accessor: "plotSize", align: "left" },
    { Header: "Price Per SQM", accessor: "pricePerSQM", align: "left" },
    { Header: "action", accessor: "action", align: "center" },
  ];



  return (
    <MDBox >
      {isLoading && <Loader />}
      {!isLoading && plots.length === 0 ? (
        <MDTypography variant="h6" px={4} pt={3} pb={3} color="error">
          No Plot Found
        </MDTypography>
      ) : (
        <DataTable table={{ columns, rows }}
          isSorted={true}
          entriesPerPage={true}
          showTotalEntries={true}
          canSearch={true}
          noEndBorder
        />
      )}

    </MDBox>

  )
}

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDProgress from "components/MDProgress";
import { useDispatch, useSelector } from "react-redux";
import { getPlots, deletePlot } from "redux/features/plot/plotSlice";
import Loader from "components/loader";
import { useEffect } from "react";
import DataTable from "components/Tables/DataTable";
import PopupDialog from "layouts/utils/popupDialog";
import { Link, NavLink } from "react-router-dom";
import { getSubscribers } from "redux/features/subscriber/subscriberSlice";
import ThousandSeparator from "layouts/utils/thousandSeperator";
import { getPayments } from "redux/features/payment/paymentSlice";
import { getStaffs } from "redux/features/auth/authSlice";
import PaymentDate from "layouts/utils/paymentDate";

export default function AllPaymentsDataTable() {
  const dispatch = useDispatch();
  const { payments, isLoading } = useSelector((state) => state.payment);
  const { subscribers } = useSelector((state) => state.subscriber);
  const { plots } = useSelector((state) => state.plot);
  const { staffs } = useSelector((state) => state.auth);

  const allPayments = payments.filter((payment) => payment?.paymentType !== "Offer Payment Progress")
    .sort((a, b) => {
      const aDate = new Date(a.paymentDate);
      const bDate = new Date(b.paymentDate);

      // Compare year first
      if (aDate.getFullYear() !== bDate.getFullYear()) {
        return aDate.getFullYear() - bDate.getFullYear();
      }

      // If years are the same, compare month
      return aDate.getMonth() - bDate.getMonth();
    });


  useEffect(() => {
    dispatch(getSubscribers());
  }, []);


  useEffect(() => {
    dispatch(getPayments());
  }, []);

  useEffect(() => {
    dispatch(getPlots());
  }, []);

  useEffect(() => {
    dispatch(getStaffs())
  }, [])




  const rows = allPayments.map((payment, index) => {
    const matchingSubscriber = subscribers.find(subscriber => subscriber?._id === payment?.subscriberID);
    const matchingPlot = plots.find(plot => plot?._id === payment?.plotID);

    const plotCorOwnerName = matchingSubscriber ? matchingSubscriber.nameOfEntry : "Vacant";
    const plotInvOwnerName = matchingSubscriber ? matchingSubscriber.name : "Vacant";
    const plotOwnerFileNumber = matchingSubscriber ? matchingSubscriber.fileNumber : "Vacant";

    const amountPaid = payment?.amountPaid.$numberDecimal.toString()


    return {
      key: payment?._id,
      paymentDate: <PaymentDate payment={payment?.paymentDate} />,
      plotName: `${matchingPlot?.blockNumber} ${matchingPlot?.plotNumber}`,
      phase: matchingPlot?.phase,
      paymentType: payment?.paymentType,
      plotOwner: plotInvOwnerName || plotCorOwnerName,
      fileNumber: plotOwnerFileNumber,
      amountPaid: (<ThousandSeparator value={amountPaid} />),
      paymentStatus: 'Verified',
      approvedBy: payment?.staff,
      approvalDate: <PaymentDate payment={payment?.updatedAt} />,
    };
  });



  const columns = [
    { Header: "Payment Date", accessor: "paymentDate", align: "left" },
    { Header: "Plot Owner", accessor: "plotOwner", width: "10%", align: "left" },
    { Header: "File Number", accessor: "fileNumber", align: "left" },
    { Header: "Plot Name", accessor: "plotName", align: "left" },
    { Header: "Phase", accessor: "phase", align: "left" },
    { Header: "Purpose", accessor: "paymentType", align: "left" },
    { Header: "Amount Paid", accessor: "amountPaid", align: "left" },
    { Header: "Payment Status", accessor: "paymentStatus", align: "center" },
    { Header: "Approved By", accessor: "approvedBy", align: "left" },
    { Header: "Approval Date", accessor: "approvalDate", align: "left" },
  ];



  return (
    <MDBox >
      {isLoading && <Loader />}
      {!isLoading && allPayments.length === 0 ? (
        <MDTypography variant="h6" px={4} pt={3} pb={3} color="error">
          No Payment Found
        </MDTypography>
      ) : (
        <DataTable table={{ columns, rows }}
          isSorted={true}
          entriesPerPage={true}
          showTotalEntries={true}
          canSearch={true}
          noEndBorder
        />
      )}

    </MDBox>

  )
}

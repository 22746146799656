// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useSelector } from "react-redux";
import DataTable from "components/Tables/DataTable";
import ThousandSeparator from "layouts/utils/thousandSeperator";
import PaymentDate from "layouts/utils/paymentDate";

export default function PaymentProgressDataTable() {


  const { plots } = useSelector((state) => state.plot);
  const { payments, isLoading } = useSelector((state) => state.payment);

  const offerProgressPayments = payments.filter((payment) => payment?.paymentType === "Offer Payment Progress")
    .sort((a, b) => {
      const aDate = new Date(a.expectedPaymentDate);
      const bDate = new Date(b.expectedPaymentDate);

      // Compare year first
      if (aDate.getFullYear() !== bDate.getFullYear()) {
        return aDate.getFullYear() - bDate.getFullYear();
      }

      // If years are the same, compare month
      return aDate.getMonth() - bDate.getMonth();
    });

  const rows = offerProgressPayments.map((payment, index) => {

    const matchingPlot = plots.find(plot => plot._id === payment.plotID);

    const amountPaid = payment?.amountPaid.$numberDecimal.toString()
    const expectedAmount = payment?.expectedAmount.$numberDecimal.toString()
    const toBalance = payment?.toBalance.$numberDecimal.toString()

    return {
      plotName: `${matchingPlot?.blockNumber} ${matchingPlot?.plotNumber}`,
      phase: matchingPlot.phase,
      period: <PaymentDate payment={payment?.expectedPaymentDate} />,
      expectedAmount: (<ThousandSeparator value={expectedAmount} />),
      amountPaid: (<ThousandSeparator value={amountPaid} />),
      toBalance: (<ThousandSeparator value={toBalance} />),
    };
  });



  const columns = [
    { Header: "Expected Payment Date", accessor: "period" },
    { Header: "Plot Name", accessor: "plotName" },
    { Header: "Phase", accessor: "phase" },
    { Header: "Amount Expected", accessor: "expectedAmount" },
    { Header: "Amount Paid", accessor: "amountPaid" },
    { Header: "To Balance", accessor: "toBalance" }
  ];



  return (
    <MDBox >
      {!isLoading && offerProgressPayments.length === 0 ? (
        <MDTypography variant="h6" pt={4} px={4} pb={3} color="error">
          Subscriber has no Installments set up yet,
        </MDTypography>
      ) : (
        <DataTable table={{ columns, rows }}
          isSorted={true}
          entriesPerPage={true}
          showTotalEntries={true}
          canSearch={true}
          noEndBorder
        />
      )}

    </MDBox>

  )
}

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/loader";
import DataTable from "components/Tables/DataTable";
import PopupDialog from "layouts/utils/popupDialog";
import ThousandSeparator from "layouts/utils/thousandSeperator";
import { Link, useParams } from "react-router-dom";
import { deletePayment } from "redux/features/payment/paymentSlice";
import { getPlots } from "redux/features/plot/plotSlice";
import { getSubPayments } from "redux/features/payment/paymentSlice";
import { useEffect, useState } from "react";
import PaymentModal from "layouts/utils/paymentModal";
import { getPayment } from "redux/features/payment/paymentSlice";
import { updatePayment } from "redux/features/payment/paymentSlice";
import PaymentDate from "layouts/utils/paymentDate";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { toast } from "react-toastify";

export default function PaymentDataTable() {



  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [paymentID, setPaymentID] = useState('');
  const [plotID, setPlotID] = useState('');
  const dispatch = useDispatch();
  const { plots } = useSelector((state) => state.plot);
  const { payments, payment, isLoading } = useSelector((state) => state.payment);

  let otherPayments = [];

  if (payments && payments.length > 0) {
    otherPayments = payments.filter((payment) => payment?.paymentType !== "Offer Payment Progress")
      .sort((a, b) => {
        const aDate = new Date(a.paymentDate);
        const bDate = new Date(b.paymentDate);

        // Compare year first
        if (aDate.getFullYear() !== bDate.getFullYear()) {
          return aDate.getFullYear() - bDate.getFullYear();
        }

        // If years are the same, compare month
        return aDate.getMonth() - bDate.getMonth();
      });
  }



  //edit payment
  const paymentTypeOptions = [
    { value: "Offer Payment", label: "Offer Payment" },
    { value: "Survey Fee", label: "Survey Fee" },
    { value: "Design Guidelines", label: "Design Guidelines" },
    { value: "Municipal Fee", label: "Municipal Fee" },
    { value: "Transfer Fee", label: "Transfer Fee" },
    { value: "Others", label: "Others" }
  ];

  const initialState = {
    paymentType: payment?.paymentType || '',
    amountPaid: payment?.amountPaid?.$numberDecimal.toString() || '',
    paymentDate: new Date(),
  };

  const [formData, setFormData] = useState(initialState);
  const [amountPaidFormatted, setAmountPaidFormatted] = useState(payment?.amountPaid?.$numberDecimal.toString() || '',);
  const {
    paymentType,
    amountPaid,
    paymentDate,
  } = formData

  //update Proof of payment handling
  const [paymentProof, setPaymentProof] = useState("");
  const [paymentProofPreview, setPaymentProofPreview] = useState(null);


  //image preview modal
  const [openPreview, setOpenPreview] = useState(false);
  const [previewImage, setPreviewImage] = useState(null)

  const handlePreviewClick = (previewImage) => {
    setOpenPreview(true);
    setPreviewImage(previewImage)
  };


  //handle update payment image
  const handleImageChange = (e) => {
    setPaymentProof(e.target.files[0])
    setPaymentProofPreview(URL.createObjectURL(e.target.files[0]))

  }




  const handleOpenModal = async (paymentID, plotID) => {
    await dispatch(getPayment(paymentID));
    setPaymentID(paymentID)
    setPlotID(plotID)
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleInputChange = (eventOrDate) => {
    if (typeof eventOrDate === 'string') { // DatePicker change
      setFormData(prevState => ({
        ...prevState,
        paymentDate: new Date(eventOrDate),
      }));
    } else if (eventOrDate.target) { // input field change
      const { name, value } = eventOrDate.target;

      if (name === 'amountPaid') {
        const unformattedValue = value.replace(/,/g, '').replace('₦', '');

        setFormData(prevState => ({
          ...prevState,
          [name]: unformattedValue,
        }));

        setAmountPaidFormatted(value);
      } else {
        setFormData(prevState => ({
          ...prevState,
          [name]: value,
        }));
      }
    } else { // DatePicker change
      setFormData(prevState => ({
        ...prevState,
        paymentDate: eventOrDate,
      }));
    }
  };


  const handleAction = async (e) => {
    e.preventDefault();


    //Validations
    if (!amountPaid || amountPaid == 0 || !paymentType) {
      toast.error('Please fill all fields')
      return
    }

    if (!plotID) {
      toast.error('Plot seems to have been deleted')
      return
    }

    if (!paymentDate) {
      toast.error('Enter Payment Date')
      return
    }

    const today = new Date()
    const isPaymentDateGreaterThanToday = paymentDate > today

    if (isPaymentDateGreaterThanToday) {
      toast.error('Payment Date cannot be in the future')
      return
    }

    if (!paymentProof) {
      toast.error('Kindly upload updated proof of payment')
      return
    }

    handleCloseModal();

    const paymentData = new FormData()

    paymentData.append("plotID", plotID)
    paymentData.append("paymentID", paymentID)
    paymentData.append("paymentType", paymentType)
    paymentData.append("paymentDate", paymentDate)
    paymentData.append("amountPaid", amountPaid)
    paymentData.append("image", paymentProof)

    await dispatch(updatePayment({ id, paymentData }))
    await dispatch(getSubPayments(id))
    await dispatch(getPlots());

  };





  // delete payment
  const deletePaymentFuntion = async ({ id, paymentData }) => {
    await dispatch(deletePayment({ id, paymentData }))
    await dispatch(getSubPayments(id))
    await dispatch(getPlots());
  };

  //use effect to ensure the payment details is fectched before it can be deleted.
  useEffect(() => {
    if (!isLoading && payment) {
      setFormData({
        paymentType: payment.paymentType || '',
        amountPaid: payment.amountPaid?.$numberDecimal.toString() || '',
        paymentDate: payment.paymentDate ? new Date(payment.paymentDate) : new Date(),
      });
      setAmountPaidFormatted(payment.amountPaid?.$numberDecimal.toString() || '');
    }
  }, [isLoading, payment]);


  const rows = otherPayments.map((payment, index) => {

    const matchingPlot = plots.find(plot => plot?._id === payment?.plotID);

    const amountPaid = payment?.amountPaid.$numberDecimal.toString()

    //PaymentData for deletion
    const paymentData = {
      plotID: matchingPlot?._id,
      paymentID: payment?._id,
      paymentType: payment?.paymentType,
    }


    return {
      date: <PaymentDate payment={payment?.paymentDate} />,
      plotName: `${matchingPlot?.blockNumber} ${matchingPlot?.plotNumber}`,
      phase: matchingPlot?.phase,
      amountPaid: (<ThousandSeparator value={amountPaid} />),
      purpose: payment?.paymentType,
      approvedBy: payment?.staff,
      approvalDate: <PaymentDate payment={payment?.updatedAt} />,
      proof: (
        <MDBox display="flex" alignItems="center" lineHeight={1} ml={3} sx={{ cursor: "pointer" }}>
          <Icon component={Link} to="#" color="secondary" fontSize="small" onClick={() => handlePreviewClick(payment?.image.filePath)}>visibility</Icon>
        </MDBox>
      ),
      action: (
        <MDTypography sx={{ display: "flex", alignItems: "center" }}>
          <Icon component={Link} color="secondary" onClick={() => handleOpenModal(payment?._id, matchingPlot?._id)} to="#" sx={{ mt: -0.25, ml: 3, mr: 3 }}>
            edit
          </Icon>
          <PopupDialog
            popupTitle="Delete Payment"
            popupText="Are you sure you want to delete this Payment?"
            buttonYes="Yes"
            buttonNo="Cancel"
            icon="delete"
            color="error"
            handleYes={() => deletePaymentFuntion({ id, paymentData })}
          />
        </MDTypography>
      ),
    };
  });



  const columns = [
    { Header: "Date", accessor: "date" },
    { Header: "Plot Name", accessor: "plotName" },
    { Header: "Phase", accessor: "phase" },
    { Header: "Amount Paid", accessor: "amountPaid" },
    { Header: "Purpose", accessor: "purpose" },
    { Header: "Proof", accessor: "proof" },
    { Header: "Approved By", accessor: "approvedBy" },
    { Header: "Approval Date", accessor: "approvalDate" },
    { Header: "Actions", accessor: "action" }
  ];



  return (
    <MDBox >
      {isLoading && <Loader />}
      {!isLoading && otherPayments.length === 0 ? (
        <MDTypography variant="h6" px={4} pb={3} color="error">
          Subscriber has no Payments recorded yet,
        </MDTypography>
      ) : (
        <MDBox>

          <Dialog open={openPreview} onClose={() => setOpenPreview(false)} PaperProps={{ style: { width: '70vw', height: '60vh', maxHeight: 'none' } }}>
            <DialogTitle>Proof of Payment Preview</DialogTitle>
            <DialogContent>
              <img src={previewImage} alt="Payment Proof" style={{ width: '100%' }} />
            </DialogContent>
          </Dialog>

          <PaymentModal
            open={open}
            onClose={handleCloseModal}
            onAction={handleAction}
            payment={formData}
            imagePreview={paymentProofPreview}
            handleImageChange={handleImageChange}
            amountPaidFormatted={amountPaidFormatted}
            paymentTypeOptions={paymentTypeOptions}
            handleInputChange={handleInputChange}
            button={true}
            title="Edit Payment"
            description="Edit this Payment for this plot"
            actionButtonLabel="Update Payment"
          />
          <DataTable table={{ columns, rows }}
            isSorted={true}
            entriesPerPage={true}
            showTotalEntries={true}
            canSearch={true}
            noEndBorder
          />
        </MDBox>
      )}

    </MDBox>

  )
}

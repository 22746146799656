import React from 'react';

function PaymentDate({ payment }) {

  const dateObj = new Date(payment);
  const dayOfMonth = dateObj.getDate();
  const monthName = dateObj.toLocaleString('default', { month: 'short' });
  const formattedDate = `${getOrdinalSuffix(dayOfMonth)} ${monthName}, ${dateObj.getFullYear()}`;
  function getOrdinalSuffix(dayOfMonth) {
    const suffixes = ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"];
    const lastDigit = dayOfMonth % 10;
    const suffix = suffixes[lastDigit] || suffixes[0];
    return dayOfMonth + suffix;
  }

  return (
    <span>{formattedDate}</span>
  );
}

export default PaymentDate;

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { NumericFormat } from 'react-number-format';




// Material Dashboard 2 React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "components/loader";
import { toast } from "react-toastify";
import { createPlot } from "redux/features/plot/plotSlice";



// Data
const initialState = {
  blockNumber: '',
  plotNumber: '',
  plotType: 'Residential',
  phase: 'Phase 1',
  plotSize: 0,
  pricePerSQM: 0,
  listPrice: 0,
}



function AddNewPlot() {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { isLoading, isCreate } = useSelector((state) => state.plot)

  const [formData, setFormData] = useState(initialState);



  const handlePricePerSQMChange = (values) => {
    const { floatValue } = values;
    const newPricePerSQM = floatValue || 0;
    const newListPrice = plotSize * newPricePerSQM;
    setFormData((formData) => ({
      ...formData,
      pricePerSQM: newPricePerSQM,
      listPrice: newListPrice,
    }));
  };

  const handlePlotSizeChange = (values) => {
    const { floatValue } = values;
    const newPlotSize = floatValue || 0;
    const newListPrice = newPlotSize * pricePerSQM;
    setFormData((formData) => ({
      ...formData,
      plotSize: newPlotSize,
      listPrice: newListPrice,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((formData) => ({
      ...formData,
      [name]: value,
    }));
  };

  let {
    blockNumber,
    plotNumber,
    plotType,
    phase,
    plotSize,
    pricePerSQM,
    listPrice,
  } = formData;


  const handleSubmit = async (e) => {
    e.preventDefault()

    //Validations
    if (!blockNumber || !plotNumber) {
      // if blockNumber or plotNumber is empty, toast an error message
      toast.error("Block Number and Plot Number are required");
      return;
    }

    // Check for alphanumeric characters in plotNumber and blockNumber
    const numberRegex = /^\d+$/;
    if (!numberRegex.test(plotNumber) || !numberRegex.test(blockNumber)) {
      toast.error('Plot number and Block number must only contain numbers');
      return;
    }

    if (pricePerSQM === 0) {
      toast.error("Enter the Price Per SQM");
      return;
    }

    if (plotSize === 0) {
      toast.error("Enter the Plot Size");
      return;
    }

    // Create formData object and log it to console
    // Remove trailing zeros
    const plotData = {
      ...formData,
      plotNumber: Number(formData.plotNumber).toString(),
      blockNumber: Number(formData.blockNumber).toString(),
    };



    await dispatch(createPlot(plotData))


  }


  useEffect(() => {
    if (isCreate) {
      navigate("/plots")
    }
  }, [isCreate, navigate])


  return (
    <DashboardLayout>
      {isLoading && <Loader />}
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <MDTypography variant="h4" color="text" mb={3} >
          Add New Plot
        </MDTypography>
        <Grid container spacing={4} style={{ maxWidth: '50vw' }} >
          <Grid item xs={6} sm={6} >
            <MDInput
              fullWidth
              label="Block Number"
              name="blockNumber"
              value={blockNumber}
              onChange={handleInputChange}
              variant="standard"
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <MDInput
              fullWidth
              label="Plot Number"
              name="plotNumber"
              value={plotNumber}
              onChange={handleInputChange}
              variant="standard"
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <NumericFormat
              fullWidth
              label="Price per SQM"
              customInput={MDInput}
              name="pricePerSQM"
              value={pricePerSQM}
              thousandSeparator={true}
              decimalScale={2}
              prefix={'₦'}
              onValueChange={handlePricePerSQMChange}
              variant="standard"
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <NumericFormat
              fullWidth
              customInput={MDInput}
              label="Plot Size (in meters)"
              name="plotSize"
              value={plotSize}
              thousandSeparator={false}
              decimalScale={2}
              onValueChange={handlePlotSizeChange}
              variant="standard"
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="plot-type-label">Plot Type</InputLabel>
              <Select
                labelId="plot-type-label"
                name="plotType"
                value={plotType}
                onChange={handleInputChange}
                label="Plot Type"
                defaultValue="Residential"
                variant="standard"
              >
                <MenuItem value={"Residential"}>Residential</MenuItem>
                <MenuItem value={"Commercial"}>Commercial</MenuItem>
                <MenuItem value={"Others"}>Others</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="phase-label">Phase</InputLabel>
              <Select
                labelId="phase-label"
                name="phase"
                value={phase}
                onChange={handleInputChange}
                label="Phase"
                defaultValue="Phase 1"
                variant="standard"
              >
                <MenuItem value={"Phase 1"}>Phase 1</MenuItem>
                <MenuItem value={"Phase 2"}>Phase 2</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <NumericFormat
              fullWidth
              customInput={MDInput}
              label="Listing Price"
              name="listPrice"
              value={listPrice}
              thousandSeparator={true}
              decimalScale={2}
              prefix={'₦'}
              disabled={true}
              variant="standard"
            />
          </Grid>
          <Grid item xs={6}>
            <MDBox mb={1}>
              <MDButton variant="gradient" color="info" onClick={handleSubmit} >
                Add Plot
              </MDButton>
            </MDBox>
          </Grid>

        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddNewPlot;

import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
const API_URL = `${BACKEND_URL}/api/document/`

// create New document
const addDocument = async (id, documentData) => {
    const response = await axios.post(`${API_URL}add/${id}`, documentData)
    return response.data.message
}

// get all document of a subscriber
const getSubDocuments = async (id) => {
    const response = await axios.get(`${API_URL}getsubdocuments/${id}`)
    return response.data
}

// get all documents 
const getDocuments = async () => {
    const response = await axios.get(`${API_URL}getdocuments`)
    return response.data
}

// delete document
const deleteDocument = async (id, documentData) => {
    const response = await axios.delete(`${API_URL}delete/${id}`, { data: documentData })
    return response.data.message
}

// update document
const updateDocument = async (id, documentData) => {

    const response = await axios.patch(`${API_URL}updatedocument/${id}`, documentData)
    return response.data.message
}

//get a single document
const getDocument = async (id) => {
    const response = await axios.get(`${API_URL}getdocument/${id}`)
    return response.data
}



const documentService = {
    addDocument,
    getSubDocuments,
    getDocuments,
    deleteDocument,
    getDocument,
    updateDocument
}

export default documentService


import { useEffect, useState } from "react";



// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

//import custom component
import PasswordInput from "components/PasswordInput";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sigin-page.png";
import brand from "assets/images/mansonia-bay-logo.png"

//My imports
import { toast } from "react-toastify";
import validator from 'validator';
import { useDispatch, useSelector } from "react-redux";
import authSlice from "redux/features/auth/authSlice";
import { register } from "redux/features/auth/authSlice";
import { RESET } from "redux/features/auth/authSlice";
import Loader from "components/loader";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  password2: "",
}

function Cover() {

  const [formData, setFormData] = useState(initialState);
  const { firstName, lastName, email, password, password2 } = formData

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { isLoading, isLoggedIn, isSuccess, message } = useSelector((state) => state.auth)

  const handleInputChange = (e) => {
    const { name, value } = e.target

    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    //Validations
    if (!email || !password || !firstName || !lastName || !password2) {
      return toast.error('Please fill all fields');
    }
    if (!validator.isEmail(email)) {
      return toast.error('Invalid email')
    }
    if (!validator.isStrongPassword(password)) {
      return toast.error('Password not strong enough');
    }

    if (password !== password2) {
      return toast.error('Passwords do not match');
    }

    const staffData = {
      firstName, lastName, email, password
    }


    await dispatch(register(staffData))

  }

  useEffect(() => {
    if (isSuccess && isLoggedIn) {
      navigate("/dashboard")
    }

    dispatch(RESET())
  }, [isLoggedIn, isSuccess, dispatch, navigate])



  return (
    <BasicLayout image={bgImage}>
      {isLoading && <Loader />}
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDBox component="img" src={brand} alt="Brand" width="10rem" mt={2} />
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1} mb={2}>
            Sign Up
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="First Name"
                onChange={handleInputChange}
                name="firstName"
                value={firstName}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Last Name"
                onChange={handleInputChange}
                name="lastName"
                value={lastName}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                onChange={handleInputChange}
                name="email"
                value={email}
                fullWidth
              />
            </MDBox>
            <PasswordInput
              type="password"
              label="Password"
              onChange={handleInputChange}
              name="password"
              value={password}
              fullWidth
            />
            <PasswordInput
              type="password"
              label="Confirm Password"
              onChange={handleInputChange}
              onPaste={(e) => {
                e.preventDefault()
                toast.error("Cannot Paste Into Input Field")
                return false
              }}
              name="password2"
              value={password2}
              fullWidth
            />
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" onClick={handleSubmit} fullWidth>
                sign up
              </MDButton>

            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Cover;


/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import SubscribersTable from "layouts/subscribers"
import PlotsTable from "layouts/plots";
import AddNewSubscriber from "layouts/subscribers/addNewSubscriber";
import SubscribersDetails from "layouts/subscribers/subscribersDetails";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import ForgotPassword from "layouts/authentication/forgot-password";
import ResetPassword from "layouts/authentication/reset-password"
import StaffTable from "layouts/staffs";
import AddNewStaff from "layouts/staffs/addNewStaff";
import EditSubscriber from "layouts/subscribers/editSubscriber";
import AddNewPlot from "layouts/plots/addNewPlot";
import EditStaff from "layouts/staffs/editStaff";
import EditPlot from "layouts/plots/editPlot";
import AllPaymentsTable from "layouts/payments/all-payments";
import UpcomingPaymentTable from "layouts/payments/upcoming-payments";
import OverduePaymentsTable from "layouts/payments/overdue-payments";
import AgentsTable from "layouts/agents";
import AddNewAgent from "layouts/agents/addNewAgent";
import EditAgent from "layouts/agents/editAgent";
import AgentDetails from "layouts/agents/agentDetails";

// @mui icons
import Icon from "@mui/material/Icon";



const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Subscribers",
    key: "subscribers",
    icon: <Icon fontSize="small">account_circle</Icon>,
    route: "/subscribers",
    component: <SubscribersTable />,
  },
  {
    type: "collapse",
    name: "SubscribersDetails",
    key: "subscribersDetails",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/subscribers/:id",
    component: <SubscribersDetails />,
  },
  {
    type: "collapse",
    name: "Add New Subscriber",
    key: "addNewSubcriber",
    icon: <Icon fontSize="small">add</Icon>,
    route: "/subscribers/add",
    component: <AddNewSubscriber />,
  },
  {
    type: "collapse",
    name: "Edit Subscriber",
    key: "editSubcriber",
    icon: <Icon fontSize="small">edit</Icon>,
    route: "/subscribers/editsubscriber/:id",
    component: <EditSubscriber />,
  },
  {
    type: "collapse",
    name: "Plots",
    key: "plots",
    icon: <Icon fontSize="small">landscape</Icon>,
    route: "/plots",
    component: <PlotsTable />,
  },
  {
    type: "collapse",
    name: "Add New Plot",
    key: "addPlot",
    icon: <Icon fontSize="small">add</Icon>,
    route: "/plots/add",
    component: <AddNewPlot />,
  },
  {
    type: "collapse",
    name: "Edit Plot",
    key: "editPlot",
    icon: <Icon fontSize="small">edit</Icon>,
    route: "/plots/editplot/:id",
    component: <EditPlot />,
  },
  {
    type: "collapse",
    name: "Agents",
    key: "agents",
    icon: <Icon fontSize="small">assignment_ind</Icon>,
    route: "/agents",
    component: <AgentsTable />,
  },
  {
    type: "collapse",
    name: "Add New Agent",
    key: "addagent",
    icon: <Icon fontSize="small">add</Icon>,
    route: "/agents/add",
    component: <AddNewAgent />,
  },
  {
    type: "collapse",
    name: "Edit Agent",
    key: "editagent",
    icon: <Icon fontSize="small">edit</Icon>,
    route: "/agents/editagent/:id",
    component: <EditAgent />,
  },
  {
    type: "collapse",
    name: "Agent",
    key: "agent",
    icon: <Icon fontSize="small">edit</Icon>,
    route: "/agent/:id",
    component: <AgentDetails />,
  },
  {
    type: "collapse",
    name: "Staff",
    key: "staffs",
    icon: <Icon fontSize="small">people</Icon>,
    route: "/staffs",
    component: <StaffTable />,
  },
  {
    type: "collapse",
    name: "Add New Staff",
    key: "addNewStaff",
    icon: <Icon fontSize="small">add</Icon>,
    route: "/staff/add",
    component: <AddNewStaff />,
  },
  {
    type: "collapse",
    name: "Edit Staff",
    key: "editStaff",
    icon: <Icon fontSize="small">edit</Icon>,
    route: "/staff/editstaff/:id",
    component: <EditStaff />,
  },
  {
    type: "collapse",
    name: "All Payments",
    key: "payments",
    icon: <Icon fontSize="small">money</Icon>,
    route: "/payments",
    component: <AllPaymentsTable />,
  },
  {
    type: "collapse",
    name: "Upcoming Payments",
    key: "upcomingpayments",
    icon: <Icon fontSize="small">add_card</Icon>,
    route: "/upcomingpayments",
    component: <UpcomingPaymentTable />,
  },
  {
    type: "collapse",
    name: "Overdue Payments",
    key: "overduepayments",
    icon: <Icon fontSize="small">error</Icon>,
    route: "/overduepayments",
    component: <OverduePaymentsTable />,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/signup",
    component: <SignUp />,
  },
  {
    type: "collapse",
    name: "Forgot Password",
    key: "forgot-password",
    icon: <Icon fontSize="small">padlock</Icon>,
    route: "/forgotpassword",
    component: <ForgotPassword />,
  },
  {
    type: "collapse",
    name: "Reset Password",
    key: "reset",
    icon: <Icon fontSize="small">reset</Icon>,
    route: "/resetpassword/:resetToken",
    component: <ResetPassword />,
  },


];

export default routes;

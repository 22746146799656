import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import authService from './authService'


const initialState = {
    isLoggedIn: false,
    staff: null,
    loggedInStaff: null,
    staffs: [],
    isError: false,
    isSuccess: false,
    isCreate: false,
    isLoading: false,
    message: ""
}

//Register Staff
export const register = createAsyncThunk(
    "auth/register",
    async (staffData, thunkAPI) => {
        try {
            return await authService.register(staffData)
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

//Login Staff
export const login = createAsyncThunk(
    "auth/login",
    async (staffData, thunkAPI) => {
        try {
            return await authService.login(staffData)
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// logout staff
export const logout = createAsyncThunk(
    "auth/logout",
    async (_, thunkAPI) => {
        try {
            return await authService.logout()
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// get login status
export const loginStatus = createAsyncThunk(
    "auth/loggedin",
    async (_, thunkAPI) => {
        try {
            return await authService.loginStatus()
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// admin get staff data
export const getStaff = createAsyncThunk(
    "auth/getStaff",
    async (id, thunkAPI) => {
        try {
            return await authService.getStaff(id)
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// get logged in staff data
export const getLoggedInStaff = createAsyncThunk(
    "auth/getLoggedInStaff",
    async (_, thunkAPI) => {
        try {
            return await authService.getLoggedInStaff()
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// admin create staff profile
export const createStaff = createAsyncThunk(
    "auth/createStaff",
    async (staffData, thunkAPI) => {
        try {
            return await authService.createStaff(staffData)
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// staff forgot password
export const forgotPassword = createAsyncThunk(
    "auth/forgotPassword",
    async (staffData, thunkAPI) => {
        try {
            return await authService.forgotPassword(staffData)
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// staff reset password
export const resetPassword = createAsyncThunk(
    "auth/resetPassword",
    async ({ staffData, resetToken }, thunkAPI) => {
        try {
            return await authService.resetPassword(staffData, resetToken)
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// get Staffs
export const getStaffs = createAsyncThunk(
    "auth/getStaffs",
    async (_, thunkAPI) => {
        try {
            return await authService.getStaffs()
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

// delete staff
export const deleteStaff = createAsyncThunk(
    "auth/deleteStaff",
    async (id, thunkAPI) => {
        try {
            return await authService.deleteStaff(id)
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)

//updateStaff
export const updateStaff = createAsyncThunk(
    "auth/updateStaff",
    async ({ id, staffData }, thunkAPI) => {
        try {
            return await authService.updateStaff(id, staffData)
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message)
        }
    }
)


const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        RESET(state) {
            state.isError = false
            state.isSuccess = false
            state.isCreate = false
            state.loggedInStaff = null
            state.isLoggedIn = false
            state.isLoading = false
            state.message = ""
        }
    },
    extraReducers: (builder) => {
        builder
            //register staff
            .addCase(register.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(register.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.isLoggedIn = true
                state.staff = action.payload
                toast.success("Registration Successfull")

            })
            .addCase(register.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.staff = null
                toast.error(action.payload)
            })

            //login staff
            .addCase(login.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.isLoggedIn = true
                state.staff = action.payload
                toast.success("Login Successfull")

            })
            .addCase(login.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.staff = null
                toast.error(action.payload)
            })

            //log out staff
            .addCase(logout.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(logout.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.isLoggedIn = false
                state.staff = null
                toast.success(action.payload)

            })
            .addCase(logout.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                toast.error(action.payload)
            })

            //get staff login status
            .addCase(loginStatus.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(loginStatus.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.isLoggedIn = action.payload

            })
            .addCase(loginStatus.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            //admin get staff data 
            .addCase(getStaff.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getStaff.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.staff = action.payload
            })
            .addCase(getStaff.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                toast.error(action.payload)
            })

            // get loggedin staff data
            .addCase(getLoggedInStaff.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getLoggedInStaff.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.loggedInStaff = action.payload
            })
            .addCase(getLoggedInStaff.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                toast.error(action.payload)
            })

            //admin create staff profile 
            .addCase(createStaff.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(createStaff.fulfilled, (state, action) => {
                state.isLoading = false
                state.isCreate = true
                state.isError = false
                state.message = action.payload
                toast.success(action.payload)

            })
            .addCase(createStaff.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                toast.error(action.payload)
            })

            // staff forgot password 
            .addCase(forgotPassword.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(forgotPassword.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.message = action.payload
                toast.success(action.payload)
            })
            .addCase(forgotPassword.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                toast.error(action.payload)
            })

            // staff reset password 
            .addCase(resetPassword.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(resetPassword.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.message = action.payload
                toast.success(action.payload)
            })
            .addCase(resetPassword.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                toast.error(action.payload)
            })

            // get Staffs
            .addCase(getStaffs.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getStaffs.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.staffs = action.payload
            })
            .addCase(getStaffs.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                toast.error(action.payload)
            })

            // admin delete staff
            .addCase(deleteStaff.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(deleteStaff.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.staffs = state.staffs.filter(staff => staff._id !== action.payload);
                state.message = action.payload
                toast.success(action.payload)
            })
            .addCase(deleteStaff.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                toast.error(action.payload)
            })

            // admin update staff
            .addCase(updateStaff.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(updateStaff.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.message = action.payload
                toast.success(action.payload)
            })
            .addCase(updateStaff.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                toast.error(action.payload)
            })
    }
})


export const { RESET } = authSlice.actions

export const selectIsLoggedIn = (state) => state.auth.isLoggedIn

export const selectStaff = (state) => state.auth.staff

export const selectLoggedInStaff = (state) => state.auth.loggedInStaff

export default authSlice.reducer

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";


// Material Dashboard 2 React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "components/loader";
import { toast } from "react-toastify";
import validator from 'validator';
import { createAgent } from "redux/features/agent/agentSlice";
import { getAgent } from "redux/features/agent/agentSlice";
import { Card } from "@mui/material";
import { getPlots } from "redux/features/plot/plotSlice";
import DefaultInfoCard from "components/Cards/InfoCards/DefaultInfoCard";
import { getPayments } from "redux/features/payment/paymentSlice";
import ThousandSeparator from "layouts/utils/thousandSeperator";
import AgentPlotsDataTable from "./data/agentPlotsDataTable";
import { getSubscribers } from "redux/features/subscriber/subscriberSlice";




function AgentDetails() {

    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    //state handlers
    const { agent, isLoading, isCreate } = useSelector((state) => state.agent)
    const { plots } = useSelector((state) => state.plot);
    const { payments } = useSelector((state) => state.payment);


    //get the data into the state managers
    useEffect(() => {
        dispatch(getAgent(id));
        dispatch(getSubscribers());
        dispatch(getPlots());
        dispatch(getPayments());
    }, []);

    //filter plots
    const agentPlots = plots.filter(plot => plot.agentID === agent?._id);
    const numberOfPlots = agentPlots.length || 0;

    //filter payments
    const agentPaymentCommission = payments.filter(payment => payment.agentID === agent?._id);


    //calculate the commissions
    let totalAgentCommission = agentPlots.reduce((total, plot) => total + parseFloat(plot?.agentCommission?.$numberDecimal), 0);
    let paidAgentCommission = agentPaymentCommission.reduce((total, payment) => total + parseFloat(payment?.agentCommission?.$numberDecimal), 0);
    let unPaidAgentCommission = totalAgentCommission - paidAgentCommission



    //currency formatting
    totalAgentCommission = <ThousandSeparator value={totalAgentCommission} />
    paidAgentCommission = <ThousandSeparator value={paidAgentCommission} />
    unPaidAgentCommission = <ThousandSeparator value={unPaidAgentCommission} />





    return (
        <DashboardLayout>
            {isLoading && <Loader />}
            <DashboardNavbar />
            <MDBox pt={2} pb={3}>
                <MDTypography variant="h4" color="text" mb={3} ml={5} >
                    Agent Details
                </MDTypography>

                {!agent && !isLoading ? (
                    <MDTypography variant="h6" px={4} pb={3} color="error">
                        Something went wrong, please reload...
                    </MDTypography>
                ) : (
                    <MDBox
                        sx={{
                            position: "relative",
                            mt: -4,
                            mx: 3,
                            py: 5,
                            pl: 0,
                            pr: 5,
                        }}
                    >
                        <MDBox display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
                            <MDBox display="flex" alignItems="center" >


                                <MDBox display="flex" flexDirection="column" marginLeft="1rem">
                                    <MDTypography variant="h5" fontWeight="medium">
                                        {agent?.name}
                                    </MDTypography>
                                    <MDTypography variant="button" color="text" fontWeight="regular">
                                        {agent?.companyName}
                                    </MDTypography>
                                </MDBox>
                            </MDBox>
                            <MDBox display="flex" flexDirection="column" justifyContent="center" marginLeft="auto" marginTop="0.5rem">
                                <MDTypography variant="button" color="text" fontWeight="regular">
                                    Email:  {agent?.email}
                                </MDTypography>
                            </MDBox>
                            <MDBox display="flex" flexDirection="column" justifyContent="center" marginLeft="auto" marginTop="0.5rem">
                                <MDTypography variant="button" color="text" fontWeight="regular">
                                    Phone Number: {agent?.phoneNumber}
                                </MDTypography>
                            </MDBox>
                            <MDBox display="flex" flexDirection="column" justifyContent="center" marginLeft="auto" marginTop="0.5rem">
                                <MDTypography variant="button" color="text" fontWeight="regular">
                                    No of Plots Sold: {numberOfPlots}
                                </MDTypography>
                            </MDBox>
                        </MDBox>


                        <MDBox mt={0} mb={3} ml={2}>
                            <MDTypography variant="h5" pb={2} pt={4} > Commission </MDTypography>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={4}>
                                    <MDBox mb={1.5}>
                                        <DefaultInfoCard
                                            title="Expected Commission"
                                            value={totalAgentCommission}
                                        />
                                    </MDBox>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <MDBox mb={1.5}>
                                        <DefaultInfoCard
                                            title="Paid Commission"
                                            value={paidAgentCommission}
                                        />
                                    </MDBox>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <MDBox mb={1.5}>
                                        <DefaultInfoCard
                                            title="Unpaid Commission"
                                            value={unPaidAgentCommission}
                                        />
                                    </MDBox>
                                </Grid>
                            </Grid>
                        </MDBox>

                        <MDBox mt={0} mb={3} ml={2}>
                            <MDTypography variant="h5" pb={2} pt={1} > Plot(s) </MDTypography>

                            <MDBox pb={2} >
                                <AgentPlotsDataTable />
                            </MDBox>

                        </MDBox>

                    </MDBox>

                )
                }

            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default AgentDetails;

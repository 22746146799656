// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/loader";
import DataTable from "components/Tables/DataTable";
import ThousandSeparator from "layouts/utils/thousandSeperator";

export default function AgentPlotsDataTable() {
  const dispatch = useDispatch();
  const { agent, isLoading } = useSelector((state) => state.agent);
  const { subscribers } = useSelector((state) => state.subscriber);
  const { plots } = useSelector((state) => state.plot);
  const { payments } = useSelector((state) => state.payment);

  const agentPlots = plots.filter(plot => plot.agentID === agent?._id);



  const rows = agentPlots.map((plot, index) => {

    const plotOwner = subscribers.find(subscriber => subscriber._id === plot?.subscriberID);
    const paidCommission = payments
      .filter(payment => payment.plotID === plot?._id && payment.agentCommission)
      .reduce((total, payment) => total + parseFloat(payment.agentCommission?.$numberDecimal), 0);


    const unPaidCommission = parseFloat(plot.agentCommission?.$numberDecimal) - paidCommission



    return {
      key: plot?._id,
      serialNumber: index + 1,
      name: `${plot?.blockNumber} ${plot?.plotNumber}`,
      phase: plot?.phase,
      plotOwner: plotOwner?.name || plotOwner?.nameOfEntry,
      fileNumber: plotOwner?.fileNumber,
      commission: <ThousandSeparator value={plot.agentCommission?.$numberDecimal} />,
      paidCommission: <ThousandSeparator value={paidCommission} />,
      unPaidCommission: <ThousandSeparator value={unPaidCommission} />,
    };
  });



  const columns = [
    { Header: "SN", accessor: "serialNumber", width: "1%", align: "left" },
    { Header: "Name", accessor: "name", align: "left" },
    { Header: "Phase", accessor: "phase", align: "left" },
    { Header: "Subscriber", accessor: "plotOwner", align: "left" },
    { Header: "File Number", accessor: "fileNumber", align: "left" },
    { Header: "Expected Commission", accessor: "commission", width: "15%", align: "left" },
    { Header: "Paid Commission", accessor: "paidCommission", width: "15%", align: "left" },
    { Header: "unpaid Commission", accessor: "unPaidCommission", width: "15%", align: "left" },
  ];



  return (
    <MDBox >
      {isLoading && <Loader />}
      {!isLoading && agentPlots.length === 0 ? (
        <MDTypography variant="h6" px={4} pt={3} pb={3} color="error">
          No Plot Sold
        </MDTypography>
      ) : (
        <DataTable table={{ columns, rows }}
          isSorted={true}
          entriesPerPage={true}
          showTotalEntries={true}
          canSearch={true}
          noEndBorder
        />
      )}

    </MDBox>

  )
}

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import ComplexStatisticsCard from "components/Cards/StatisticsCards/ComplexStatisticsCard";
import PieChart from "components/Charts/PieChart";
import DefaultInfoCard from "components/Cards/InfoCards/DefaultInfoCard";

//my imports
import MDTypography from "components/MDTypography";
import HorizontalBarChart from "components/Charts/BarCharts/HorizontalBarChart";

// Data
import { getPayments } from "redux/features/dashboard/dashboardSlice";
import {
  CALC_PLOT_PAYMENTS,
  CALC_OTHER_PAYMENTS,
  CALC_OVERDUE_PAYMENTS,
  CALC_UPCOMING_PAYMENTS,
  CALC_PLOTS_QUANTITY,
  CALC_AGENTS_COMMISSION,
  CALC_REVENUE_PROGRESS_MONTLY,
  CALC_PLOT_PAYMENTS_BY_PHASES,
  CALC_OTHER_PAYMENTS_BY_PHASES
} from "redux/features/dashboard/dashboardSlice";
import { getPlots } from "redux/features/plot/plotSlice";
import { getSubscribers } from "redux/features/subscriber/subscriberSlice";
import Loader from "components/loader";
import ThousandSeparator from "layouts/utils/thousandSeperator";
import { getAgents } from "redux/features/agent/agentSlice";
import { CALC_PAYMENTS_BREAKDOWN } from "redux/features/dashboard/dashboardSlice";
import { AdminOnlyLink } from "components/protect/hiddenLink";



function Dashboard() {

  const dispatch = useDispatch();

  // data from database
  const { payments, isLoading } = useSelector((state) => state.dashboard);
  const { isLoggedIn } = useSelector((state) => state.auth)
  const { plots } = useSelector((state) => state.plot);
  const { subscribers } = useSelector((state) => state.subscriber);
  const { agents } = useSelector((state) => state.agent);


  // dashboard data
  let {
    totalOfferPayments,
    totalPlotPayments,
    totalAgentPayments,
    totalOidcPayments,
    totalLegalPayments,
    totalOtherPaymentsComm,
    totalOtherPayments,
    totalOverduePayments,
    totalUpcomingPayments,
    plotsQuantity,
    phase1Payments,
    phase2Payments,
    phase1OtherPayments,
    phase2OtherPayments } = useSelector((state) => state.dashboard);




  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getPayments());
      dispatch(getPlots());
      dispatch(getSubscribers());
      dispatch(getAgents());
    }
  }, [isLoggedIn, dispatch]);



  useEffect(() => {
    dispatch(CALC_PLOT_PAYMENTS(payments));
    dispatch(CALC_OTHER_PAYMENTS(payments));
    dispatch(CALC_OVERDUE_PAYMENTS(payments));
    dispatch(CALC_UPCOMING_PAYMENTS(payments));
    dispatch(CALC_REVENUE_PROGRESS_MONTLY(payments));
    dispatch(CALC_OTHER_PAYMENTS_BY_PHASES(payments));
    dispatch(CALC_PAYMENTS_BREAKDOWN(payments));
    dispatch(CALC_PLOTS_QUANTITY(plots));
    dispatch(CALC_AGENTS_COMMISSION(plots));
    dispatch(CALC_PLOT_PAYMENTS_BY_PHASES(plots));

  }, [dispatch, payments, plots]);


  //variable declaration
  //payments
  const totalPaymentReceived = parseFloat(totalOtherPayments) + parseFloat(totalOfferPayments)
  const totalOutstandingPayments = totalOverduePayments + totalUpcomingPayments



  //plots
  let totalPlots = plotsQuantity?.totalPlots
  let totalPlotsPhase1 = plotsQuantity?.phase1Plots
  let totalPlotsPhase2 = plotsQuantity?.phase2Plots
  let availablePlots = plotsQuantity?.totalUnsubscribedPlots
  let availablePlotsPhase1 = plotsQuantity?.unsubscribedPhase1Plots
  let availablePlotsPhase2 = plotsQuantity?.unsubscribedPhase2Plots

  //subscribers
  let totalSubscribers = subscribers.length
  const uniqueSubscriberIDs = [...new Set(plots
    .filter(plot => plot.subscriberID) // Filter out plots without a subscriberID
    .map(plot => plot.subscriberID)
  )];
  let totalPlotOwners = uniqueSubscriberIDs.length;
  let plotOwnersPhase1 = plotsQuantity?.phase1PlotsCount
  let plotOwnersPhase2 = plotsQuantity?.phase2PlotsCount
  let nonPlotOwners = totalSubscribers - totalPlotOwners

  //agents
  let { totalAgentCommission, totalAgentsPlot } = useSelector((state) => state.dashboard);
  let totalAgents = agents.length



  availablePlots = availablePlots || "0";
  totalPlotOwners = totalPlotOwners || "0";
  totalSubscribers = totalSubscribers || "0";
  nonPlotOwners = nonPlotOwners || "0";
  totalAgents = totalAgents || "0";
  totalAgentsPlot = totalAgentsPlot || "0";
  totalPlots = totalPlots || "0";
  totalAgentCommission = totalAgentCommission || 0;

  totalAgentCommission = <ThousandSeparator value={totalAgentCommission} />


  return (
    <DashboardLayout>
      {isLoading && <Loader />}
      <DashboardNavbar />
      <MDBox py={3}>
        <MDTypography variant="h5" pb={4} > Revenue</MDTypography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="money"
                title="Total Payment Received"
                amount={totalPaymentReceived}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="sell"
                title="Total Offer Payments"
                amount={totalOfferPayments}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="landscape"
                title="Total Plot Payments"
                amount={totalPlotPayments}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={2}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="foundation"
                title="Total Other Payments"
                amount={totalOtherPayments}
              />
            </MDBox>
          </Grid>

        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={3}>
                <PieChart
                  icon={{ color: "info", component: "landscape" }}
                  title="Plot Payments"
                  description="by Phases"
                  chart={{
                    labels: ["Phase 1", "Phase 2"],
                    datasets: {
                      label: "Projects",
                      backgroundColors: ["info", "secondary"],
                      data: [phase1Payments, phase2Payments],
                    },
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={3}>
                <PieChart
                  icon={{ color: "info", component: "foundation" }}
                  title="Other Payments"
                  description="by Phases"
                  chart={{
                    labels: ["Phase 1", "Phase 2"],
                    datasets: {
                      label: "Projects",
                      backgroundColors: ["info", "secondary"],
                      data: [phase1OtherPayments, phase2OtherPayments],
                    },
                  }}
                />
              </MDBox>
            </Grid>

            <AdminOnlyLink>
              <Grid item xs={12} md={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    icon="percentage"
                    title="OIDC Commission"
                    amount={totalOidcPayments}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    title="Legal Commission"
                    amount={totalLegalPayments}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    title="Agent Commission"
                    amount={totalAgentPayments}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    title="Other Commission"
                    amount={totalOtherPaymentsComm}
                  />
                </MDBox>
              </Grid>
            </AdminOnlyLink>
          </Grid>
        </MDBox>


        <MDBox mt={3} mb={3}>
          <MDTypography variant="h5" pb={4} pt={4} > Outstanding Revenue </MDTypography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="error"
                  color="error"
                  title="Overdue Payments"
                  amount={totalOverduePayments}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="add_card"
                  title="Upcoming Payments"
                  amount={totalUpcomingPayments}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="landslide"
                  title="Total Outstanding Payments"
                  amount={totalOutstandingPayments}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>



        <MDBox mt={3} mb={3}>
          <MDTypography variant="h5" pb={2} pt={4} > Plots </MDTypography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <DefaultInfoCard
                  title="Total Plots"
                  value={totalPlots}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <HorizontalBarChart
                  description="by Phases"
                  height="9.8rem"
                  chart={{
                    labels: ["Phase 1", "Phase 2",],
                    datasets: [{
                      color: "info",
                      data: [totalPlotsPhase1, totalPlotsPhase2],
                    }],
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <DefaultInfoCard
                  title="Available Plots"
                  value={availablePlots}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <HorizontalBarChart
                  description="by Phases"
                  height="9.8rem"
                  chart={{
                    labels: ["Phase 1", "Phase 2",],
                    datasets: [{
                      color: "info",
                      data: [availablePlotsPhase1, availablePlotsPhase2],
                    }],
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>



        <MDBox mt={3} mb={3}>
          <MDTypography variant="h5" pb={2} pt={4} > Subscribers </MDTypography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <DefaultInfoCard
                  title="Total Subscribers"
                  value={totalSubscribers}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <DefaultInfoCard
                  title="Plot Owners"
                  value={totalPlotOwners}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <HorizontalBarChart
                  description="by Phases"
                  height="9.8rem"
                  chart={{
                    labels: ["Phase 1", "Phase 2",],
                    datasets: [{
                      color: "info",
                      data: [plotOwnersPhase1, plotOwnersPhase2],
                    }],
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <DefaultInfoCard
                  title="Non Owners"
                  value={nonPlotOwners}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>

        <AdminOnlyLink>
          <MDBox mt={3} mb={3}>
            <MDTypography variant="h5" pb={2} pt={4} > Agents </MDTypography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <MDBox mb={1.5}>
                  <DefaultInfoCard
                    title="Total Agents"
                    value={totalAgents}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <MDBox mb={1.5}>
                  <DefaultInfoCard
                    title="Total Agents Expected Commission"
                    value={totalAgentCommission}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={4}>
                <MDBox mb={1.5}>
                  <DefaultInfoCard
                    title="Total Plots Sold by Agents"
                    value={totalAgentsPlot}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>

        </AdminOnlyLink>


        {/* <MDBox mt={3} mb={3}>
          <MDTypography variant="h5" pb={4} pt={4} > Insights </MDTypography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={12}>
              <VerticalBarChart
                icon={{ color: "info", component: "leaderboard" }}
                title="Plot Payments Progress"
                description="Sales by month (in Millions)"
                chart={{
                  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                  datasets: [{
                    color: "dark",
                    data: [15000, 25000, 10000, 50000, 23000, 15000, 15000, 25000, 10000, 50000, 23000, 15000],
                  }],
                }}
              />
            </Grid>
          </Grid>
        </MDBox> */}

      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;

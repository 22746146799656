import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
const API_URL = `${BACKEND_URL}/api/agent/`

// Create New agent
const createAgent = async (agentData) => {
    const response = await axios.post(API_URL + "add", agentData)
    return response.data
}


// get all agent filtered details
const getAgents = async () => {
    const response = await axios.get(API_URL + "getagents")
    return response.data
}

// delete a agent
const deleteAgent = async (id) => {
    const response = await axios.delete(`${API_URL}delete/${id}`)
    return response.data.message
}

//get all details of a single agent
const getAgent = async (id) => {
    const response = await axios.get(`${API_URL}getagent/${id}`)    
    return response.data
}

//update a agent
const updateAgent = async (id, agentData) => {
    const response = await axios.patch(`${API_URL}updateagent/${id}`, agentData) 
    return response.data.message
}



const agentService = {
    createAgent,
    getAgents,
    deleteAgent,
    getAgent,
    updateAgent
}

export default agentService
import React, { useState } from "react";
import PropTypes from "prop-types";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    Icon,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";

import MDButton from "components/MDButton";
import { NumericFormat } from "react-number-format";
import MDInput from "components/MDInput";
import DatePicker from 'react-datepicker';
import './datepicker.css';
import { format } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import MDTypography from "components/MDTypography";
import { Link } from "react-router-dom";

// Data
function OfferModal(props) {
    const { open, onClose, title, plots, agents, installmentsData, handlePlotChange, handleAgentChange, handleInputChange, addFields, removeFields, handleInstallmentChange, formData, actionButtonLabel } = props;



    const {
        selectedPlot,
        selectedAgent,
        plotSize,
        plotType,
        pricePerSQM,
        agentFee,
        legalFee,
        listPrice,
        oidcFee,
        otherFee,
        salesPrice,
    } = formData






    return (
        <Dialog open={open} onClose={onClose} PaperProps={{ style: { width: '80vw', height: '70vh', maxWidth: 'none', maxHeight: 'none' } }}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent style={{ pt: '10px' }} >
                <DialogContentText> </DialogContentText>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Grid container spacing={4} style={{ maxWidth: '50vw' }} >
                            <Grid item xs={6} sm={6} >
                                <FormControl fullWidth margin="normal">
                                    <InputLabel>Available Plots</InputLabel>
                                    <Select name="selectedPlot" value={selectedPlot || ''} onChange={handlePlotChange} variant="standard">
                                        {plots.map((plot) => (
                                            <MenuItem key={plot._id} value={plot._id}>
                                                {plot.blockNumber} {plot.plotNumber} | {plot.phase}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} sm={6} >
                                <FormControl fullWidth margin="normal">
                                    <InputLabel>Assigned Agent</InputLabel>
                                    <Select name="selectedAgent" value={selectedAgent || ''} onChange={handleAgentChange} variant="standard">
                                        <MenuItem value="none">None</MenuItem>
                                        {agents.map((agent) => (
                                            <MenuItem key={agent._id} value={agent._id}>
                                                {agent.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <NumericFormat
                                    fullWidth
                                    label="Price per SQM"
                                    customInput={MDInput}
                                    name="pricePerSQM"
                                    value={pricePerSQM}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    prefix={'₦'}
                                    variant="standard"
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <NumericFormat
                                    fullWidth
                                    customInput={MDInput}
                                    label="Plot Size (in meters)"
                                    name="plotSize"
                                    value={plotSize}
                                    thousandSeparator={false}
                                    decimalScale={2}
                                    variant="standard"
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <MDInput
                                    fullWidth
                                    label="Plot Type"
                                    name="plotType"
                                    value={plotType}
                                    variant="standard"
                                    disabled
                                />

                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <NumericFormat
                                    fullWidth
                                    customInput={MDInput}
                                    label="Listing Price"
                                    name="listPrice"
                                    value={listPrice}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    prefix={'₦'}
                                    disabled={true}
                                    variant="standard"
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} >
                                <MDInput
                                    fullWidth
                                    label="Orange Island Fee (%)"
                                    name="oidcFee"
                                    value={oidcFee}
                                    prefix={''}
                                    suffix={'%'}
                                    onChange={handleInputChange}
                                    variant="standard"
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} >
                                <MDInput
                                    fullWidth
                                    label="Agent Fee (%)"
                                    name="agentFee"
                                    value={agentFee}
                                    prefix={''}
                                    suffix={'%'}
                                    onChange={handleInputChange}
                                    variant="standard"
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} >
                                <MDInput
                                    fullWidth
                                    label="Legal Fee (%)"
                                    name="legalFee"
                                    value={legalFee}
                                    prefix={''}
                                    suffix={'%'}
                                    onChange={handleInputChange}
                                    variant="standard"
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} >
                                <MDInput
                                    fullWidth
                                    label="Other Fee (%)"
                                    name="otherFee"
                                    value={otherFee}
                                    prefix={''}
                                    suffix={'%'}
                                    onChange={handleInputChange}
                                    variant="standard"
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <NumericFormat
                                    fullWidth
                                    customInput={MDInput}
                                    label="Sales Price"
                                    name="salesPrice"
                                    value={salesPrice}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    prefix={'₦'}
                                    disabled={true}
                                    variant="standard"
                                />
                            </Grid>

                        </Grid>

                    </Grid>
                    <Grid item xs={6}>
                        <Grid container spacing={4} alignItems="left">
                            <Grid item xs={12} sm={6}>
                                <MDTypography variant="h6">Installments</MDTypography>
                            </Grid>
                            {installmentsData.map((input, index) => {
                                return (
                                    <Grid container key={index} spacing={2} mt={1} style={{ maxWidth: '90vw' }} alignItems="center">
                                        <Grid item xs={3} ml={5}>
                                            <MDInput
                                                fullWidth
                                                label="Percentage (%)"
                                                name="percentage"
                                                prefix={''}
                                                suffix={'%'}
                                                onChange={event => {
                                                    const value = event.target.value;
                                                    handleInstallmentChange(index, value, input.amountPaid, input.expectedPaymentDate);
                                                }}
                                                variant="standard"
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <NumericFormat
                                                fullWidth
                                                label="Amount"
                                                customInput={MDInput}
                                                allowNegative={false}
                                                name="amountPaid"
                                                value={input.amountPaid}
                                                thousandSeparator={true}
                                                decimalScale={2}
                                                prefix={'₦'}
                                                onValueChange={({ floatValue }) => handleInstallmentChange(index, input.percentage, floatValue, input.expectedPaymentDate)}
                                                variant="standard"
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <DatePicker
                                                placeholderText="Select Expected Payment date"
                                                name='expectedPaymentDate'
                                                format="dd/MM/yyyy"
                                                selected={input.expectedPaymentDate || new Date()}
                                                onChange={date => handleInstallmentChange(index, input.percentage, input.amountPaid, date)}
                                                value={input.expectedPaymentDate ? format(input.expectedPaymentDate, 'do MMM, yyyy') : ''}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Icon component={Link} onClick={() => removeFields(index)} color="secondary" to="#" sx={{ mt: -0.25, ml: 3, mr: 3 }}>
                                                remove
                                            </Icon>
                                        </Grid>
                                    </Grid>
                                )
                            })}

                            <Grid item xs={12}>
                                <MDButton variant="gradient" color="info" onClick={addFields} >
                                    Add More
                                </MDButton>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <MDButton variant="gradient" color="info" onClick={props.onAction} >
                    {actionButtonLabel}
                </MDButton>
            </DialogActions>
        </Dialog>
    );
}



export default OfferModal;
